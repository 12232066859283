import React from 'react'
import { Container, Nav, Tab } from 'react-bootstrap'
import ExternalCards from './External/Cards'
//import Reception from './views/Reception'
import '../../styles/tabs.css'

export default function WmMovements() { 
    return (
        <Container fluid>
            <Tab.Container id='submenu-header' defaultActiveKey='external-reception'>
                <Nav variant='pills' className='mt-3 mb-2 justify-content-center border-bottom border-1 spread-font '>
                    <Nav.Item  bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' eventKey='external-reception'><p>Recepción Externa</p></Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' eventKey='internal-reception' disabled><p>Recepción Interna</p></Nav.Link>
                    </Nav.Item> */}
                </Nav>
                <Tab.Content className='gx-0 px-0 mx-0'>
                    <Tab.Pane eventKey='external-reception'>
                        <ExternalCards />
                    </Tab.Pane>
                    <Tab.Pane eventKey='vas' >
                        {/* <Reception /> */}
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>
    )    
}
