export const CardsData = [
    {
        title: 'Consulta por CUD a Ripley',
        path: '',
        cliente: 'ripley',
        id: 17,
        // img: 'img/logo-ripley.png',
        button: 'Herramienta 17',
        class: 'active',
        Text: 'Excel con columna GUIA.',
        Text1: 'Desde sistema de Ripley.',
        Text2: 'Excel con información de cada guía.'
    }
]
