import React, { useState, useRef, useEffect} from 'react'
import XLSX from 'xlsx'
import { Col, Alert, OverlayTrigger, Overlay, Popover, Tooltip } from 'react-bootstrap'
import { ButtonGroup, Button } from '@material-ui/core'
import Swal from 'sweetalert2'
import axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
import { useSelector } from 'react-redux'
import DatePicker, { registerLocale } from "react-datepicker"
import { addDays } from 'date-fns'
import es from "date-fns/locale/es"
import fileDownload from 'js-file-download'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import 'filepond/dist/filepond.min.css'
import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es)
registerPlugin(FilePondPluginFileValidateType);

//const url_backend_pi = process.env.REACT_APP_URL_PREP_INFO
const url_backend_py = process.env.REACT_APP_URL_PY

const make_cols = (refstr) => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
    return o;
}

const SheetJSFT = [
    "xlsx"
].map(function (x) { return "." + x; }).join(",")

const UploadFile = ({ path, titulo, loading, setLoading }) => {
    const { user: currentUser } = useSelector(state => state.auth)
    const MySwal = withReactContent(Swal)
    const [file, setFile] = useState({
        file: {},
        data: [],
        cols: []
    })
    const [files,setFiles] = useState([])
    //const [fileConversor, setFileConversor] = useState(null)
    const [fileConversor, setFileConversor] = useState({
        file: {},
        data: [],
        cols: []
    })
    const [Enviar, setEnviar] = useState(false)
    const [Nombre, setNombre] = useState('')
    const [Estado, setEstado] = useState('')
    const [recibido, setRecibido] = useState(false)
    const [archivosFinales, setarchivosFinales] = useState([])
    const [Lof, setLof] = useState('')
    const [dateManifest, setDateManifest] = useState('')
    const [manifestQ, setManifestQ] = useState('')
    const [buttonEnabled, setButtonEnabled] = useState(false)
    const target = useRef(null);
    const enabledDays = (date) => {
        const day = date.getDay();
        return day !== 0
    }

    const handleError = (mensaje, titulo, tipo) => {
        MySwal.fire({
            title: titulo,
            icon: tipo,
            text: mensaje,
            confirmButtonText: `Aceptar`,
        }).then(() => {
            window.location.reload()
        })
    }

    const formatDate = (date, format) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            dateFormated = ''
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        if (format === 'us') dateFormated = [year, month, day].join('-')
        else if (format === 'es') dateFormated = [day, month, year].join('-')
        else dateFormated = [day, month, year].join('-')

        return dateFormated
    }

    // function handleChange(e) {
        // const files = e.target.files;
        // setEstado("Subiendo Archivo")
        // if (files && files[0]) {
        //     setLoading(true)
        //     setFile({ ...file, file: files[0] })
        //     setNombre(files[0].name)

        //     const reader = new FileReader();
        //     const rABS = !!reader.readAsBinaryString;

        //     reader.onload = (e) => {
        //         const bstr = e.target.result
        //         const wb = XLSX.read(bstr, {
        //             type: rABS ? 'binary' : 'array', bookVBA: true, cellDates: true,
        //             cellNF: false,
        //             cellText: false,
        //             raw: false,
        //         })
        //         if (path === "ManifiestoFalabella") {
        //             let hojas = []
        //             try {
        //                 hojas = wb.Workbook.Sheets
        //             } catch (error) {
        //                 handleError("El formato del archivo debe ser .xlsx", "Error de formato", "error")
        //             }
        //             hojas.forEach(hoja => {
        //                 if (hoja.Hidden !== 0) return
        //                 else {
        //                     const ws = wb.Sheets[hoja.name];
        //                     const data = XLSX.utils.sheet_to_json(ws, { "defval": " " });

        //                     try {

        //                         if ((Object.keys(data[0]).includes("DO") || Object.keys(data[0]).includes("F12"))) {
        //                             setFile({ ...file, data: data, cols: make_cols(ws['!ref']) })
        //                             setLoading(false)
        //                         }
        //                     } catch (error) {

        //                     }
        //                 }
        //             })
        //         } else {
        //             const wsname = wb.SheetNames[0];
        //             const ws = wb.Sheets[wsname];
        //             const data = XLSX.utils.sheet_to_json(ws, { "defval": " " });
        //             setFile({ ...file, data: data, cols: make_cols(ws['!ref']) })
        //             setLoading(false)
        //         }
        //     }
        //     if (rABS) {
        //         reader.readAsBinaryString(files[0]);
        //     } else {
        //         reader.readAsArrayBuffer(files[0]);
        //     };
        //     setLoading(false)
        //     setEnviar(true)
    //     };
    // }

    function handleChangeFile(event) {
        setLoading(true)
        try {
            const files = event.target.files
            setEstado("Subiendo Archivo")
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = (e) => {
                const bstr = e.target.result
                const wb = XLSX.read(bstr, {
                    type: rABS ? 'binary' : 'array', bookVBA: true, cellDates: true,
                    cellNF: false,
                    cellText: false,
                    raw: false,
                })
                if(path==="ManifiestoParis"){
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = XLSX.utils.sheet_to_json(ws, { "defval": " " });
                    const colsToDelete = [
                        "Identificador", 
                        "Hojas de Ruta", 
                        "PILOTO NOTIFICACION VENTANAS", 
                        "AGRUPACIONES DE TRANSPORTES", 
                        "Transportes", 
                        "Fecha estimada", 
                        "Fecha Llegada", 
                        "Estado", 
                        "Subestado", 
                        "Destino",
                        "Usuario móvil",
                        "Teléfono usuario",
                        "Fecha primer intento",
                        "# intentos",
                        "Modo de calce",
                        "Latitud dirección",
                        "Longitud dirección",
                        "FECHAEMI",
                        "NUMSOLGUI",
                        "FECTRANTSL",
                        "FECDESFIS",
                        "FECEMIGUI",
                        "DEPTO",
                        "CODREGI",
                        "CODCIUD",
                        "CODCOMU",
                        "LOCALVENTA",
                        "FECPAG",
                        "JORNADA",
                        "FECSOLDES",
                        "ASN_ID",
                        "DO_ID",
                        "RUT_EMP",
                        "DESC_EMP",
                        "TIPOGUI",
                        "TIPO_ORDEN",
                        "TIPO_CARGA",
                        "URLGUIA",
                        "URLCARGA",
                        "Cantidad despachada",
                        "LINE_ID",
                        "CARTON_STATUS",
                        "CARTON_SUB_STATUS",
                        "CARTON_TIMESTAMP",
                        "Guía completada",
                        "¿Quien Recibe?",
                        "Código Autorización",
                        "Expectativa",
                        "Razón de desistir"
                    ];
                    data.forEach(row => {
                        colsToDelete.forEach(col => delete row[col]);
                    });
                    setFileConversor({ data: data, cols: make_cols(ws['!ref']) })
                } else {
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = XLSX.utils.sheet_to_json(ws, { "defval": " " });
                    setFileConversor({ data: data, cols: make_cols(ws['!ref']) })
                }
               
            }
            try{
                if (rABS) {
                    reader.readAsBinaryString(files[0]);
                } else {
                    reader.readAsArrayBuffer(files[0]);
                }
                //setEnviar(true)
            } catch (error) {
                console.log(error)
            }    
        setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (fileConversor.data) {
            setEnviar(true)
        }
    })

    const handleManifestDate = async (date) => {
        setManifestQ('')
        setLoading(true)
        setDateManifest(date)
        if (titulo === 'Manifiesto Ripley') var url = 'api/manifest/ripley/redex?date='
        else if (titulo === 'Manifiesto Nclientes') var url = 'api/manifest/nclientes?date='
        await axios.get(url_backend_py+ url+formatDate(date, 'us'), {
            headers: {
                'Access-Control-Allow-Origin' : '*',
                client : '909802b269dee093387e26c6c7ace636f180eac29f58c7d8009b637c4babf1ce'
            }
        }).then((response)=> {
            if (response.data === 0) {
                MySwal.fire({
                    title: 'Info',
                    icon: 'info',
                    text: 'No hay manifiesto disponible para '+ formatDate(date,'es'),
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#009B78',
                    showDenyButton: false,
                    customClass:{
                        confirmButton: 'spread-font spread-width-100'
                    }
                }).then(()=>{
                    setLoading(false)
                    setDateManifest('')
                    setButtonEnabled(false)
                })
            } else {
                setManifestQ(response.data)
                setButtonEnabled(true)
                setLoading(false)
            }
        })
    }

    const handleDownloadBlobFile = (url, filename) => {
        axios.get(url,{
            responseType: 'blob'
        })
        .then((res) => {
            fileDownload(res.data, filename)
        })
    }

    const sectorizedManifest = async (event)=>{
        setLoading(true)
        setEstado("Procesando Datos")
        const headers = {
            'Access-Control-Allow-Origin' : '*',
            client : '909802b269dee093387e26c6c7ace636f180eac29f58c7d8009b637c4babf1ce',
            'X-Requested-With': 'XMLHttpRequest' 
        }
        const formData = new FormData();
        const files = event.target.files
        for(let i = 0; i< files.length;i++){
            formData.append(`file[${i}]`, files[i]);
        }
        try {
            await axios.post(url_backend_py+ 'api/manifest/sectorizer',formData, { headers }).then((response)=>{
                const url = response.data.urlFiles
                const filename = url.split('/')[4]
                handleDownloadBlobFile(url, filename)
                setLoading(false)
            }).finally(()=>{
                MySwal.fire({
                    title: 'Éxito',
                    icon: 'success',
                    text: 'Archivo sectores generado exitosamente',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#009B78',
                    showDenyButton: false,
                    customClass:{
                        confirmButton: 'spread-font spread-width-100'
                    }
                })
            })
        } catch (err) {
            handleError(err, 'Error', 'error')
        }
        
    }
    
    const manifestAutomatized = async() => {
        setLoading(true)
        const data = {
            date : formatDate(dateManifest, 'us'),
            user : currentUser.displayName,
            env  : "Production",
            ft   : 0,
            dl   : 0,
            dest : [
                currentUser.email,
                'ignacio.sanchez@spread.cl'
            ]
        }
        const headers = {
            'Access-Control-Allow-Origin' : '*',
            client : '909802b269dee093387e26c6c7ace636f180eac29f58c7d8009b637c4babf1ce',
            'X-Requested-With': 'XMLHttpRequest' 
        }
        try {
            if (titulo === 'Manifiesto Ripley') {
                var url = 'api/manifest/ripley/redex'
            } else if (titulo === 'Manifiesto Nclientes') {
                var url = 'api/manifest/nclientes'
            } else if (titulo === 'Manifiesto Paris DaaS') {
                var url = 'api/manifest/paris/daas'
            }
            await axios.post(url, data, { headers, baseURL: url_backend_py })
            .then(r => {
                if (r.status == 204) {
                    setLoading(false)
                    MySwal.fire({
                        title: 'Info',
                        icon: 'info',
                        text: 'No hay manifiestos disponibles para '+ formatDate(dateManifest,'es'),
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#009B78',
                        showDenyButton: false,
                        customClass:{
                            confirmButton: 'spread-font spread-width-100'
                        }
                    })
                    setDateManifest('')
                } else {
                    const url = r.data.urlFiles
                    const filename = url.split('/')[4]
                    handleDownloadBlobFile(url, filename)
                    setLoading(false)
                    setManifestQ('')
                    setDateManifest('')
                    MySwal.fire({
                        title: 'Éxito',
                        icon: 'success',
                        text: 'Manifiesto generado exitosamente',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#009B78',
                        showDenyButton: false,
                        customClass:{
                            confirmButton: 'spread-font spread-width-100'
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload()
                        }
                        else window.location.href = '/planning'
                    })
                }
            })
        } catch (err) {
            setManifestQ('')
            handleError(err, 'Error', 'error')
        } finally {
            setDateManifest('')
            setManifestQ('')
        }
    }

    const manifestConversor = async() => {
        setLoading(true)
        setEstado("Procesando Datos")
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin' : '*',
            client : '909802b269dee093387e26c6c7ace636f180eac29f58c7d8009b637c4babf1ce',
            'X-Requested-With': 'XMLHttpRequest' 
        }
        const data = {
            local: Lof,
            date : formatDate(dateManifest, 'us'),
            user : currentUser.displayName,
            env  : "Production",
            ft   : 0,
            dl   : 0,
            dest : [
                currentUser.email,
                'ignacio.sanchez@spread.cl'
            ]
        }
        const formData = new FormData();
        //if (titulo === 'Manifiesto Paris') formData.append('manifest',cleanParisManifest())
        //formData.append('manifest',cleanParisManifest())
        formData.append('manifest', JSON.stringify(fileConversor.data, null, 2))
        formData.append('data',JSON.stringify(data))
        if (titulo === 'Manifiesto Falabella') {
            var url = 'api/manifest/falabella'
            //var filename = 'manifiesto_ripley_redex_metropolitana_'+formatDate(dateManifest,'es')+'.xlsx'
        } else if (titulo === 'Manifiesto Paris') {
            var url = 'api/manifest/paris'
            //var filename = 'manifiesto_nclientes_'+formatDate(dateManifest,'es')+'.xlsx'
        }
        try {
            await axios.post(url_backend_py+ url,formData, { headers }).then((response)=>{
                console.log(response.data.urlFiles)
                const url = response.data.urlFiles
                const filename = url.split('/')[4]
                handleDownloadBlobFile(url, filename)
                setLoading(false)
                path = ''
                MySwal.fire({
                    title: 'Éxito',
                    icon: 'success',
                    text: 'Manifiesto generado exitosamente',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#009B78',
                    showDenyButton: false,
                    customClass:{
                        confirmButton: 'spread-font spread-width-100'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                    else window.location.href = '/planning'
                })
            })
        } catch (err) {
            handleError(err, 'Error', 'error')
        }
    }


    // async function EnviarFile() {
    //     setLoading(true)
    //     setEstado("Procesando Datos")
    //     if (path === "ManifiestoFalabella") path = path + "/" + Lof
    //     await fetch(url_backend_pi + path, {
    //         method: "POST",
    //         data: JSON.stringify(file.data, null, 2),
    //         mode: "cors",
    //         headers: { 'Content-Type': 'application/json', },
    //         body: JSON.stringify(file.data, null, 2)
    //     }).then((response) => response.json())
    //         .then((responseJson) => {
    //             setEstado("Creando Archivo Final")
    //             setarchivosFinales(responseJson)
    //             responseJson.map((value, index) => {
    //                 if (value[Object.keys(value)[0]].length > 0) {

    //                     let fileName = Object.keys(value)[0]
    //                     var ws = XLSX.utils.json_to_sheet(value[Object.keys(value)[0]], { sheetStubs: true });
    //                     var wb = XLSX.utils.book_new();
    //                     var range = XLSX.utils.decode_range(ws['!ref']);
    //                     for (var r = range.s.r; r <= range.e.r; r++) {
    //                         for (var c = range.s.c; c <= range.e.c; c++) {
    //                             var cellName = XLSX.utils.encode_cell({ c: c, r: r });
    //                             ws[cellName].z = '@';
    //                         }
    //                     }
    //                     XLSX.utils.book_append_sheet(wb, ws, "sheet");
    //                     XLSX.writeFile(wb, fileName + ".xlsx");
    //                     setLoading(false)
    //                     MySwal.fire({
    //                         title: 'Éxito',
    //                         icon: 'success',
    //                         text: 'Manifiesto generado exitosamente',
    //                         confirmButtonText: 'Listo',
    //                         confirmButtonColor: '#009B78',
    //                         showDenyButton: false,
    //                         customClass:{
    //                             confirmButton: 'spread-font spread-width-100'
    //                         }
    //                     }).then((result) => {
    //                         if (result.isConfirmed) {
    //                             window.location.reload()
    //                         }
    //                         else window.location.href = '/planning'
    //                     })
    //                 }
    //                 setLoading(false)
    //                 return true
    //             })
    //             setRecibido(true)
    //             setNombre('')
    //         }).catch(error => {
    //             MySwal.fire({
    //                 title: error,
    //                 icon: 'error',
    //                 text: 'Hubo un problema al generar el manifiesto, favor contactar a TI',
    //                 confirmButtonText: 'OK',
    //                 confirmButtonColor: '#009B78',
    //                 showDenyButton: false,
    //                 customClass:{
    //                     confirmButton: 'spread-font spread-width-100'
    //                 }
    //             }).then((result) => {
    //                 if (result.isConfirmed) window.location.reload()
    //                 else window.location.href = '/planning'
    //             })
    //         }); 
    // }
    return (
        <Col className='text-center align-self-center justify-content-md-center'>
            {path === 'ManifiestoAutomatizado' ? (
                <div className='d-inline-flex w-25 flex-column' style={{minWidth: '150px', marginTop: '10em'}}>
                    <DatePicker 
                        locale='es' 
                        selected={dateManifest}
                        onChange={(date) => handleManifestDate(date)}
                        todayButton='Seleccionar Hoy'
                        filterDate={enabledDays}
                        placeholderText="Seleccione fecha"
                        dateFormat='dd/MM/yyyy'
                        maxDate={addDays(new Date(), 1)}
                        popperPlacement='top'
                        className=' text-center form-control spread-font'
                        ref={target}
                    />
                    <OverlayTrigger placement='right' trigger="manual" show={manifestQ} overlay={
                        <Tooltip id='tooltip-right'>
                          <strong>{ manifestQ }</strong> bultos disponibles.
                        </Tooltip>
                      }
                    >
                       <Button
                            className='mt-2 spread-font btn btn-primary'
                            variant="contained"
                            style={{ backgroundColor: 'rgb(0, 155, 120)', color: 'white' }}
                            onClick={() => { manifestAutomatized() }}
                            disabled={!buttonEnabled || loading}
                        >
                            Generar y Descargar
                        </Button>
                    </OverlayTrigger>
                    <Alert className='mt-4'>
                        <strong>Fecha seleccionada</strong> representa el <strong>día a planificar</strong>
                    </Alert>
                    <span style={{fontSize: '12px'}}>
                        <strong>ejemplo:</strong> si está planificando la carga del día anterior para el despacho del día siguiente,
                        <p>debe seleccionar el día siguiente.</p>
                    </span>
                </div>
            ) : (
                <>
                    {!recibido &&
                        <>
                            <FilePond
                                //key={Date.now()}
                                allowMultiple={true}
                                acceptedFileTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                                files={files}
                                onupdatefiles={fileItems => {
                                    setFiles(fileItems.map(fileItem => fileItem.file));
                                }}
                                credits={false}
                                labelIdle='Arrastra y suelta el o los archivos de manifiesto o <span class="filepond--label-action">Búscalos en tu ordenador</span>'
                                //labelIdle=""
                                //stylePanelAspectRatio="0"
                            />
                            {/* <input
                                accept={SheetJSFT}
                                style={{ display: 'none' }}
                                id="contained-button-file"
                                type="file"
                                multiple
                                onChange={(event) => { titulo == 'Separador por Sector' ? sectorizedManifest(event) : handleChangeFile(event) }}
                            /> */}
                            {/* <label htmlFor="contained-button-file">
                                <img
                                    alt=''
                                    style={{ width: 200 }}
                                    src='https://cdn1.iconfinder.com/data/icons/famous-brand-apps/100/_-04-512.png' />
                                <Button
                                    style={{ backgroundColor: 'rgb(0, 155, 120)' }}
                                    variant="contained" color="primary" component="span">
                                    Seleccionar Archivo
                                </Button>
                            </label> */}
                        </>
                    }
                    {Enviar && !recibido &&
                        <>
                            {
                                path === "ManifiestoFalabella" &&
                                <ButtonGroup
                                    style={{ marginLeft: 20 }}
                                    aria-label="outlined secondary button group">
                                    <Button
                                        variant={Lof === 'LOF1' ? "contained" : "outlined"}
                                        onClick={() => { setLof("LOF1") }}
                                    >LOF1</Button>
                                    <Button
                                        variant={Lof === 'LOF2' ? "contained" : "outlined"}
                                        onClick={() => { setLof("LOF2") }}
                                    >LOF2</Button>
                                    <Button
                                        variant={Lof === 'FBY' ? "contained" : "outlined"}
                                        onClick={() => { setLof("FBY") }}
                                    >FBY</Button>
                                    <Button
                                        variant={Lof === 'HUBXD' ? "contained" : "outlined"}
                                        onClick={() => { setLof("HUB XD") }}
                                    >HUBXD</Button>
                                </ButtonGroup>
                            }
                            <Button
                                className='mx-3 spread-font btn btn-primary'
                                variant="contained"
                                style={{ backgroundColor: 'rgb(0, 155, 120)', color: 'white' }}
                                onClick={() => { manifestConversor() }}
                                disabled={!Lof && path === "ManifiestoFalabella"}
                            >ENVIAR</Button>
                        </>
                    }
                    {
                        archivosFinales.map((value, index) => {
                            return (
                                <Button
                                    key={index}
                                    color='primary'
                                    variant="contained"
                                    style={{ margin: 5, fontWeight: 'bold', backgroundColor: 'rgb(0, 155, 120)' }}
                                    disabled={value[Object.keys(value)[0]].length > 0 ? false : true}
                                >
                                    {Object.keys(value)[0]}
                                </Button>)
                        })
                    }
                </>
            )

            }

        </Col>
    )
}

export default UploadFile
