import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ToolsRipley from '../components/OptionTarget/TargetRipley'

import InformationBox from '../components/InformationBox/InformationBox'


export default function RipleyTools() {
    return (
        <>
            <Container fluid className='gx-0'>
                <Row className='mt-5'>
                    <h3 className='spread-font text-dark text-center'>Ripley</h3>
                </Row>
                <Row>
                   <Col md={9} style={{'overflowY': 'auto', 'height': 'calc(100vh - 200px)'}}>
                        <ToolsRipley />
                   </Col>
                   <Col md={3}>
                        <InformationBox />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

