import * as React from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    TextField,
    Tooltip,
    IconButton
} from '@mui/material'
import { Col, Row } from 'react-bootstrap'
import { 
    CloudDownload, 
    Visibility
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import axios from 'axios'
import propTypes from 'prop-types'

const BASE_URL = process.env.REACT_APP_URL

export default function Details({ timId, date, pdfLink, state, stampnumber, licenceplate }) {
    const [open, setOpen] = useState(false)
    const [details, setDetails] = useState({
        origin: '',
        reason: '',
        responsibleEmail: ''
    })
    const spreadColor = '#009b78'

    const handleClickOpen = async () => {
        setOpen(true);
        const response = await axios.get(BASE_URL + '/li/movement/' + timId)
        await setDetails(response.data.data)
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleClickOpen} aria-label="delete">
                <Visibility />
            </IconButton>
            <Dialog
                style={{
                    width: '100%',
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Detalles {String(date.substring(0, 10).replaceAll("-", "/"))}
                </DialogTitle>
                <DialogContent>
                    <Row style={{
                        marginTop: '1%',
                    }}>
                        <Col xs={12} className='pb-1'>
                            <TextField InputProps={{
                                readOnly: true,
                            }}
                                fullWidth
                                label="N° TIM"
                                value={timId}
                            />
                        </Col>
                        <Col xs={12} className='py-1'>
                            <TextField InputProps={{
                                readOnly: true,
                            }}
                                fullWidth
                                label="Estado"
                                value={state}
                            />
                        </Col>
                        <Col xs={12} className='py-1'>
                            <TextField InputProps={{
                                readOnly: true,
                            }}
                                fullWidth
                                label="Origen"
                                value={details.origin}
                            />
                        </Col>
                        <Col xs={12} className='py-1'>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Destino"
                            />
                        </Col>  
                        <Col xs={12} className='py-1'>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Patente"
                                value={licenceplate}
                            />
                        </Col>  
                        <Col xs={12} className='py-1'>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="N° sello"
                                value={stampnumber}
                            />
                        </Col>  
                        <Col xs={12} className='py-1'>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth label="Correo destinatario"
                                value={details.responsibleEmail}
                            />
                        </Col>
                        <Col xs={12} className='py-1'>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                multiline
                                rows={3}
                                fullWidth
                                label="Comentarios"
                                value={details.observations}
                            />
                        </Col>
                        <Col xs={12} className='py-1'>

                            { 
                                pdfLink ? 
                                <Button startIcon={<CloudDownload />} href={pdfLink} download target="_blank" rel="noopener noreferrer" fullWidth size="large" variant="outlined"
                                    style={{
                                        color: 'black',
                                    }}
                                >
                                    Descargar Archivo
                                </Button>
                                :
                                <Tooltip title="Archivo no disponible" placement="bottom">
                                    <Button startIcon={<CloudDownload />} disableRipple rel="noopener noreferrer" fullWidth size="large" variant="outlined"
                                        style={{
                                            color: 'black',
                                        }}
                                        >
                                        Descargar Archivo
                                    </Button>
                                </Tooltip>
                            }


                        </Col>
                    </Row>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                    <Button onClick={handleClose}
                        style={{
                            backgroundColor: spreadColor,
                        }}
                        variant="contained"
                        autoFocus
                    >
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

Details.propTypes = {
    timId: propTypes.number,
    date: propTypes.string,
    pdfLink: propTypes.string,
    state: propTypes.string,
    stampnumber: propTypes.string,
    licenceplate: propTypes.string
}
