export const CardsData = [
    {
        description: 'fechas',
        title: 'Guías no definitivas Paris',
        path: '',
        cliente: 'paris',
        id: 13,
        img: 'img/logo-paris.png',
        button: 'Herramienta 13',
        class: 'active',
        Text: 'Rango de Fechas.',
        Text1: 'Beetrack Paris y Beetrack Spread',
        Text2: 'Listado Guías no definitivas de Paris',

    },
    {
        title: 'Informes de guías pendientes Paris.',
        path: '',
        cliente: 'paris',
        id: 14,
        img: 'img/logo-paris.png',
        button: 'Herramienta 15',
        class: 'active',
        Text: 'Listado con guías.',
        Text1: 'Beetrack Paris y Beetrack Spread.',
        Text2: 'Informes de calce pendiente y de LI pendiente.',
    }
    ,
    {
        title: 'Comparación estados Paris - Spread.',
        path: '',
        cliente: 'paris',
        id: 11,
        img: 'img/logo-paris.png',
        button: 'Herramienta 16',
        class: 'active',
        Text: 'Informe de Creación desde Beetrack Paris, sin incluir item.',
        Text1: 'Beetrack Paris y Beetrack Spread.',
        Text2: 'Información de guías, Estados Paris, Estados Spread, guía definitiva o bloqueada.',
    }
]


