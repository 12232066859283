export const CardsData = [
     {
        title: 'Herramienta 5',
         path: '',
         cliente:'Falabella',
         id: 5,
         img: 'img/logo-paris.png',
         button: 'Herramienta 16',
         class: 'active',
         Text: ' Excel con columna GUIAS.',
         Text1: 'Desde Sheet REM Falabella.',
         Text2: 'Tipo(Robo, Extravío y Mermas).',
     },
    {
        description:'modal simple',
        title: 'Datos de Números de Viajes',
        path: '',
        cliente:'Falabella',
        id: 16,
        // img: 'img/logo-ripley.png',
        button: 'Ingresar',
        class: 'active',
        Text: 'Numero de viajes.',
        Text1: 'Desde API Falabella.',
        Text2: 'Csv con todos los datos asociados a ese número de viaje.',
    },
 ]
 
 
 