import React from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import { CardsData } from '../../CardDataOne'

import styles from '../../styles/Scroll.module.css'
import '../../styles/cardsToolsOne.css'

import ModalCard from '../Modal/ModalCard'

export default function ToolsOneTarget() {
     return (
          <Container fluid className='w-100 h-100 g-0 align-items-center float-start scrollspy-example'>
               <Row  className="w-100">
                    {
                         CardsData.map((item, index) => {
                              return (
                                   <>
                                        <Col className='justify-content-evenly'>
                                             <Card className="card-style-one" key={index}>
                                                  <Card.Header className="card-header-one">
                                                       <Row className="HeaderContainer-o">
                                                            <Card.Text className="card-text-o">{item.title}</Card.Text>
                                                            <Row>
                                                                 <Card.Text className="text-instruction-o">
                                                                      <p className="text-p">
                                                                           <a className="text-a-o">Input: </a>
                                                                           {item.Text}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                            <Row>
                                                                 <Card.Text className="text-instruction-o">
                                                                      <p className="text-p">
                                                                           <a className="text-a-o">Data: </a>
                                                                           {item.Text1}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                            <Row>
                                                                 <Card.Text className="text-instruction-o">
                                                                      <p className="text-p">
                                                                           <a className="text-a-o">Output: </a>
                                                                           {item.Text2}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                       </Row>
                                                  </Card.Header>
                                                  <Card.Body className="card-body-btn-tools-f">
                                                       <ModalCard Cliente={item.cliente} Titulo={item.title} Id={item.id} />
                                                       {/* <Button size="lg" className="card-btn" variant={`align-middle ${item.class}`} href={item.path}>
                                                            {item.button}
                                                       </Button> */}
                                                  </Card.Body>
                                             </Card>
                                        </Col>
                                   </>
                              )

                         })
                    }
               </Row>
          </Container>
     )
}
