import React from 'react'
import { Container, Nav, Tab } from 'react-bootstrap'
import ToolsParis from './views/Paris'
import ToolsRipley from './views/Ripley'
import ToolsSpread from './views/Spread'
import ToolsOne from './views/ToolsOne'
import './styles/tabs.css'

export default function Tools() {
    return (
        <Container fluid>
            <Tab.Container id='submenu-header' defaultActiveKey='toolsSpread'>
                <Nav variant='pills' className='mt-4 mb-2 justify-content-center border-bottom border-1 spread-font'>
                    <Nav.Item bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' eventKey='toolsSpread'><p>Spread</p></Nav.Link>
                    </Nav.Item>
                    <Nav.Item  bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' eventKey='toolsParis'><p>Paris</p></Nav.Link>
                    </Nav.Item>
                    <Nav.Item bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' eventKey='toolsRipley'><p>Ripley</p></Nav.Link>
                    </Nav.Item>
                    <Nav.Item bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' eventKey='toolsOne'><p>Falabella</p></Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey='toolsParis'>
                        <ToolsParis />
                    </Tab.Pane>
                    <Tab.Pane eventKey='toolsRipley'>
                        <ToolsRipley />
                    </Tab.Pane>
                    <Tab.Pane eventKey='toolsSpread'>
                        <ToolsSpread />
                    </Tab.Pane>
                    <Tab.Pane eventKey='toolsOne'>
                        <ToolsOne />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>
    )
}
