import * as React from 'react'
import { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { Input, Label, FormGroup, ModalBody, Modal } from 'reactstrap'
import { Dropdown, ButtonGroup } from 'react-bootstrap'
import styles from '../../styles/formExpedition.module.css'
import { BsX, BsPlus, BsCloudDownload, BsPaperclip } from 'react-icons/bs'
import IconX from '../../assets/X.png'
import clip from '../../assets/clip.png'
import NubeFull from '../../assets/NubeFull.png'
import dropdownstyles from '../../styles/forms.module.css'

export default function FormReception() {
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
        <div className='pb-3 text-end'>

            <Dropdown 
                bsPrefix='dropdownActions'
                variant='success'
                as={ButtonGroup}
                //size='lg' 
                className={`px-1`}
            >
                <Dropdown.Toggle as='button' bsPrefix='headerButton' className={`dropdown-toggle ${dropdownstyles.headerButton}`} variant="success" id="dropdown-basic">
                    Acciones
                </Dropdown.Toggle>
                <Dropdown.Menu className='contentButton'>
                    <Dropdown.Item href="#/receive">Recepcionar</Dropdown.Item>
                    <Dropdown.Item href="#/edit">Editar</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
        <Dialog className={styles.modalContainer} maxWidth='860px' open={open} onClose={handleClose}>
            <DialogTitle className={styles.modalHeader} id="alert-dialog-title">
                <div className={styles.titleModalHeader}>
                    Formulario
                    <img src={IconX} className={styles.IconX} onClick={handleClose}></img>
                </div>
            </DialogTitle>  
            <ModalBody className={styles.modalBody}>
                <FormGroup>
                    <div className={styles.divInputDestino}>
                        <Input className={styles.InputDestino} type="text" id="inputOrigen" placeholder="Origen"></Input>
                    </div>
                    <div className={styles.divInputOrigen}>
                        <Input className={styles.InputOrigen} type="text" id="inputDestino" placeholder="Destino"></Input>
                    </div>
                    <div>
                        <Label className={styles.TxtLabel}>Datos del Conductor</Label>
                        <Input className={styles.styleInputForm} id='' type='text' placeholder='Nombre'></Input>
                        <Input className={styles.styleInputForm} id='' type='text' placeholder='Patente'></Input>
                        <Input className={styles.styleInputForm} id='' type='number' placeholder='N° Sello'></Input>
                        <Input className={styles.styleInputForm} id='' type='email' placeholder='Correo destinatario'></Input>
                        <Input className={styles.InputObservacion} type='text' id='' placeholder='Observaciones'></Input>
                    </div>
                    <div>
                        <div className={styles.InputUpFile}>
                            <p className={styles.InputText}>Adjuntar Archivo</p>
                            <img src={clip} className={styles.clipStyle}></img>
                            <Input className={styles.InputUploadFile} type='file' multiple id='btnUpload'></Input>
                        </div>
                        <div className={styles.DivDownloadFile}>
                            <p className={styles.InputTextDownload}>Descargar Archivo</p>
                            <img src={NubeFull} className={styles.NubeFull}></img>
                            <Input className={styles.InputDownloadFile} type='file' id='btnDownload'></Input>
                        </div>
                    </div>
                    <div>
                        <div className={styles.btnCerrar}>
                            <Button type='' onClick={handleClose}>
                            <p className={styles.pCa}>CANCELAR</p>
                            </Button>
                        </div>
                        <div className={styles.btnContinuar}>
                            <Button type=''>
                                <p className={styles.pC}>CONTINUAR</p>
                            </Button>
                        </div>
                    </div>
                </FormGroup>
            </ModalBody>
        </Dialog>
    </>
  )
}
