import { types } from '../types/types'

const defaultState = { mode: types.REVERSE_LOGISTICS_MODE_UPLOAD_FILE, orderNo: null }

export const reverseLogisticsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.REVERSE_LOGISTICS_SET_MODE:
      return { ...state, mode: action.payload }
    case types.REVERSE_LOGISTICS_LOAD_CURRENT_SUCCESS:
      return {
        ...state,
        current: {
          ...action.payload
        },
        orderNo: action.payload.orderNo
      }
    case types.REVERSE_LOGISTICS_LOAD_CURRENT_NOT_FOUND:
      return { ...state, current: null }
    case types.REVERSE_LOGISTICS_CLEAN_CURRENT:
      return { ...state, current: null, orderNo: null }
    case types.REVERSE_LOGISTICS_LOGOUT_CLEANING:
      return { ...state, current: null, orderNo: null }
    case types.REVERSE_LOGISTICS_SAVE_ORDER_NUMBER:
      return { ...state, orderNo: action.payload }
    case types.REVERSE_LOGISTICS_CLEAN_ORDER_NUMBER:
      return { ...state, orderNo: null }
    default:
      return state
  }
}