import { Row, Col, Button, Form, Modal, Dropdown, ButtonGroup  } from 'react-bootstrap'
import React, { useState, useRef } from 'react'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { utils, write } from 'xlsx'
import MUIDataTable from 'mui-datatables'
import axios from 'axios'
import { saveAs } from 'file-saver'
import Swal from 'sweetalert2'
import { useForm } from '../../hooks/useForm'
import Details from './views/MovementsExpeditionDetails'
//import DataTable from 'react-data-table-component'
import FormProsecution from './components/Forms/FormProsecution'
import FilterProsecution from './components/Forms/FilterProsecution'
//import "react-data-table-component-extensions/dist/index.css";
import { GoSearch } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { FaRegEye, FaFileUpload } from 'react-icons/fa'
import clsx from "clsx";
import './styles/searcher.css'
import './styles/datatable.css'
import dropdownstyles from './styles/forms.module.css'
//import $ from 'jquery'

const theme = createTheme()
responsiveFontSizes(theme)

const BASE_URL = process.env.REACT_APP_URL

function DetailsModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalles
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Select aria-label="Default select example">
                <option>Seleccionar estado</option>
                <option value="1">En Logística Inversa</option>
                <option value="2">En Transito a Logística Inversa</option>
                <option value="3">Devuelto a Logística Inversa</option>
                <option value="4">Devuelto a Logística Inversa</option>
            </Form.Select>
        </Modal.Body>
      </Modal>
    );
}


function ChangeStateModal(props) {
    function closeModal(){
        //$('#change-state').modal("hide");
    }
    return (
      <Modal
        {...props}
        
        aria-labelledby="change-state"
        centered
//        style={{background: '#fff', color: '#272726'}}
      >
        <Modal.Header closeButton >
          <Modal.Title id="change-state" className="text-center">
            Cambiar Estado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Select aria-label="Default select example">
                <option>Observaciones</option>
                <option value="1">En Logística Inversa</option>
                <option value="2">En Transito a Logística Inversa</option>
                <option value="3">Devuelto a Logística Inversa</option>
                <option value="4">Devuelto a Logística Inversa</option>
            </Form.Select>
            <Form.Control
                type="text"
                id="observations"
            
                style={{height: '100px'}}
                as="textarea" 
                rows={3} 
                placeholder='Observaciones'
            />
            <Form.Group controlId="formFile" className="mt-4 mb-3">
                <Form.Label><FaFileUpload/> Adjuntar archivo <Form.Control type="file" /></Form.Label>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button size="lg" style={{
                height: '60px',
                width: '47%',
                background: '#FFF',
                borderRadius: '10px',
                borderColor: '#EA4335'
                }}
                onClick={closeModal}>
                <span style={{
                    fontWeight: '550',
                    fontSize: '15px',
                    fontFamily: 'Nunito',
                    lineHeight: '22px',
                    marginTop: '-30px',
                    color: '#EA4335'
                }}
                
                >CANCELAR</span>
            </Button>
            <Button type='submit' size="lg" style={{
                height: '60px',
                width: '47%',
                background: '#009B78',
                borderRadius: '10px',
                borderColor: '#009B78'
                }}>
                <span style={{
                    fontWeight: '550',
                    fontSize: '15px',
                    fontFamily: 'Nunito',
                    lineHeight: '22px',
                    marginTop: '-30px',
                    color: '#FFF'
                }}>CONTINUAR</span>
            </Button>
        </Modal.Footer>
      </Modal>
    );
}

function Processing() {
    const [detailModalShow, setDetailModalShow] = useState(false);
    const [changeStateModalShow, setChangeStateModalShow] = useState(false);
    const [expSearch, setExpSearch] = useState(false)
    const [search, setSearch] = useState('')
    const refSearch = useRef(null)
    const [someChecked, setSomeCheked] = useState(false)

    const handleSearch = (event) => {
        setSearch(event.target.value)
    }

    const toggleExpSearch = () => {
        setExpSearch(!expSearch)
        refSearch.current.focus()
    }
    const data = [
        {
            fecha: '10/07/2022',
            n_folio: 1312321321,
            n_tim: 131233213,
            last_state: 'En Bodega'
        },
        {
            fecha: '10/07/2022',
            n_folio: 1312321321,
            n_tim: 131233213,
            last_state: 'En Bodega'
        },
        {
            fecha: '10/07/2022',
            n_folio: 1312321321,
            n_tim: 131233213,
            last_state: 'En Bodega'
        },
        {
            fecha: '10/07/2022',
            n_folio: 151512,
            n_tim: 412421,
            last_state: 'En Transito'
        },
        {
            fecha: '10/07/2022',
            n_folio: 131322,
            n_tim: 125125,
            last_state: 'En Bodega'
        },
        {
            fecha: '10/07/2022',
            n_folio: 12312,
            n_tim: 1512521,
            last_state: 'En Bodega'
        },
        {
            fecha: '10/07/2022',
            n_folio: 1312321321,
            n_tim: 131233213,
            last_state: 'En Bodega'
        } 
    ]
    const columns = [
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true
        },
        {
            name: 'N° Folio',
            selector: row => row.n_folio,
            sortable: true
        },
        {
            name: 'N° TIM',
            selector: row => row.n_tim,
            sortable: true
        },
        {
            name: 'Último Estado',
            selector: row => row.last_state,
            sortable: true
        },
        {
            name: 'Detalles',
            sortable: false,
            selector: "null",
            cell: (d) => [
                <FaRegEye style={{ fontSize: '23px', color: '#787777', cursor: 'pointer' }} onClick={() => setDetailModalShow(true)}/>
            ]
        }
    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    return (
        <>
            <Row className='pt-4 pb-4'>
                <h3 className='spread-font text-dark text-center'>Procesamiento</h3>
            </Row>
            <Row>
                <div className='d-flex justify-content-end px-4 pb-2'>
                    <Form.Control
                        htmlFor='search'
                        ref={refSearch}
                        id='searcher'
                        className={clsx(expSearch && 'exp-search-show')}
                        placeholder="buscar..."
                        onChange={handleSearch}
                    />
                    <Button bsPrefix='searcheractioner' onClick={toggleExpSearch}>{expSearch ? <IoMdClose /> : <GoSearch />}</Button>
                    <Dropdown 
                        bsPrefix='dropdownActions'
                        variant='success'
                        as={ButtonGroup}
                        //size='lg' 
                        >
                        <Dropdown.Toggle as='button' bsPrefix='headerButton' className={`dropdown-toggle ${dropdownstyles.headerButton}`} id="actions">
                            Acciones
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='contentButton'>
                            <Dropdown.Item onClick={() => setChangeStateModalShow(true)}>Cambiar Estado</Dropdown.Item>
                            <Dropdown.Item href="#">Descargar XLS</Dropdown.Item>
                            <Dropdown.Item href="#">Generar TIM</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    </div>

            </Row>
            <Row className='w-100'>
                <Col xs={3}>
                    <FilterProsecution />
                </Col>
                <Col xs={9}>
                    <div style={{ 
                    fontFamily: 'Nunito',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '16px',
                    border: '1px solid rgba(255, 255, 255, 0.418)',
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
                    borderRadius: '10px',
                    height: '790px'
                    }}>
                        {/* <DataTable
                            columns={columns}
                            data={data}
                            selectableRows
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                        /> */}
                    </div>
                </Col>
            </Row>
            <DetailsModal
                show={detailModalShow}
                onHide={() => setDetailModalShow(false)}
            />
            <ChangeStateModal
                show={changeStateModalShow}
                onHide={() => setChangeStateModalShow(false)}
            />
        </>
    )
}  

export const loadingProsecution = payload => {
    const { url } = payload
    return async dispatch => {
      dispatch(startLoading())
      try {
        MySwal.fire({
          title: 'Cargando lista de reprocesos...',
          text: 'Espere por favor...',
          showConfirmButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          willOpen: () => {
            Swal.showLoading()
          }
        })
        dispatch(closeModal())
        const { data } = await axios.get(url)
        dispatch(saveReprocessingListSuccess(data.data))
        dispatch(finishLoading())
        MySwal.fire({
          title: `Lista de reprocesos cargada correctamente para el ${dayjs(data.data.reprocessingDate).format(
            'DD/MM/YYYY'
          )}`,
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        })
      } catch (err) {
        // eslint-disable-next-line
        dispatch(saveReprocessingFail())
        Swal.fire({
          title: 'Error',
          text: err?.response?.data?.message,
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        })
      }
    }
  }



function ProsecutionOld() {
    const [packageState, setPackageState] = useState('');
    const [values, handleInputChange, reset] = useForm();
    
    const headers_ = [
        {
            name: 'id',
            label: 'N° TIM',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                })
            }
        },
        {
            name: 'processingDate',
            label: 'Fecha',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                }),
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {String(movements[dataIndex].processingDate.substring(0, 10).replaceAll("-", "/"))}
                        </>
                    )
                }
            },
        },
        {
            name: 'timorigin',
            label: 'Origen',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                })
            }
        },
        {
            name: 'timorigin',
            label: 'Destino',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                })
            }
        },
        {
            name: 'observations',
            label: 'Detalles',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                }),
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {
                                <Details
                                    barCode={movements[dataIndex].barcode}
                                    date={movements[dataIndex].processingDate}
                                    pdfLink={movements[dataIndex].pdfLink}
                                    timId={movements[dataIndex].id}
                                    state={movements[dataIndex].movementType}
                                    stampnumber={movements[dataIndex].stampnumber}
                                    licenceplate={movements[dataIndex].licenceplate}
                                />
                            }
                        </>
                    )
                }
            }
        },
        {
            name: 'guias',
            label: 'Guías Totales',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                }),
                customBodyRenderLite: (dataIndex) => {
                    // return (
                    //     <>
                    //         {
                    //             movements[dataIndex].pdfLink &&
                    //             <a href={movements[dataIndex].pdfLink} download target="_blank" rel="noopener noreferrer">
                    //                 <Tooltip title="Descargar PDF" placement="bottom">
                    //                     <IconButton aria-label="delete" color="primary">
                    //                         <CloudDownloadIcon />
                    //                     </IconButton>
                    //                 </Tooltip>
                    //             </a>
                    //         }
                    //         {
                    
                    //             <Tooltip title="Sin PDF" placement="bottom">
                    //                 <span>
                    //                     <IconButton aria-label="delete" disabled color="primary">
                    //                         <CloudDownloadIcon />
                    //                     </IconButton>
                    //                 </span>
                    //             </Tooltip>
                    //         }
                    //     </>
                    // )
                }
            }
        }
    ]

    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        sort: false,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10],
        selectableRows: 'true',
        toolbar: false,
        // onCellClick: async (colData, cellMeta) => {
        //     if (cellMeta.colIndex === 7) {
        //         // const id = movements.find(el => el.barcode === colData).id
        //         const id = movements.find(el => el.barcode === colData).barcode
        //         const response = await axios.get(BASE_URL + '/li/movement/' + id)
        //         await setReverseLogistic(response.data.data)
        //     }
        // },
        onDownload: (columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'

            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = 'pistoleados'
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)

            return false
        },
        textLabels: {
            toolbar: {
                downloadCsv: 'Descargar XLSX',
                search: 'Buscar'
            },
            pagination: {
                next: 'Página Siguiente',
                previous: 'Página Anterior',
                rowsPerPage: 'Filas por página:',
                displayRows: 'de'
            },
            body: {
                noMatch: 'Aún no hay datos para mostrar'
            }
        }
    }

    const [reverseLogistic, setReverseLogistic] = useState([])
    const [movements, setMovements] = useState([])
    const [date, setDate] = useState([])

    const changeState = async (event) => {
        await setPackageState(event.target.value)
        handleInputChange(event)
    }

    const searchByDate = async () => {
        Swal.fire({
            title: 'Cargando lista de seguimientos...',
            text: 'Espere por favor...',
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            }
        })
        setReverseLogistic([])
        const { data } = await axios.post(BASE_URL + '/tim/movements', values)
        await setMovements(data.data)
        if (data.data.length === 0) {
            Swal.fire(
                'No se encontraron datos para la fecha indicada',
                ' ',
                'error'
            )
        } else {
            Swal.fire({
                title: 'Lista de seguimientos cargada correctamente',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    return (
        <>
            <Row className='pt-4'>
                <h3 className='spread-font text-dark text-center'>Procesamiento</h3>
            </Row>
            <Row className='w-100'>
                <FormProsecution />
            </Row>
            <Row className='w-100'>
                <Col xs={3}>
                    <FilterProsecution />
                </Col>
                <Col xs={9}>
                    <MUIDataTable
                        data={movements}
                        columns={headers_}
                        options={options}
                        borderRadius={10}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Processing