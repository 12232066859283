import { Container, Button, Form, InputGroup } from 'react-bootstrap'
import styles from '../../styles/filterProsecution.module.css'
import { BsSearch, BsCalendar } from "react-icons/bs"

export default function FilterProsecution() {
     return (
          <Container fluid className={styles.DivContainer}>
               <Form.Group>
                    <div className='border-bottom'>
                         <h3 className={styles.title}>Filtros</h3>
                    </div>
                    <div className={styles.Container}>
                         <Form.Control className={styles.InputPrincipal} id='' type='' placeholder='N° Folio' autoFocus />
                    </div>
                    <div>
                         <Form.Label className={styles.SegTitle}>Seleccionar Rango de Fecha</Form.Label>
                    </div>
                    <div>
                         <InputGroup className={styles.DivInputFecha}>
                              <InputGroup.Text><BsCalendar/></InputGroup.Text>
                              <Form.Control id='' type='date' className={styles.InputPrincipalFecha} placeholder='dd/mm/aaaa'/>
                         </InputGroup>
                              
                         {/* </div> */}
                         <Form.Control id='' type='text' className={styles.InputPrincipal} placeholder='Origen'/>
                         <Form.Control id='' type='text' className={styles.InputPrincipal} placeholder='Destino'/>
                         <Form.Control id='' type='text' className={styles.InputPrincipal} placeholder='N° TIM'/>
                    </div>
                    <div className='row py-5 mt-5'>
                         <div className='d-grid gap-2 py-3 text-center justify-content-center'>
                         <br/><br/><br/><br/><br/><br/><br/><br/>
                              <Button type='submit' size="lg" className={` ${styles.BtnBuscar}`}>
                                   <BsSearch className={styles.iconBuscar}/>
                                   <span className={styles.TxtBtnBuscar}>BUSCAR</span>
                              </Button>
                         </div>
                    </div>
               </Form.Group>
          </Container>
     )
}
