import { useSelector } from 'react-redux'

export default function AvatarVertical () {
    const { user: currentUser } = useSelector(state => state.auth)
    return (
            <div className="d-flex flex-row align-items-center my-3 custom-profile">
                <img className="rounded-circle profile-image" src={currentUser.photoURL ?? null}/>
                <div className="info-card-text">
                    <div className="fs-lg profile-text text-light">
                        {currentUser.displayName.length>18 ? currentUser.displayName.substring(0,15).concat('...') : currentUser.displayName}
                    </div>
                    <div className="fs-lg profile-text text-light" style={{fontSize: '14px'}}>Colaborador</div>
                </div>
            </div>
        )
}
