import React from 'react'
// import 'rsuite/dist/rsuite.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'dayjs/locale/es'
import './config/pacejs/flash.css'
import './App.scss';

import { Provider } from 'react-redux'
import RouterComponent from './router/RouterComponent'
import { store } from './store/store'

const App = () => (
  	<Provider store={store}>
    	<RouterComponent />
  	</Provider>
)

export default App
