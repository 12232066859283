import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import * as React from 'react'
import axios from 'axios'
import { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { Input, Label, FormGroup, ModalBody, Modal } from 'reactstrap'
import { Dropdown } from 'react-bootstrap'
import styles from '../../styles/formExpedition.module.css'
import { BsX, BsPlus, BsCloudDownload, BsPaperclip } from 'react-icons/bs'
import IconX from '../../assets/X.png'
import clip from '../../assets/clip.png'
import NubeFull from '../../assets/NubeFull.png'
import FileDownload from 'js-file-download'
export default function FormExpedition() {
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

const downloadFile = async (link) => {
    await axios.get(link, { responseType: 'blob' }).then((response) => {
        FileDownload(response.data, 'Plantillas_App_LI.xlsx')
    })
}

const downloadXLSXTemplate = async () => {
    const url =
      'https://res.cloudinary.com/spreadchrisloarryn-2022/raw/upload/v1644647573/Plantillas_App_Li__eantvt.xlsx'
    downloadFile(url)
}

const bajarPlantilla = async () => {
    const data_ = [{ Guia: '', Estado: '', Cliente: '', Motivo: '', Origen: '' }]
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = 'LI_TEMPLATE'
    const ws = XLSX.utils.json_to_sheet(data_)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
}

  return (
    <>
        <div className='pb-2 text-end g-0'>
            <Button className={`${styles.btnCrearExpe}`} onClick={handleClickOpen} style={{border: '2px solid #009B78', borderRadius: 10}}>
                <BsPlus className={styles.iconAddEx}/><p className={styles.BtnTxtExpedition}>Crear Expedición</p>
            </Button>
        </div>
        <Dialog className={styles.modalContainer} maxWidth='860px' open={open} onClose={handleClose}>
            <DialogTitle className={styles.modalHeader} id="alert-dialog-title">
                <div className={styles.titleModalHeader}>
                    Formulario
                    <img src={IconX} className={styles.IconX} onClick={handleClose}></img>
                </div>
            </DialogTitle>  
            <ModalBody className={styles.modalBody}>
                <FormGroup>
                    <div className={styles.divInputDestino}>
                        <Input className={styles.InputDestino} type="text" id="inputOrigen" placeholder="Origen"></Input>
                    </div>
                    <div className={styles.divInputOrigen}>
                        <Input className={styles.InputOrigen} type="text" id="inputDestino" placeholder="Destino"></Input>
                    </div>
                    <div>
                        <Label className={styles.TxtLabel}>Datos del Conductor</Label>
                        <Input className={styles.styleInputForm} id='' type='text' placeholder='Nombre'></Input>
                        <Input className={styles.styleInputForm} id='' type='text' placeholder='Patente'></Input>
                        <Input className={styles.styleInputForm} id='' type='number' placeholder='N° Sello'></Input>
                        <Input className={styles.styleInputForm} id='' type='email' placeholder='Correo destinatario'></Input>
                        <Input className={styles.InputObservacion} type='text' id='' placeholder='Observaciones'></Input>
                    </div>
                    <div>
                        <div className={styles.InputUpFile}>
                            <p className={styles.InputText}>Adjuntar Archivo</p>
                            <img src={clip} className={styles.clipStyle}></img>
                            <Input className={styles.InputUploadFile} type='file' multiple id='btnUpload'></Input>
                        </div>
                        <div className={styles.DivDownloadFile} onClick={downloadXLSXTemplate}>
                            <p className={styles.InputTextDownload}>Descargar Archivo</p>
                            <img src={NubeFull} className={styles.NubeFull}></img>
                            <Input className={styles.InputDownloadFile} type='file' id='btnDownload'></Input>
                        </div>
                    </div>
                    <div>
                        <div className={styles.btnCerrar}>
                            <Button type='' onClick={handleClose}>
                            <p className={styles.pCa}>CANCELAR</p>
                            </Button>
                        </div>
                        <div className={styles.btnContinuar}>
                            <Button type=''>
                                <p className={styles.pC}>CONTINUAR</p>
                            </Button>
                        </div>
                    </div>
                </FormGroup>
            </ModalBody>
        </Dialog>
    </>
  )
}



