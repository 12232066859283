import * as _ from 'lodash'

import { closeBackdrop, closeModal, finishLoading, setError, startLoading } from './ui'

import Swal from 'sweetalert2'
import axios from 'axios'
import dayjs from 'dayjs'
import { types } from '../types/types'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const currentReprocessingSuccess = payload => {
  return {
    type: types.REPROCESSING_LOAD_CURRENT_SUCCESS,
    payload
  }
}

export const saveOrderNumbers = payload => ({
  type: types.REPROCESSING_SAVE_ORDER_NUMBERS,
  payload
})

export const cleanOrderNumbers = () => ({
  type: types.REPROCESSING_CLEAN_ORDER_NUMBERS
})

export const saveReprocessingSuccess = payload => {
  return {
    type: types.REPROCESSING_LIST_SAVE_SUCCESS,
    payload
  }
}

export const saveReprocessingListSuccess = payload => {
  return {
    type: types.REPROCESSING_LIST_SAVE_SUCCESS,
    payload
  }
}

export const saveReprocessingHistoryListSuccess = payload => {
  return {
    type: types.REPROCESSING_HISTORY_LIST_SAVE_SUCCESS,
    payload
  }
}

export const saveReprocessingFail = () => {
  return async dispatch => {
    dispatch(finishLoading())
    dispatch(reprocessingClean())

    dispatch(setError('Error al procesar'))
    dispatch(closeBackdrop())
    Swal.fire('Error', 'Error', 'error')
  }
}

export const startLoadingReprocessingHistoryList = payload => {
  const { url } = payload
  return async dispatch => {
    dispatch(startLoading())
    try {
      MySwal.fire({
        title: 'Cargando lista de reprocesos...',
        text: 'Espere por favor...',
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading()
        }
      })
      dispatch(closeModal())
      const { data } = await axios.get(url)
      dispatch(saveReprocessingHistoryListSuccess(data.data))
      dispatch(finishLoading())
      MySwal.fire({
        title: `Lista de reprocesos cargada correctamente para el ${dayjs(data.data.reprocessingDate).format(
          'DD/MM/YYYY'
        )}`,
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })
      // dispatch(reprocessingClean())
      // dispatch(closeBackdrop())
    } catch (err) {
      // eslint-disable-next-line
      dispatch(saveReprocessingFail())
      Swal.fire({
        title: 'Error',
        text: err?.response?.data?.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 2000
      })
    }
  }
}

export const searchSwitchMode = payload => ({
  type: types.REPROCESSING_SEARCH_SWITCH_MODE,
  payload
})

export const cleanReprocessingListAndHistory = () => ({
  type: types.REPROCESSING_CLEAN_HISTORY_AND_LIST
})

export const cleanReprocessingHistory = () => ({
  type: types.REPROCESSING_CLEAN_HISTORY
})

export const cleanReprocessingList = () => ({
  type: types.REPROCESSING_CLEAN_LIST
})

export const reprocessingSearchSwitchMode = payload => {
  return dispatch => {
    dispatch(cleanOrderNumbers())
    dispatch(cleanReprocessingListAndHistory())
    dispatch(currentReprocessingNotFound())
    dispatch(searchSwitchMode(payload))
  }
}

export const startLoadingReprocessingList = payload => {
  const { url } = payload
  return async dispatch => {
    dispatch(startLoading())
    try {
      MySwal.fire({
        title: 'Cargando guías...',
        text: 'Por favor, espere',
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading()
        }
      })
      dispatch(closeModal())
      const { data } = await axios.get(url)
      dispatch(saveReprocessingListSuccess(data.data))
      dispatch(finishLoading())
      MySwal.fire({
        title: `Lista cargada correctamente 
        (${dayjs(data.data.reprocessingDate).format('DD/MM/YYYY')})`,
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      // eslint-disable-next-line
      dispatch(saveReprocessingFail())
      Swal.fire({
        title: 'Error',
        text: err?.response?.data?.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 2000
      })
    }
  }
}

export const startLoadingReprocessingListByDate = payload => {
  const { url, searchDateRange, client, destination, origin, reason } = payload
  const modifyString = str => {
    if (!str) ''
    if (str && !str.includes(' ')) str
    return str.replace(/ /g, '_')
  }
  const reformatDate = date => dayjs(date).format('YYYY-MM-DD')
  return async dispatch => {
    const [startDate, endDate] = searchDateRange
    const newUrl = `${url}?startDate=${reformatDate(startDate)}&endDate=${reformatDate(endDate)}&client=${modifyString(
      client
    )}&destination=${modifyString(destination)}&origin=${modifyString(origin)}&reason=${modifyString(reason)}`
    dispatch(startLoading())
    try {
      MySwal.fire({
        title: 'Cargando lista de reprocesos...',
        text: 'Espere por favor...',
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading()
        }
      })
      dispatch(closeModal())

      const { data } = await axios.get(newUrl)
      dispatch(saveReprocessingListSuccess(data.data))
      dispatch(finishLoading())
      MySwal.fire({
        title: `Lista de reprocesos cargada correctamente para fechas: ${dayjs(startDate).format(
          'DD/MM/YYYY'
        )} - ${dayjs(endDate).format('DD/MM/YYYY')}`,
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      })
    } catch (err) {
      // eslint-disable-next-line
      dispatch(saveReprocessingFail())
      dispatch(closeBackdrop())
      Swal.fire({
        title: 'Error',
        text: err?.response?.data?.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 2000
      })
    }
  }
}

export const startSaveReprocessing = payload => {
  const { urlPost, reprocessed } = payload
  return async dispatch => {
    dispatch(startLoading())
    try {
      Swal.fire({
        title: 'Guardando...',
        text: 'Espere por favor...',
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading()
        }
      })
      dispatch(closeModal())
      const { data } = await axios.post(urlPost, reprocessed)
      dispatch(saveReprocessingSuccess(data.data))
      dispatch(finishLoading())
      const status = data.message === 'updated' ? 'actualizado(s)' : 'creado(s)'
      const quantity = data?.data?.length ?? 0
      const oneOrMore = quantity === 1 ? 'Reproceso' : 'Reprocesos'
      const title = `${oneOrMore} ${status} (${data.data[0].reason.toLowerCase()}) el ${dayjs(
        data.data[0].reprocessingDate
      ).format('DD/MM/YYYY')}`
      MySwal.fire({
        title,
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      })
      dispatch(reprocessingClean())
      const url = process.env.REACT_APP_URL
      // const url_ = `${url}/reprocessings`
      const today = dayjs()
      const dateFormat = 'YYYY-MM-DD'
      const startDate = today.startOf('day').format(dateFormat)
      const endDate = today.endOf('day').format(dateFormat)
      const url_ = `${url}/reprocessings?startDate=${startDate}&endDate=${endDate}`
      dispatch(startLoadingReprocessingList({ url: url_ }))
      // dispatch(closeBackdrop())
    } catch (err) {
      // eslint-disable-next-line
      dispatch(saveReprocessingFail())
      Swal.fire({
        title: 'Error',
        text: err.response?.data?.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 2000
      })
    }
  }
}

export const currentReprocessingNotFound = () => {
  return {
    type: types.REPROCESSING_LOAD_CURRENT_NOT_FOUND
  }
}

export const setDateRange = payload => ({
  type: types.REPROCESSING_SEARCH_SET_DATE_RANGE,
  payload
})

export const startLoadingReprocessing = url => {
  return async dispatch => {
    dispatch(startLoading())
    dispatch(cleanReprocessingListAndHistory())
    await axios
      .get(url)
      .then(res => {
        if (res) {
          const reprocessing = res.data.data
          const histories = res.data.histories

          if (!Array.isArray(reprocessing)) {
            dispatch(currentReprocessingSuccess(reprocessing))
            dispatch(saveReprocessingHistoryListSuccess(histories))
          }
          if (Array.isArray(reprocessing) && reprocessing.length === 1) {
            dispatch(currentReprocessingSuccess(reprocessing))
            dispatch(saveReprocessingListSuccess(reprocessing))
            dispatch(saveReprocessingHistoryListSuccess(histories))
          } else if (Array.isArray(reprocessing) && reprocessing.length > 1) {
            // dispatch(currentReprocessingSuccess(reprocessing[0]))
            dispatch(saveReprocessingListSuccess(reprocessing))
          }
        }
      })
      .catch(() => {
        Swal.fire({
          title: 'Error al buscar el código',
          icon: 'error',
          text: 'El código no existe o no está registrado',
          showConfirmButton: false,
          timer: 2000
        })
        dispatch(reprocessingClean())
        dispatch(cleanReprocessingHistory())
        dispatch(cleanReprocessingList())
        dispatch(currentReprocessingNotFound())
      })
      .finally(() => dispatch(finishLoading()))
  }
}

export const reprocessingClean = () => ({
  type: types.REPROCESSING_CLEAN_CURRENT
})

export const reprocessingLogout = () => ({
  type: types.REPROCESSING_LOGOUT_CLEANING
})

export const cleanDateRange = () => ({
  type: types.REPROCESSING_SEARCH_CLEAN_DATE_RANGE
})
