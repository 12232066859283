import { Row, Col, Container, Button, Form, InputGroup} from 'react-bootstrap'
import IconButton from '@mui/material/IconButton'
import React, { useState, useEffect, useRef} from 'react'
import { utils, write } from 'xlsx'
import { DataGrid, esES } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import MuiAlert from '@mui/material/Alert'
import Tooltip from '@mui/material/Tooltip'
import axios from 'axios'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined';
import { saveAs } from 'file-saver'
import Swal from 'sweetalert2'
import { useForm } from '../../../hooks/useForm'
import Details from './MovementsExpeditionDetails'
import FormExpedition from '../components/Forms/FormExpedition'
import FilterExpedition from '../components/Forms/FilterExpedition'
import { FaRegEye, FaCloudDownloadAlt } from 'react-icons/fa'
import { BsExclamationTriangle, BsSearch, BsCalendar} from 'react-icons/bs'
// import { DateRangePicker } from 'rsuite';
import { isAfter } from 'date-fns'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Moment from 'moment';
import styles from '../styles/filterExpedition.module.css'

React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

// const theme = createTheme()
// responsiveFontSizes(theme)

const BASE_URL = process.env.REACT_APP_URL


function Expedition() {
    const [packageState, setPackageState] = useState('')
    const [values, handleInputChange] = useForm()
    const cols = [
        { 
            field: 'processingDate', 
            headerName: 'Fecha', 
            width: 100,
            flex: 0.3,
            headerAlign: 'center',
            renderCell: (params) => {
                var formatDate = Moment(params.row.processingDate).format('DD/MM/YYYY')
                return String(formatDate)
            }
        },
        {
            field: 'id',
            headerName: 'N° TIM',
            //width: 120,
            flex: 1,
            headerAlign: 'center',
        },
        { 
            field: 'movementType',
            headerName: 'Estado',
            width: 70,
            //flex: 1,
            sortable: false,
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.movementType === 'LI_OUTGOING' &&
                            <Tooltip title="En Transito a LI Gamero" placement="bottom">
                                <IconButton>
                                    <LocalShippingIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            params.row.movementType === 'LI_OUTGOING2' &&
                            <Tooltip title="Recepcionado en LI Gamero" placement="bottom">
                                <IconButton color="primary">
                                    <HomeIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            field: 'timorigin',
            headerName: 'Origen',
            width: 150,
            headerAlign: 'center'
            //flex: 1
        },
        // {
        //     field: 'timorigin',
        //     headerName: 'Destino',
        //     width: 150,
        //     //flex: 1
        // },
        {
            field: 'licenceplate',
            headerName: 'Patente',
            width: 80,
            headerAlign: 'center',
            //flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.licenseplate && 
                                param.length == 6 &&
                                    String(param)
                                    //String(param.slice(0, param.length-2), '-', param.slice(-2).join('') )
                        }
                    </>
                    )
            }
        },
        {
            field: 'stampnumber',
            headerName: 'N° Sello',
            width: 90
        },
        { 
            field: 'observations',
            headerName: 'Detalles',
            width: 75,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => {
                return (
                    <>
                        {
                            <Details
                                barCode={params.row.barcode}
                                date={params.row.processingDate}
                                pdfLink={params.row.pdfLink}
                                timId={params.row.id}
                                state={params.row.movementType}
                                stampnumber={params.row.stampnumber}
                                licenceplate={params.row.licenceplate}
                            />
                        }
                    </>
                )
            }
        },
        {
            field: 'pdfLink',
            headerName: 'TIM',
            width: 70,
            headerAlign: 'center',
            sortable: false,
            headerClassName: 'lastcolumnSeparator',
            renderCell: (params) => {
                return (
                    <>
                        { 
                            params.row.pdfLink ? 
                            <a href={params.row.pdfLink} download target="_blank" rel="noopener noreferrer">
                                <Tooltip title="Descargar PDF" placement="bottom">
                                    <IconButton aria-label="delete" color="primary">
                                        <FaCloudDownloadAlt />
                                    </IconButton>
                                </Tooltip>
                            </a>
                            : <Tooltip title="Sin PDF" placement="bottom">
                                <span>
                                    <IconButton aria-label="delete" disabled color="primary">
                                        <FaCloudDownloadAlt />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        }
                    </>
                )
            }
        }
    ]

    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        sort: false,
        toolbar: false,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10],
        selectableRows: 'none',
        height: '800px',
        // onCellClick: async (colData, cellMeta) => {
        //     if (cellMeta.colIndex === 7) {
        //         // const id = movements.find(el => el.barcode === colData).id
        //         const id = movements.find(el => el.barcode === colData).barcode
        //         const response = await axios.get(BASE_URL + '/li/movement/' + id)
        //         await setReverseLogistic(response.data.data)
        //     }
        // },
        onDownload: (columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'

            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = 'pistoleados'
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)

            return false
        },
        textLabels: {
            toolbar: {
                downloadCsv: 'Descargar XLSX',
                search: 'Buscar'
            },
            pagination: {
                next: 'Página Siguiente',
                previous: 'Página Anterior',
                rowsPerPage: 'Filas por página:',
                displayRows: 'de'
            },
            body: {
                noMatch: <div className='justify-content-center align-items-center' style={{fontSize: '20px'}}><BsExclamationTriangle style={{color: 'red'}}/><span> Aún no hay datos para mostrar</span></div>
            }
        }
    }
    
    const [movements, setMovements] = useState([])
    const changeState = async (event) => {
        await setPackageState(event.target.value)
        handleInputChange(event)
    }

    const searchByDate = async () => {
        setMovements([])
        Swal.fire({
            title: 'Cargando Expediciones...',
            text: 'Espere por favor...',
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            }
        })
        const { data } = await axios.post(BASE_URL + '/tim/movements', values)
        await setMovements(data.data)
        if (data.data.length === 0) {
            Swal.fire(
                'No se encontraron datos',
                ' ',
                'error' 
            )
        } else {
            Swal.fire({
                title: 'Lista Expedición cargada correctamente',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    const Calendar = {
        sunday: 'Do',
        monday: 'Lu',
        tuesday: 'Ma',
        wednesday: 'Mi',
        thursday: 'Ju',
        friday: 'Vi',
        saturday: 'Sa',
        ok: 'OK',
        today: 'Hoy',
        yesterday: 'Ayer',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
        formattedMonthPattern: 'MMM yyyy',
        formattedDayPattern: 'dd MMM yyyy',
        last7Days: 'Últimos 7 Días'
      };

    return (
        <>
            {/* <Row className='pt-4'>
                <h3 className='spread-font text-dark text-center'>Expedición</h3>
            </Row> */}
            <Row className='w-100'>
                <FormExpedition />
            </Row>
            <Row className='w-100 mx-auto'>
                <Col xs={12} md={4} lg={4} xl={3}>
                    <Container fluid className={styles.DivContainer}>
                        <Form.Group>
                            <div className='border-bottom w-100'>
                                <h3 className={styles.title}>Filtros</h3>
                            </div>
                            <div className={`${styles.FormContainer}`}>
                                <Form.Control 
                                    className={styles.InputPrincipal} 
                                    id='timNumber' 
                                    name='timnumber' 
                                    onChange={handleInputChange}
                                    type='text' 
                                    placeholder='N° TIM' 
                                    autoFocus
                                />
                         
                            {/* <div>
                                <Form.Label className={styles.SegTitle}>Seleccionar Rango de Fecha</Form.Label>
                            </div> */}
                       
                                {/* <BsCalendar className={styles.iconCalendario}/>
                                <div className={styles.DivInputFecha}> */}
                                {/* <DateRangePicker
                                    calendars={1}
                                    value={dateRange}
                                    onChange={(newValue) => {
                                        setDateRange(newValue);
                                    }}
                                    renderInput={(startProps, endProps) => (
                                        <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} />
                                        </React.Fragment>
                                    )}
                                /> */}
                                {/* <DateRangePicker 
                                    placeholder="Seleccionar Rango de Fecha"
                                    isoWeek
                                    locale={Calendar}
                                    format='dd-MM-yyyy'
                                    showOneCalendar
                                    placement="auto"
                                    editable={false}
                                    size="lg"
                                    style={{ width: 315, marginTop: 10, marginBottom: 10 }}
                                    disabledDate={date => isAfter(date, new Date())}
                                    /> */}
                                {/* <InputGroup className={styles.DivInputFecha}>
                                    <InputGroup.Text><BsCalendar/></InputGroup.Text>
                                    <Form.Control id='from' type='date' name='startDate' onChange={handleInputChange} className={styles.InputPrincipalFecha} placeholder='Fecha Inicio'/>
                                </InputGroup> */}
                                <InputGroup className={`my-2 ${styles.DivInputFecha}`}>
                                    <InputGroup.Text><BsCalendar/></InputGroup.Text>
                                    <Form.Control id='to' type='date' name='endDate' onChange={handleInputChange} className={styles.InputPrincipalFecha} placeholder='Fecha Termino'/>
                                </InputGroup>
                                {/* </div> */}
                                <Form.Select aria-label="Estado" name='movementType' id='movementType' onChange={changeState} className={styles.InputPrincipal} value={packageState}>
                                    <option value={'LI_OUTGOING'}>En Tránsito</option>
                                    <option value={'LI'}>En Bodega</option>
                                </Form.Select>
                                <Form.Control id='movementType' name='movementType' onChange={handleInputChange} type='text' className={styles.InputPrincipal} placeholder='Estado'/>
                                <Form.Control id='origin' name='origin' onChange={handleInputChange} type='text' className={styles.InputPrincipal} placeholder='Origen'/>
                                <Form.Control id='timNumber' name='destiny' onChange={handleInputChange} type='text' className={styles.InputPrincipal} placeholder='Destino'/>
                                <Form.Control id='licenceplate' name='licenceplate' onChange={handleInputChange} type='text' className={styles.InputPrincipal} placeholder='Patente'/>
                                <Form.Control id='stampnumber' name='stampnumber' onChange={handleInputChange} type='text' className={styles.InputPrincipal} placeholder='N° Sello'/>
                            </div>
                            <div className='logout pb-3'>
                                <div className='d-grid gap-2'>
                                    <Button type='submit' size="lg" onClick={searchByDate} className={`${styles.BtnBuscar} btn btn-lg`}>
                                        <BsSearch className={styles.iconBuscar}/>
                                        <span className={styles.TxtBtnBuscar}>BUSCAR</span>
                                    </Button>
                                </div>
                            </div>
                        </Form.Group>
                    </Container>
                </Col>
                <Col xs={12} md={8} lg={8} xl={9}>
                    <div style={{ 
                        fontFamily: 'Nunito',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        border: '1px solid rgba(255, 255, 255, 0.418)',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
                        borderRadius: '10px',
                        height: 'calc(100vh - 156px)',
                        flexGrow: 1
                        }}>
                        {/* <DataTable 
                            columns={columns}
                            data={movements}
                            selectableRows
                            pagination
                            fixedHeader
                            noDataComponent=<div className='justify-content-center align-items-center' style={{fontSize: '20px'}}><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><BsExclamationTriangle style={{color: 'red'}}/><span> Aún no hay datos para mostrar</span></div>
                        /> */}
                        <DataGrid
                            disableColumnMenu
                            getRowId={(row) => row.barcode}
                            rows={movements}
                            columns={cols}
                            pageSize={25}
                            options={options}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            sx={{
                                border: 0
                            }}
                        />
                    </div>

                </Col>
            </Row>
        </>
    )
}

export default Expedition