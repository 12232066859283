import { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { Tooltip, Chip, alertTitleClasses } from '@mui/material'
import { Modal, Col, Row, Button, Form, Card } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useDispatch, useSelector } from 'react-redux'
import { mockSelects } from '../../mock/mockSelects'
import { DataGrid, esES } from '@mui/x-data-grid'
import Moment from 'moment'
import axios from 'axios'

//import DivForSelects from '../Custom/DivForSelects'
import CommonSelect from '../MUIBased/CommonSelect'
import { renderSelectMenuItems } from '../../utils/renderItems'
import {
	cleanOrderNumbers,
	reprocessingClean,
	startSaveReprocessing,
	cleanReprocessingHistory,
	cleanReprocessingList
} from '../../../../actions/reprocessing'
import { useForm } from '../../../../hooks/useForm'
import { closeModal } from '../../../../actions/ui'
import * as _ from 'lodash'
import is from '@validatecl/type'

//import MUIDataTable from 'mui-datatables'
import { saveAs } from 'file-saver'
import { write, utils, read } from 'xlsx'

const defaultFormValues = {
	flow: '',
	region: '',
	client: '',
	origin: '',
	destination: '',
	reason: '',
	observation: '',
	parisOrderNo: ''
}
const url = process.env.REACT_APP_URL

const titleFormatCol = (title) => <Tooltip title={title} placement="bottom"><strong>{title}</strong></Tooltip>
const cols = [
	{
		field: 'orderNo',
		renderHeader: () => (titleFormatCol('Item')),
		flex: 0.3,
	},
	{
		field: 'region',
		renderHeader: () => (titleFormatCol('Región')),
		flex: 0.25,
	},
	{
		field: 'client',
		renderHeader: () => (titleFormatCol('Cliente')),
		flex: 0.3,
	},
	{
		field: 'flow',
		renderHeader: () => (titleFormatCol('Flujo')),
		flex: 0.25,
	},
	{
		field: 'origin',
		renderHeader: () => (titleFormatCol('Origen')),
		flex: 0.6
	},
	{
		field: 'reason',
		renderHeader: () => (titleFormatCol('Motivo')),
		flex: 0.6
	},
	{
		field: 'destination',
		renderHeader: () => (titleFormatCol('Destino')),
		flex: 0.6
	},
	{
		field: 'reprocessingDate',
		renderHeader: () => (titleFormatCol('Fecha')),
		flex: 0.4,
		renderCell: (params) => Moment(params.row.reprocessingDate).format('DD/MM/YYYY')

	},
	{
		field: 'responsibleName',
		renderHeader: () => (titleFormatCol('Responsable')),
		flex: 0.5,
		headerClassName: 'lastcolumnSeparator',
	}
]

const ModalForSave = ({ handleCodeChange, reprocessData, guidesTotal }) => {
	const today = dayjs() //  .format('DD-MM-YYYY')
	const guidesT = guidesTotal
	const dispatch = useDispatch()
	const { modalIsOpen } = useSelector(state => state.ui)
	// eslint-disable-next-line
	const {
		current: activeReprocessing,
		reprocessingHistoryList,
		reprocessingList,
		orderNumbers
	} = useSelector(state => state.reprocessing)
	const { user: currentUser } = useSelector(state => state.auth)
	
	// eslint-disable-next-line
	const [formValues, handleInputChange, reset] = useForm({
		...defaultFormValues,
		...(activeReprocessing &&
			activeReprocessing?.length === 1 && {
				..._.omit(activeReprocessing[0], ['destination', 'reason', 'origin'])
			})
		})
		const MySwal = withReactContent(Swal)
		
		const handleClose = () => {
			dispatch(closeModal())
			reset(defaultFormValues)
			dispatch(cleanReprocessingHistory())
			dispatch(cleanReprocessingList())
			dispatch(reprocessingClean())
		}
		const [flowSelect] = useState(mockSelects.flow)
		const [regionSelect] = useState(mockSelects.region)
		const [clientSelect] = useState(mockSelects.clients)
		const [originSelect] = useState(mockSelects.origins)
		const [destinationSelect] = useState(mockSelects.destinations)
		const [reasonSelect] = useState(mockSelects.reasons)

		const getTrolleyLabel = reprocessing => {
			if (formValues && 'client' in formValues && 'destination' in formValues) {
				const { client } = formValues
				const getWord =
				formValues.destination === 'LOGISTICA INVERSA'
					? 'LI'
					: formValues.destination === 'MERMA'
						? 'MERMA'
						: formValues.destination === 'SIN INFORMACION'
							? 'SIN INFORMACION'
							: `RP ${client.toUpperCase()}`
			return `${getWord}`
		} else {
			return 'CARRO: ----'
		}
	}

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
		height: '95%',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		overflow: 'scroll',
		overflowY: 'scroll'
	}

	const handleSubmit = () => {
		// eslint-disable-next-line
		if ([formValues.flow, formValues.region, formValues.client, formValues.origin, formValues.destination, formValues.reason].some(is.empty)) {
			dispatch(closeModal())
			return MySwal.fire({
				title: 'Se debe seleccionar una Región, Flujo, Cliente, Origen, Destino y Motivo',
				icon: 'warning',
				showConfirmButton: false,
				timer: 2500,
				timerProgressBar: true
			})
		}

		const orderNo = Array.isArray(orderNumbers) ? String(orderNumbers[0]) : String(orderNumbers)
		// continue
		const reprocessed = {
			reprocessingDate: new Date(),
			flow: formValues.flow ?? '',
			region: formValues.region ?? '',
			client: formValues.client ?? '',
			orderNo,
			parisOrderNo: formValues.parisOrderNo ?? '',
			origin: formValues.origin ?? '',
			reason: formValues.reason ?? '',
			destination: formValues.destination,
			observations: formValues.observations ?? '',
			responsibleEmail: currentUser.email ?? '',
			trolley: getTrolleyLabel(activeReprocessing) ?? '',
			responsibleName: currentUser.displayName ?? ''
		}
		const reprocessingToSave = orderNumbers.map(orderNo => ({
			...reprocessed,
			orderNo
		}))
		const urlPost = `${url}/reprocessings`
		dispatch(startSaveReprocessing({ reprocessed: reprocessingToSave, urlPost }))
		handleCodeChange({ target: { name: 'code', value: '' } })
		dispatch(cleanOrderNumbers())
		reset(defaultFormValues)
	}

	const handleObservationKeyChange = event => {
		if (event.key === 'Enter') {
			handleSubmit()
		}
	}

	const handleIdBoxKeyChange = event => {
		if (event.key === 'Enter') {
			handleSubmit()
			/* event.target.value = '' */
		}
	}

	const shouldBeShowed = formValues && formValues?.client.toLowerCase() === 'paris'

	useEffect(() => {
		if (activeReprocessing) {
			const reprocessingPicked = _.pick(activeReprocessing, 'client', 'orderNo', 'parisOrderNo')
			reset({ ...formValues, ...reprocessingPicked })
		}
	}, [activeReprocessing])

	useEffect(() => {
		return () => {
			reset(defaultFormValues)
		}
	}, [dispatch])

	useEffect(() => {
		if (orderNumbers && orderNumbers.length > 1) {
			//setTableData(reprocessingList)
		} else if (orderNumbers && orderNumbers.length === 1) {
			//setTableData(reprocessingHistoryList)
		}
	}, [orderNumbers])

	return (
		<>
			<Modal
				backdrop="static"
				show={modalIsOpen ?? false}
				onHide={handleClose}
				centered
				keyboard={false}
				dialogClassName='modal-dialog-custom'
				contentClassName="modal-content-custom"
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						<Tooltip
							title={
								reprocessData
								// .map((row, i) => {
								// 	if (i > 0) {
								// 		return row.orderNo + ',' + row.orderNo
								// 	} else {
								// 		return row.orderNo
								// 	}
								// })
							}
							placement="bottom"
						>
							<Chip label={`Códigos`} className='spread-font px-1 mx-1' style={{ 'background': '#F7F7F7', 'color': '#787777', 'borderRadius:': '10px' }} />
						</Tooltip>
						<Chip label={`N° Items: ${guidesT.length}`} className='spread-font px-1 mx-1' style={{ 'background': '#F7F7F7', 'color': '#787777', 'borderRadius:': '10px' }} />
						<Chip label={`Carro: ${getTrolleyLabel(activeReprocessing)}`} className='spread-font px-1 mx-1' style={{ 'background': '#F7F7F7', 'color': '#787777', 'borderRadius:': '10px' }} />
						{/* <Chip label={`Fecha: ${dayjs(reprocessing?.reprocessingDate ?? today).locale('es').format('DD-MM-YYYY')}`} className='spread-font px-1 mx-1' style={{ 'background': '#F7F7F7', 'color': '#787777', 'borderRadius:': '10px' }} /> */}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className='gx-1 spread-font'>
						<Col>
							{/* <Typeahead
								id='regionselector'
								labelKey='name'
								options={regionSelect}
								selected={String(formValues?.region ?? '')}
								placeholder="Región"
								highlightOnlyResult
								renderMenuItemChildren={(e) => e.name}
								className='input-custom'
								onChange={handleInputChange}
							/> */}
							<CommonSelect
								labelId="demo-simple-select-autowidth-label"
								id="demo-simple-select-autowidth"
								value={formValues?.region ?? ''}
								name="region"
								onChange={handleInputChange}
								autoWidth
								displayEmpty
								autoFocus={false}>
								{renderSelectMenuItems(regionSelect)}
							</CommonSelect>
						</Col>
						<Col>
							{/* <Typeahead
								id='flujoselector'
								labelKey='name'
								options={flowSelect}
								selected={String(formValues?.flow ?? '')}
								placeholder='Flujo'
								highlightOnlyResult
								renderMenuItemChildren={(e) => e.name}
								className='input-custom'
								onChange={handleInputChange}
							/> */}
							<CommonSelect
								labelId="demo-simple-select-autowidth-label"
								id="demo-simple-select-autowidth"
								value={formValues?.flow ?? ''}
								name="flow"
								onChange={handleInputChange}
								autoWidth
								displayEmpty
								autoFocus={false}>
								{renderSelectMenuItems(flowSelect)}
							</CommonSelect>
						</Col>
						<Col>
							<CommonSelect
								labelId="demo-simple-select-autowidth-label"
								id="demo-simple-select-autowidth"
								value={formValues?.client ?? ''}
								name="client"
								onChange={handleInputChange}
								autoWidth
								displayEmpty
								autoFocus={false}>
								{renderSelectMenuItems(clientSelect)}
							</CommonSelect>
							{/* <Typeahead
								id='clientselector'
								labelKey='name'
								options={clientSelect}
								selected={String(formValues?.client ?? '')}
								placeholder='Cliente'
								highlightOnlyResult
								renderMenuItemChildren={(e) => e.name}
								className='input-custom'
								onChange={handleInputChange}
							/> */}
						</Col>
						<Col>
							<CommonSelect
								labelId="demo-simple-select-autowidth-label"
								id="demo-simple-select-autowidth"
								value={formValues?.origin ?? ''}
								name="origin"
								onChange={handleInputChange}
								autoWidth
								displayEmpty
								autoFocus={false}>
								{renderSelectMenuItems(originSelect)}
							</CommonSelect>
							{/* <Typeahead
								id='originselector'
								labelKey='name'
								options={originSelect}
								selected={String(formValues?.origin ?? '')}
								placeholder='Origen'
								highlightOnlyResult
								renderMenuItemChildren={(e) => e.name}
								className='input-custom'
								onChange={handleInputChange}
							/> */}
						</Col>
						<Col>
							<CommonSelect
								labelId="demo-simple-select-autowidth-label"
								id="demo-simple-select-autowidth"
								value={formValues?.reason ?? ''}
								name="reason"
								onChange={handleInputChange}
								autoWidth
								displayEmpty
								autoFocus={false}>
								{renderSelectMenuItems(reasonSelect)}
							</CommonSelect>
							{/* <Typeahead
								id='reasonselector'
								labelKey='name'
								options={reasonSelect}
								selected={String(formValues?.reason ?? '')}
								placeholder='Motivo'
								highlightOnlyResult
								renderMenuItemChildren={(e) => e.name}
								className='input-custom'
								onChange={handleInputChange}
							/> */}
						</Col>
						<Col>
							<CommonSelect
								labelId="demo-simple-select-autowidth-label"
								id="demo-simple-select-autowidth"
								value={formValues?.destination ?? ''}
								name="destination"
								onChange={handleInputChange}
								autoWidth
								displayEmpty
								autoFocus={false}>
								{renderSelectMenuItems(destinationSelect)}
							</CommonSelect>
							{/* <Typeahead
								id='destinyselector'
								labelKey='name'
								options={destinationSelect}
								selected={String(formValues?.destination ?? '')}
								placeholder='Destino'
								highlightOnlyResult
								renderMenuItemChildren={(e) => e.name}
								className='input-custom'
								onChange={handleInputChange}
							/> */}
						</Col>
					</Row>
					<Row className='gx-1'>
						<Col >
							<Form.Control
								type="text" 
								className='input-custom-observation' 
								placeholder="Observaciones" 
								name="observations"
								value={formValues?.observations ?? ''}
								onChange={handleInputChange}
							/>
						</Col>
						<Col xl={2} className='d-grid gap-2'>
							<Button
								type='file'
								style={{ 'background': '#009B78', 'color': '#fff', 'marginTop': '10px', 'height': '50px', 'borderRadius': '10px' }}
								onClick={handleSubmit}
							>
								Confirmar
							</Button>

						</Col>
					</Row>
					<Row className='mt-4'>
						<Col>
							<Card className='d-flex border text-center spread-font' style={{ 'borderRadius': '10px' }}>
								<Card.Body className='align-items-center d-flex justify-content-center' style={{ 'height': 'calc(100vh - 450px)' }}>
									<DataGrid
										disableSelectionOnClick
										disableColumnMenu
										getRowId={(row) => row.id}
										rows={reprocessData}
										columns={cols}
										//pageSize={25}
										localeText={esES.components.MuiDataGrid.defaultProps.localeText}
										sx={{
											border: 0
										}}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>













			{/* <div style={{ position: 'absolute', overflow: 'scroll', overflowY: 'scroll' }}>
				<Modal
					style={{
						position: 'absolute',
						height: '90%',
						maxHeight: '80% !important',
						overflow: 'scroll',
						overflowY: 'scroll'
					}}
					open={modalIsOpen ?? false}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description">
					<Box sx={style}>
						<Container style={{ width: '100%', height: 'auto', overflow: 'scroll', overflowY: 'scroll' }}>
							<Button style={{ position: 'absolute', right: 0, top: 0 }}>
								<CloseIcon onClick={handleClose} color="secondary" />
								<span onClick={handleClose}>Cancelar</span>
							</Button>
							<Row
								style={{
									marginBottom: '16px',
									marginTop: '16px !important',
									marginLeft: 'auto',
									overflow: 'scroll',
									overflowY: 'scroll'
								}}>
								<Stack direction="row" spacing={4} justifyContent="unset">
									{orderNumbers &&
										orderNumbers?.map((orderNumber, index) => {
											// find in reprocessingList the orderNumber
											const reprocessing =
												reprocessingList && reprocessingList.find(reprocessing => reprocessing?.orderNo === orderNumber)
											const counter = reprocessing?.checkoutCounter ?? 0
											return (
												<Chip
													key={`${orderNumber}_${index + 1}`}
													label={`${orderNumber} (${counter} ${counter === 0 || counter > 1 ? 'reprocesos' : 'reproceso'
														})`}
													variant="outlined"
												/>
											)
										})}
									<Chip
										sx={{
											marginLeft: 'auto !important'
										}}
										label={`CARRO: ${getTrolleyLabel(activeReprocessing)}`}
										variant="outlined"
									/>
								</Stack>
							</Row>
							<Row
								style={{
									display: 'flex',
									justifyContent: 'center',
									backgroundColor: 'white !important'
								}}>
								<Col xs={10}>
									<Row center="xs" style={{ marginTop: '2%' }}>
										<DivForSelects>
											<FormControl sx={{ width: '15%', maxWidth: '23%' }}>
												<CommonSelect
													labelId="demo-simple-select-autowidth-label"
													id="demo-simple-select-autowidth"
													value={formValues?.region ?? ''}
													name="region"
													onChange={handleInputChange}
													autoWidth
													displayEmpty
													autoFocus={false}>
													{renderSelectMenuItems(regionSelect)}
												</CommonSelect>
												<FormHelperText> Región </FormHelperText>
											</FormControl>
											<FormControl sx={{ width: '15%', maxWidth: '23%' }}>
												<CommonSelect
													labelId="demo-simple-select-autowidth-label"
													id="demo-simple-select-autowidth"
													value={formValues?.flow ?? ''}
													name="flow"
													onChange={handleInputChange}
													autoWidth
													displayEmpty
													autoFocus={false}>
													{renderSelectMenuItems(flowSelect)}
												</CommonSelect>
												<FormHelperText> Flujo </FormHelperText>
											</FormControl>
											<FormControl sx={{ width: '15%', maxWidth: '23%' }}>
												<CommonSelect
													labelId="demo-simple-select-autowidth-label"
													id="demo-simple-select-autowidth"
													value={formValues?.client ?? ''}
													name="client"
													onChange={handleInputChange}
													autoWidth
													displayEmpty
													autoFocus={false}>
													{renderSelectMenuItems(clientSelects)}
												</CommonSelect>
												<FormHelperText> Cliente </FormHelperText>
											</FormControl>
											<FormControl sx={{ m: 1, width: '15%', maxWidth: '23%' }}>
												<CommonSelect
													labelId="demo-simple-select-autowidth-label"
													id="origin-select"
													name="origin"
													value={formValues?.origin ?? ''}
													onChange={handleInputChange}
													autoWidth
													displayEmpty
													autoFocus={false}>
													{renderSelectMenuItems(originSelects)}
												</CommonSelect>
												<FormHelperText> Origen </FormHelperText>
											</FormControl>
											<FormControl sx={{ m: 1, width: '25%', maxWidth: '23%' }}>
												<CommonSelect
													labelId="demo-simple-select-autowidth-label"
													id="demo-simple-select-autowidth"
													value={formValues?.reason ?? ''}
													name="reason"
													onChange={handleInputChange}
													autoWidth
													displayEmpty
													autoFocus={false}>
													{renderSelectMenuItems(reasonSelects)}
												</CommonSelect>
												<FormHelperText> Motivo </FormHelperText>
											</FormControl>
											<FormControl sx={{ m: 1, width: '25%', maxWidth: '23%' }}>
												<CommonSelect
													labelId="demo-simple-select-autowidth-label"
													id="demo-simple-select-autowidth"
													value={formValues?.destination ?? ''}
													name="destination"
													onChange={handleInputChange}
													autoWidth
													displayEmpty
													autoFocus={false}>
													{renderSelectMenuItems(destinationSelects)}
												</CommonSelect>
												<FormHelperText> Destino </FormHelperText>
											</FormControl>
										</DivForSelects>
									</Row>
									<Row
										style={{
											marginTop: '2%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'row',
											width: '100%'
										}}>
										<Box sx={{ maxWidth: '75%' }}>
											{shouldBeShowed && (
												<TextField
													label="Guía Paris"
													type="text"
													letiant="outlined"
													sx={{
														width: '100%',
														my: 2
													}}
													name="parisOrderNo"
													value={formValues?.parisOrderNo ?? ''}
													onChange={handleInputChange}
													onKeyPress={handleIdBoxKeyChange}
												/>
											)}
											<TextField
												label="Observaciones"
												type="text"
												name="observations"
												value={formValues?.observations ?? ''}
												onChange={handleInputChange}
												letiant="outlined"
												sx={{
													width: '100%',
													my: 2
												}}
												onKeyPress={handleObservationKeyChange}
											/>
										</Box>

										<Button onClick={handleSubmit}>
											{activeReprocessing ? 'Actualizar Reproceso' : 'Crear Reproceso'}
										</Button>

										<hr />
										<MUIDataTable
											style={{
												marginTop: '1rem !important',
												width: '90%',
												height: '50%',
												maxHeight: '50% !important'
											}}
											pb={3}
											data={tableData}
											columns={headers_}
											options={options}
										/>
									</Row>
								</Col>
							</Row>
						</Container>
					</Box>
				</Modal>
			</div> */}
		</>
	)
}

ModalForSave.propTypes = {
	handleCodeChange: propTypes.func.isRequired
}

export default ModalForSave
