import { Redirect, Route, Switch } from 'react-router-dom'

import { Login } from '../components/Login'
import React from 'react'

export const AuthRouter = () => {
  return (
    
    <div className="auth__main">
      <div className="auth__box-container">
        <Switch>
          <Route exact path="/auth/login" component={Login} />
          <Redirect to="/auth/login" />
        </Switch>
      </div>
    </div>
    
  )
}