import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import botonGoogle from './assets/boton-google.svg'
import titleLogo from './assets/title.svg'
import Alerta from '../Alertas/GreenAlert'
import '../Alertas/modal.css'
import './styles/cardlogin.css'

import { startGoogleLogin } from '../../actions/auth'
import { useDispatch } from 'react-redux'

export const CardLogin = () => {
  const appName = process.env.REACT_APP_NAME ?? 'Aplicación Genérica'
  const dispatch = useDispatch()
  const history = useHistory()

  // modal
  const [open, setOpen] = useState(false)

  const handleModal = valor => setOpen(valor)

  const handleGoogleLogin = () => {
    const lastPath = localStorage.getItem('lastPath') || '/'
    dispatch(startGoogleLogin())
    history.push(lastPath)
  }

  return (
    <div className="">
      {/* <Header2></Header2> */}
      <div className="content-login">
        <div className="card-container ">
          <div className="container-login vertical-center">
            <div >
              <div className="bienvenido-text text-center">
                <p className="text-header text-center">Iniciar sesión en Spread</p>
              </div>
              <p className="text-middle">Inicie sesión con su cuenta para continuar</p>
              <div>
                <img
                  onClick={handleGoogleLogin}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    marginTop: '50px'
                  }}
                  src={botonGoogle}
                  alt="google"
                />
              </div>
            </div>
          </div>
          {/* <div className="triangulo"></div> */}
        </div>
      </div>
      <Alerta mostrar={open} getOpen={handleModal} />
    </div>
  )
}

const Header2 = () => {
  const style = {
    with: '100%',
    height: '100px',
    backgroundColor: '#ADFFEC',
    position: 'fixed',
    width: '100%',
    top: '0',
    zIndex: '2'
  }

  const layerOne = {
    with: '100%',
    height: '90px',
    backgroundColor: '#00CC9C'
  }
  const layerTwo = {
    with: '100%',
    height: '80px',
    backgroundColor: '#009B78',
    color: 'white'
  }

  const contentTitleSpread = {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    justifyContent: 'space-between',
    paddingRight: '10px',
    height: '100%'
  }
  // const dispatch = useDispatch();
  // function cerrarSesion() {
  //     dispatch( startLogout() );
  // }
  return (
    <div style={style}>
      <div style={layerOne}>
        <div style={layerTwo}>
          <div style={contentTitleSpread}>
            <h2> </h2>
            <img alt="img-title" style={{ height: '40px' }} src={titleLogo}></img>
            {/* <Button style={{backgroundColor: 'red', width: '10%'}} type='button' onClick={ cerrarSesion }>Cerrar Sesión</Button> */}
            <p></p>
          </div>
        </div>
      </div>
    </div>
  )
}
