import { Row, Col } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { utils, write } from 'xlsx'
import MUIDataTable from 'mui-datatables'
import MuiAlert from '@mui/material/Alert'
import Tooltip from '@mui/material/Tooltip'
import axios from 'axios'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined';
import { saveAs } from 'file-saver'
import Swal from 'sweetalert2'
import { useForm } from '../../../hooks/useForm'
import Details from './MovementsExpeditionDetails'
//import DataTable from 'react-data-table-component'
import { BsExclamationTriangle, BsSearch, BsCalendar} from 'react-icons/bs'
import { FaRegEye, FaCloudDownloadAlt } from 'react-icons/fa'
import FormReception from '../components/Forms/FormReception'
import FilterExpedition from '../components/Forms/FilterExpedition'

React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const theme = createTheme()
responsiveFontSizes(theme)

const BASE_URL = process.env.REACT_APP_URL

function Reception() {
    const [packageState, setPackageState] = useState('');
    const [values, handleInputChange, reset] = useForm();
    const noData = <div className='justify-content-center align-items-center' style={{fontSize: '20px'}}><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><BsExclamationTriangle style={{color: 'red'}}/><span> Aún no hay datos para mostrar</span></div>
    const data = [
        {
            n_tim: 131233213,
            fecha: '10/07/2022',
            origen: 'Sorting Regular',
            destino: 'Logistica Inversa',
            guias_totales: 10
        },
        {
            n_tim: 131233213,
            fecha: '10/07/2022',
            origen: 'Sorting Regular',
            destino: 'Logistica Inversa',
            guias_totales: 10
        },
        {
            n_tim: 131233213,
            fecha: '10/07/2022',
            origen: 'Sorting Regular',
            destino: 'Logistica Inversa',
            guias_totales: 10
        },
        {
            n_tim: 131233213,
            fecha: '10/07/2022',
            origen: 'Sorting Regular',
            destino: 'Logistica Inversa',
            guias_totales: 10
        },
        {
            n_tim: 131233213,
            fecha: '10/07/2022',
            origen: 'Sorting Regular',
            destino: 'Logistica Inversa',
            guias_totales: 10
        },
        {
            n_tim: 131233213,
            fecha: '10/07/2022',
            origen: 'Sorting Regular',
            destino: 'Logistica Inversa',
            guias_totales: 10
        }
    ]
    const columns = [
        {
            name: 'N° TIM',
            selector: row => row.n_tim,
            sortable: true
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true
        },
        {
            name: 'Origen',
            selector: row => row.origen,
            sortable: true
        },
        {
            name: 'Destino',
            selector: row => row.destino,
            sortable: true
        },
        {
            name: 'Detalles',
            sortable: false,
            selector: "null",
            cell: (d) => [
                <FaRegEye style={{ fontSize: '23px', color: '#787777', cursor: 'pointer' }} onClick={() => setDetailModalShow(true)}/>
            ]
        },
        {
            name: 'Guías totales',
            selector: row => row.guias_totales,
            sortable: true
        }
    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const headers_ = [
        {
            name: 'processingDate',
            label: 'Fecha',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                }),
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {String(movements[dataIndex].processingDate.substring(0, 10).replaceAll("-", "/"))}
                        </>
                    )
                }
            },
        },
        {
            name: 'id',
            label: 'N° TIM',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                })
            }
        },
        {
            name: 'movementType',
            label: 'Estado',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                }),
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {
                                movements[dataIndex].movementType === 'LI_OUTGOING' &&
                                <Tooltip title="En Transito a LI Gamero" placement="bottom">
                                    <IconButton>
                                        <LocalShippingIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                movements[dataIndex].movementType === 'LI_OUTGOING2' &&
                                <Tooltip title="Recepcionado en LI Gamero" placement="bottom">
                                    <IconButton color="primary">
                                        <HomeIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </>
                    )
                }
            }
        },
        {
            name: 'timorigin',
            label: 'Origen',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                })
            }
        },
        {
            name: 'timorigin',
            label: 'Destino',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                })
            }
        },
        {
            name: 'licenceplate',
            label: 'Patente',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                })
            }
        },
        {
            name: 'stampnumber',
            label: 'N° Sello',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                })
            }
        },
        {
            name: 'observations',
            label: 'Detalles',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                }),
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {
                                <Details
                                    barCode={movements[dataIndex].barcode}
                                    date={movements[dataIndex].processingDate}
                                    pdfLink={movements[dataIndex].pdfLink}
                                    timId={movements[dataIndex].id}
                                    state={movements[dataIndex].movementType}
                                    stampnumber={movements[dataIndex].stampnumber}
                                    licenceplate={movements[dataIndex].licenceplate}
                                />
                            }
                        </>
                    )
                }
            }
        },
        {
            name: 'pdfLink',
            label: 'TIM',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Nunito', fontSize: 15, cursor: 'pointer' } }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center', fontWeight: 600, fontSize: 15, fontFamily: 'Nunito' }
                }),
                customBodyRenderLite: (dataIndex) => {
                    // return (
                    //     <>
                    //         {
                    //             movements[dataIndex].pdfLink &&
                    //             <a href={movements[dataIndex].pdfLink} download target="_blank" rel="noopener noreferrer">
                    //                 <Tooltip title="Descargar PDF" placement="bottom">
                    //                     <IconButton aria-label="delete" color="primary">
                    //                         <CloudDownloadIcon />
                    //                     </IconButton>
                    //                 </Tooltip>
                    //             </a>
                    //         }
                    //         {
                    
                    //             <Tooltip title="Sin PDF" placement="bottom">
                    //                 <span>
                    //                     <IconButton aria-label="delete" disabled color="primary">
                    //                         <CloudDownloadIcon />
                    //                     </IconButton>
                    //                 </span>
                    //             </Tooltip>
                    //         }
                    //     </>
                    // )
                }
            }
        }
    ]

    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        sort: false,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10],
        selectableRows: 'none',
        // onCellClick: async (colData, cellMeta) => {
        //     if (cellMeta.colIndex === 7) {
        //         // const id = movements.find(el => el.barcode === colData).id
        //         const id = movements.find(el => el.barcode === colData).barcode
        //         const response = await axios.get(BASE_URL + '/li/movement/' + id)
        //         await setReverseLogistic(response.data.data)
        //     }
        // },
        onDownload: (columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'

            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = 'pistoleados'
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)

            return false
        },
        textLabels: {
            toolbar: {
                downloadCsv: 'Descargar XLSX',
                search: 'Buscar'
            },
            pagination: {
                next: 'Página Siguiente',
                previous: 'Página Anterior',
                rowsPerPage: 'Filas por página:',
                displayRows: 'de'
            },
            body: {
                noMatch: 'Aún no hay datos para mostrar'
            }
        }
    }

    const [reverseLogistic, setReverseLogistic] = useState([])
    const [movements, setMovements] = useState([])
    const [date, setDate] = useState([])

    const changeState = async (event) => {
        await setPackageState(event.target.value)
        handleInputChange(event)
    }

    const searchByDate = async () => {
        Swal.fire({
            title: 'Cargando lista de seguimientos...',
            text: 'Espere por favor...',
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            }
        })
        setReverseLogistic([])
        const { data } = await axios.post(BASE_URL + '/tim/movements', values)
        await setMovements(data.data)
        if (data.data.length === 0) {
            Swal.fire(
                'No se encontraron datos para la fecha indicada',
                ' ',
                'error'
            )
        } else {
            Swal.fire({
                title: 'Lista de seguimientos cargada correctamente',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    return (
        <>
            {/* <Row className='pt-4'>
                <h3 className='spread-font text-dark text-center'>Recepción</h3>
            </Row> */}
            <Row className='w-100'>
                <FormReception />
            </Row>
            <Row className='w-100'>
                <Col xs={3}>
                    <FilterExpedition />
                </Col>
                <Col xs={9}>
                    <div style={{ 
                        fontFamily: 'Nunito',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        border: '1px solid rgba(255, 255, 255, 0.418)',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
                        borderRadius: '10px',
                        height: '750px'
                        }}>
                        {/* <DataTable 
                            columns={columns}
                            data={data}
                            selectableRows
                            pagination
                            fixedHeader
                            paginationComponentOptions={paginationComponentOptions}
                            noDataComponent={noData}
                        /> */}
                    {/* <MUIDataTable
                        data={movements}
                        columns={headers_}
                        options={options}
                        borderRadius={10}
                    /> */}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Reception