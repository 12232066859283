export const CardsData = [
    {
        title: 'Información para manifiesto',
        path: '',
        cliente:'Spread',
        id: 15,
        // img: 'img/logo-ripley.png',
        button: 'Ingresar',
        class: 'active',
        Text: 'Excel con columna GUIA y Cliente.',
        Text1: 'Desde las bases de datos de clientes.',
        Text2: 'Excel con datos de cada guía.',
    },
    {
        title: 'Herramienta 1',
        path: '',
        cliente:'Spread',
        id: 1,
        img: 'img/logo-paris.png',
        button: 'Herramienta 13',
        class: 'active',
        Text: 'Excel con columna GUIAS.',
        Text1: 'Desde Beetrack Spread.',
        Text2: 'Estado, Subestado, Número de Viaje,Fecha de viaje, Dirección y Comuna.',

    },
    {
        title: 'Herramienta 2',
         path: '',
         cliente:'Spread',
         id: 2,
         img: 'img/logo-paris.png',
         button: 'Herramienta 14',
         class: 'active',
         Text: 'Excel con columna GUIAS.',
         Text1: 'Desde Beetrack Spread.',
         Text2: 'Estado, Subestado y Fecha de entrega.',
     },
     {
        title: 'Herramienta 3',
         path: '',
         cliente:'Spread',
         id: 3,
         img: 'img/logo-paris.png',
         button: 'Herramienta 15',
         class: 'active',
         Text: 'Excel  con columna GUIAS.',
         Text1: 'Desde Beetrack Spread.',
         Text2: 'Estado, Subestado, Fecha de entrega, Receptor, Rut receptor y Parentesco. ',
     },
    {
        title: 'Herramienta 6',
         path: '',
         cliente:'Spread',
         id: 6,
         img: 'img/logo-paris.png',
         button: 'Herramienta 16',
         class: 'active',
         Text: 'Excel con columna GUIAS.',
         Text1: 'Desde sheet En proceso de devolución.',
         Text2: 'Destino.',
     },
    {
        title: 'Herramienta 4',
         path: '',
         cliente:'Spread',
         id: 4,
         img: 'img/logo-paris.png',
         button: 'Herramienta 16',
         class: 'active',
         Text: 'Excel con columna GUIAS. Para Ripley usar el ODC KEY.',
         Text1: 'Desde Beetrack Spread.',
         Text2: 'Estado, Subestado, Fecha de Entrega, Receptor, Rut Receptor, Parentesco y Foto.',
     },
    {
        title: 'IFE',
        path: '',
        cliente:'Spread',
        id: 12,
        // img: 'img/logo-ripley.png',
        button: 'Herramienta **',
        class: 'active',
        Text: 'Listado de guias.',
        Text1: 'Beetrack Spread.',
        Text2: 'Entrega información sobre guías y usuario movil.',
        // Text: 'Input: Excel con Columna GUIA y Cliente'
    },
]
