import { types } from '../types/types'

const defaultState = { isConnected: true, userName: '', user: null }

export const authReducer = (state = defaultState, action) => {
  // console.log('authReducer', action)
  switch (action.type) {
    case types.LOGIN:
      return {
        isConnected: true,
        userName: action.payload.displayName,
        user: action.payload
      }
    case types.LOGOUT:
      return {
        isConnected: false,
        userName: '',
        user: null
      }
    default:
      return state
  }
}