import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGrid, esES } from '@mui/x-data-grid'
import Moment from 'moment';
import { Backdrop, IconButton, CircularProgress, Chip, Tooltip } from '@mui/material'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FaPlus, FaReceipt } from 'react-icons/fa'
import { InputGroup, Modal, Button, Nav, Dropdown, Container, Col, Row, Card, Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import axios from 'axios'
import { MuiChipsInput } from 'mui-chips-input'
import '../../../styles/chips-input.css'
const url = process.env.REACT_APP_URL

const SimulateNavbar = () => {
    return (
        <Row className='gx-0'>
                <Nav className='mt-3 mb-2 justify-content-center border-bottom border-1 spread-font' activeKey='external'>
                    <Nav.Item bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' eventKey='external'><p>Recepción Externa</p></Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' onClick={()=> location = '/wms/movements'}><p>Recepción Interna</p></Nav.Link>
                    </Nav.Item> */}
                </Nav>
        </Row>
    )
}

const ReturnDispatchRouteNew = () => {

    const { user: currentUser } = useSelector(state => state.auth)
    const [openModal, setOpenModal] = useState(true)
    const [openModalSearch, setOpenModalSearch] = useState(false)
    const [usuarioMovil,setUsuarioMovil] = useState('')
    const [ppu,setPPU] = useState('')
    const [rows,setRows] = useState([])
    const [loading,setLoading] = useState(false)
    const [selectedIDs,setSelectedIDs] = useState([])
    const [disabledTypeahead,setDisabledTypeahead] = useState(false)
    const [drivers, setDrivers] = useState([])
    const [chips, setChips] = useState([])
    const handleClose = ()=>setOpenModal(false)
    const MySwal = withReactContent(Swal)
    const today = Moment()
    const titleFormatCol = (title) => <Tooltip title={title} placement="bottom"><strong>{title}</strong></Tooltip>

    const searchInputChange = (newChips) => {
        setChips(newChips)
    }

    const cols = [
        { 
            field: 'status', 
            renderHeader: () => (titleFormatCol('Estado')),
            flex: 0.25,
            renderCell: (params) => {
                return (
                    <>
                        {
                            (params.row.status ==='Entregado' || params.row.system_status === 'RECEPCIONADO') 
                            ? <Chip label="Recepcionado" className='spread-font' style={{'background': 'rgba(0, 155, 120, 0.21)', 'color': '#009B78'}} /> 
                            : <Chip label="No Recepcionado" className='spread-font' style={{'background': 'rgba(234, 67, 53, 0.21)', 'color': '#EA4335'}} />
                        }  
                    </>
                )
            }
        },
        {
            field: 'item',
            renderHeader: () => (titleFormatCol('Item')),
            flex: 0.3,
        },
        {
            field: 'guide',
            renderHeader: () => (titleFormatCol('Guía')),
            flex: 0.3,
        },
        {
            field: 'client',
            renderHeader: () => (titleFormatCol('Cliente')),
            flex: 0.2,
        },
        { 
            field: 'date',
            renderHeader: () => (titleFormatCol('Fecha Viaje')),
            renderCell: (params) => Moment(params.row.date  ).format('DD/MM/YYYY'),
            flex: 0.2,
        },
        {
            field: 'subStatus',
            renderHeader: () => (titleFormatCol('Motivo')),
            flex: 0.6,
        },
        {
            field: 'countdays',
            renderHeader: () => (titleFormatCol('Días Atraso')),
            flex: 0.2,
            renderCell: (params) => today.diff(Moment(params.row.date), 'days'),
            headerClassName: 'lastcolumnSeparator'
        }
    ]

    const getItemsById = async () => {
        if (!chips.length == 0){
            const body = {
                ids : chips,
                user : usuarioMovil,
                ppu : ppu
            }
            try {
                setLoading(true) 
                const { data } = await axios.post(`${url}/undelivered/getReceptionByItem`,body)
                setLoading(false) 
                if (data.data[0]==0) {
                    MySwal.fire({
                        icon: 'info',
                        title: 'No se encontró información asociada :(',
                        showConfirmButton: false,
                        timer: 1500
                      })
                } else {
                    setOpenModalSearch(false)
                    setRows(data.data[0])
                }   
            } catch (err) {
                console.error(err)
            }
        }
    }

    const receptionConfirm = () => {
        if (selectedIDs.length > 0) {
            MySwal.fire({
                title: '¿Está seguro desea Recepcionar?',
                icon: 'success',
                showDenyButton: true,
                denyButtonText: 'No',
                denyButtonColor: '#EA4335',
                confirmButtonText: 'Si',
                confirmButtonColor: '#009B78',
                customClass:{
                    denyButton: 'order-1 spread-font spread-width-btn',
                    confirmButton: 'order-2 spread-font spread-width-btn'
                }
            }).then((result) => {
                if (result.isConfirmed) receptionAction()
            })
        } else {
            MySwal.fire({
                title: 'Ningún item seleccionado',
                icon: 'warning',
                text: 'Debe seleccionar al menos un item',
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const siniesterConfirm = async () => {
        if (selectedIDs.length > 0) {
            MySwal.fire({
                title: '¿Que tipo de siniestro desea declarar?',
                icon: 'question',
                input: 'select',
                inputPlaceholder: 'Seleccione Motivo...',
                inputOptions: {
                    ROBO: 'Robo',
                    EXTRAVIO: 'Extravío',
                    MERMA: 'Merma'
                },
                showCancelButton: true,
                showConfirmButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#009B78',
                customClass:{
                    denyButton: 'order-2 spread-font spread-width-btn',
                    confirmButton: 'order-1 spread-font spread-width-btn',
                    container: 'spread-font',
                    input: 'rounded border-primary text-primary',
                    icon: 'text-primary border-primary'
                }
            }).then((result)=>{
                if (result.isConfirmed) siniesterAction(result.value)
            })
        } else {
            MySwal.fire({
                title: 'Ningún item seleccionado',
                icon: 'warning',
                text: 'Debe seleccionar al menos un item',
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const receptionAction = async () => {
        setLoading(true)
        const body = {
            reception : selectedIDs,
            receiver : currentUser.displayName,
            user : usuarioMovil,
            ppu : ppu
        }
        try {
            let res = await axios.put(`${url}/undelivered/reception`,body)
            setLoading(false)
            if (res.status == 200) {
                MySwal.fire({
                    title: 'Éxito',
                    icon: 'success',
                    text: 'Has terminado el proceso de recepción',
                    confirmButtonText: 'Descargar Comprobante y Finalizar',
                    confirmButtonColor: '#009B78',
                    showDenyButton: false,
                    customClass:{
                        confirmButton: 'spread-font spread-width-100'
                    }
                }).then((result) => {
                    if (result.isConfirmed) generateExcelFileByIds()
                    else window.location.href = '/wms/movements/external/return-dispatch-route'
                })
                
            }
            console.log('Status back: ', res.status)
        } catch(err) {
            console.error(err)
        }
    }

    const siniesterAction = async (reason) => {
        setLoading(true)
        const body = {
            ids : selectedIDs,
            user : usuarioMovil,
            ppu : ppu,
            reason : reason,
            receiver : currentUser.displayName
        }
        try {
            let res = await axios.post(`${url}/undelivered/siniester`,body)
            setLoading(false)
            if (res.status == 200) {
                MySwal.fire({
                    title: 'Éxito',
                    icon: 'success',
                    text: 'Siniestro registrado exitosamente',
                    confirmButtonText: 'Descargar Comprobante y Finalizar',
                    confirmButtonColor: '#009B78',
                    showDenyButton: false,
                    customClass:{
                        confirmButton: 'spread-font spread-width-100'
                    }
                }).then((result) => {
                    if (result.isConfirmed) generateExcelFileByIds()
                    else window.location.href = '/wms/movements/external/return-dispatch-route'
                })
            }
            console.log('Status feedback: ', res.status)
        } catch (err) {
            console.error(err)
        }
    }

 
    const getUndeliveredData = async () => {
        if (!usuarioMovil && !ppu) {
            setOpenModal(false)
            await MySwal.fire({
                title: 'Ups...',
                icon: 'warning',
                text: 'Debe escribir al menos un Usuario o una Patente',
                showConfirmButton: true,
                confirmButtonText: 'Ok',
                confirmButtonColor: '#009B78',
                allowOutsideClick: false
            }).then(setTimeout(setOpenModal(true), 2000))   
        } else {
            setLoading(true)     
            setOpenModal(false)      
            let body = {
                "Usuario Móvil" : usuarioMovil,
                "PPU" : ppu
            }
            const {data} = await axios.post(`${url}/undelivered/getUndelivered`,body)
            setRows(data.data[0])
            setLoading(false)
        }
    }

    const generateExcelFileByIds = async ()=> {
        setLoading(true)
        
        const body = {
            ids: selectedIDs,
            receiver: currentUser.displayName,
            driver : usuarioMovil,
            ppu: ppu,
            date: today
        }
        await axios({
            url:`${url}/undelivered/generateExcelFileByIds`,
            method:'POST',
            responseType:'blob',
            headers :{
                'Access-Control-Allow-Origin' : '*'
               },
            data:body}).then((res)=>{
            // console.log(res)
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'COMPROBANTE DE RECEPCIÓN' + '.xlsx');
            document.body.appendChild(fileLink);
            fileLink.click();
        })
        setLoading(false)
        window.location.href = '/wms/movements/external/return-dispatch-route/success'
    }
    
    return (
        <Container fluid className='gx-0'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal 
                backdrop="static"
                show={openModalSearch} 
                onHide={handleClose}
                centered
                keyboard={false}
                size="lg"
            >
                <Modal.Header className='spread-font' style={{'background':'#E5F5F1', 'color':'#009b78'}}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Búsqueda por pistoleo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card 
                        className='d-flex border text-center spread-font' 
                        style={{
                            'borderRadius': '10px', 
                            'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)'
                        }}
                    >
                        <Card.Body 
                            className='' 
                            style={{
                                'marginTop': '-18px'
                                }}
                        >
                            <span style={{'fontSize':'25px'}}>Items pistoleados: <strong>{chips.length}</strong></span>
                            
                    <MuiChipsInput
                        multiline={false}
                        variant='outlined'
                        color='primary'
                        fullWidth
                        value={chips} 
                        onChange={searchInputChange} 
                        autoFocus
                        className='chips-input-modal'
                        placeholder={chips.length < 1 ? 'Ingrese item y presione enter' : ''}
                    />
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer className='spread-font justify-content-center'>
                    <Button onClick={()=> setOpenModalSearch(false)} style={{
                        'background':'#fff', 
                        'color' : '#EA4335', 
                        'border' : '1px solid #EA4335', 
                        'height': '50px', 
                        'padding' : '0px',
                        'width' : '45%',
                        'borderRadius': '10px'
                    }}>Cancelar</Button>
                    <Button style={{
                        'background':'#009B78', 
                        'border' : '0px solid #EA4335',
                        'color' : '#fff', 
                        'height': '50px', 
                        'padding' : '0px',
                        'width' : '45%',
                        'borderRadius': '10px',
                        'marginLeft' : '0.5rem'
                    }} 
                    disabled={chips.length<1}
                    onClick={()=> getItemsById()}
                    >
                        Cargar
                        </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                backdrop="static"
                show={openModal} 
                onHide={handleClose}
                centered
                keyboard={false}
            >
                <Modal.Header className='spread-font' style={{'background':'#E5F5F1', 'color':'#009b78'}}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Formulario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='spread-font'>
                    <Row className="">
                        <Col>
                            <Form.Control
                                type='text'
                                id='usermobile'
                                aria-describedby='Usuario'
                                placeholder='Usuario'
                            />
                            <Form.Control
                                type='text'
                                id='plate'
                                aria-describedby='Patente'
                                placeholder='Patente'
                            />
                            <Form.Control
                                type='text'
                                id='reason'
                                aria-describedby='Motivo'
                                placeholder='Motivo'
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='spread-font justify-content-center'>
                    <Button variant='danger' onClick={() => location = '/wms/movements/external/return-dispatch-route'} style={{
                        'background':'#fff', 
                        'color' : '#EA4335', 
                        'border' : '1px solid #EA4335', 
                        'height': '50px', 
                        'padding' : '0px',
                        'width' : '45%',
                        'borderRadius': '10px'
                    }}>Cancelar</Button>
                    <Button onClick={getUndeliveredData} style={{
                        'background':'#009B78', 
                        'border' : '0px solid #EA4335',
                        'color' : '#fff', 
                        'height': '50px', 
                        'padding' : '0px',
                        'width' : '45%',
                        'borderRadius': '10px',
                        'marginLeft' : '0.5rem'
                    }} >
                        Continuar
                        </Button>
                </Modal.Footer>
            </Modal>
            <SimulateNavbar />
            <Row className='pt-2 gx-0'>
                <h3 className='spread-font text-dark text-center'>Recepción Externa</h3>
                <h4 className='spread-font text-center' style={{'fontWeight': '400', 'color': '#787777', 'marginTop': '-10px'}}>Devolución Ruta Despachada</h4>
            </Row>
            <Row className='px-3 gx-0 '>
                <Col>
                    <InputGroup className="mb-2 justify-content-end" style={{'height':'60px'}}>
                        {/* <Form.Control
                            placeholder="🔍 Ingrese número de folio o para multiples búsquedas, separelos por 'coma' (,)"
                            name='code'
                            value={formValues?.code ?? ''}
                            onChange={handleInputChange}
                            onKeyPress={handleCodeChange}
                            style={{
                                'border': '1px solid #AFAFAF', 
                                'borderRadius': '10px',
                                'fontFamily': 'Nunito',
                                'fontStyle': 'normal',
                                'color': '#787777',
                                'height':'50px'
                            }}
                            autoFocus
                        /> */}
                                <Button 
                                    className='spread-font' 
                                    style={{
                                        'width': '150px', 
                                        'background': 'rgba(0, 155, 120, 0.21)', 
                                        'border' : 'rgba(0, 155, 120, 0.21)', 
                                        'borderRadius': '10px', 
                                        'marginLeft': '0.5rem', 
                                        'color': '#009B78',
                                        'height': '50px',
                                        'marginTop':'10px' 
                                    }} 
                                    id="button-addon2"
                                    onClick={ ()=> setOpenModalSearch(true) } 
                                >
                                    Pistolear
                                </Button>
                                <Dropdown>
                                    <Dropdown.Toggle className='spread-font' style={{
                                        'width': '150px', 
                                        'background': '#fff', 
                                        'border': '1px solid #009B78',
                                        'borderRadius': '10px', 
                                        'marginLeft': '0.5rem', 
                                        'color': '#009B78',
                                        'height': '50px',
                                        'marginTop':'10px' 
                                    }} >
                                        Acciones
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='spread-font' style={{
                                        'width': '150px'
                                    }}>
                                        <Dropdown.Item onClick={() => window.location.reload(false)}>Nueva Recepción</Dropdown.Item>
                                        <Dropdown.Item onClick={() => receptionConfirm()}>Recepcionar</Dropdown.Item>
                                        <Dropdown.Item onClick={() => siniesterConfirm()}>Siniestro</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                    </InputGroup>
                </Col>
            </Row>
            <Row className='px-3 gx-0'>
                <Col>
                    <Card className='d-flex border text-center spread-font' style={{'borderRadius': '10px', 'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)'}}>
                        <Card.Body className='align-items-center d-flex justify-content-center' style={{'height': 'calc(100vh - 225px)', 'marginTop': '-18px' }}>
                            <DataGrid
                                disableColumnMenu
                                disableSelectionOnClick 
                                pageSize={25}  
                                checkboxSelection
                                rowsPerPageOptions={[25, 50, 100]}
                                isRowSelectable={(params) => (params.row.status !== 'Entregado' && params.row.system_status !== 'RECEPCIONADO')}
                                getRowId={(row) => row.id}
                                rows={rows}
                                columns={cols}
                                onSelectionModelChange = {(e)=>{setSelectedIDs(e)}}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                sx={{
                                    border: 0
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>      
        </Container>
    )
}

const ReturnDispatchRouteResume = () => {
    const { user: currentUser } = useSelector(state => state.auth)    
    const [receivedData,setReceivedData] = useState([])
    const [loading,setLoading] = useState(false)
    
    useEffect(() => {
        setLoading(true)
        axios.get(`${url}/undelivered/receivedData`).then(r => {
            const data = r.data.data[0]
            const dataFiltered = data.filter((data) => data.type == 'DISPATCH_ROUTE')
            setReceivedData(dataFiltered)
            setLoading(false) 
        })
    }, [])

    const titleFormatCol = (title) => <Tooltip title={title} placement="bottom"><strong>{title}</strong></Tooltip>

    const generateExcelFile = async (id_)=> {
        setLoading(true)
        
        const body = {
            id:id_,
            receiver: currentUser.displayName
        }
        await axios({
            url:`${url}/undelivered/generateExcelFile`,
            method:'POST',
            responseType:'blob',
            headers :{
                'Access-Control-Allow-Origin' : '*'
               },
            data:body}).then((res)=>{
            // console.log(res)
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'COMPROBANTE DE DEVOLUCION' + ".xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
        })
        setLoading(false)
    }

    const cols = [
        { 
            field: 'driver', 
            renderHeader: () => (titleFormatCol('Usuario Móvil')),
            flex: 1,
        },
        {
            field: 'ppu',
            renderHeader: () => (titleFormatCol('Patente')),
            flex: 0.3
        },
        { 
            field: 'date',
            renderHeader: () => (titleFormatCol('Fecha de Ruta')),
            renderCell: (params) => Moment(params.row.date).format('DD/MM/YYYY'),
            flex: 0.3,
        },
        {
            field: 'received',
            renderHeader: () => (titleFormatCol('Bultos Recepcionados')),
            flex: 0.3,
        },
        {
            field: 'not_received',
            renderHeader: () => (titleFormatCol('Bultos Pendientes')),
            flex: 0.3,
        },
        {
            field: 'actions',
            renderHeader: () => (titleFormatCol('Acciones')),
            flex: 0.12,
            headerClassName: 'lastcolumnSeparator',
            renderCell: (param) => {
                return (
                    <>
                        {/* <Tooltip title="Imprimir" onClick={()=> proofReception('print')}>
                            <IconButton>
                                <FaPrint />
                            </IconButton>
                        </Tooltip> */}
                        
                        {
                            param.row.received === 0 
                            ?
                       
                                <IconButton disabled>
                                    <FaReceipt />
                                </IconButton>
                   
                            :
                            <Tooltip
                                title="Descargar Comprobante" 
                                onClick={()=> generateExcelFile(param.row.id)}
                            >
                                <IconButton>
                                    <FaReceipt />
                                </IconButton>
                            </Tooltip>
                        }
                        
                        {
                        // USAR ROUTER REDIRECT.
                        /* <Tooltip title="Recepcionar" onClick={()=> location = ('/wms/movements/external/undelivered-return/new?',param.row.driver)}>
                            <IconButton>
                                <FaPeopleCarry />
                            </IconButton>
                        </Tooltip> */}
                    </>
                )
            }
        }
    ]
    return (
        <Container fluid className='gx-0'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SimulateNavbar />
            <Row className='pt-2 gx-0'>
                <h3 className='spread-font text-dark text-center'>Resumen de la Recepción</h3>
                <h4 className='spread-font text-center' style={{'fontWeight': '400', 'color': '#787777', 'marginTop': '-10px'}}>Devolución No Entregados</h4>
            </Row>
            <Row className='px-3 gx-0'>
                <Col>
                    <InputGroup className="mb-2 justify-content-end" style={{'height':'60px'}}>
                        <Button 
                            className='spread-font' 
                            style={{
                                'width': '213px', 
                                'background': '#fff', 
                                'border' : '1px solid #009B78', 
                                'borderRadius': '10px', 
                                'marginLeft': '1rem', 
                                'color': '#009B78',
                                'height': '50px',
                                'marginTop':'10px' 
                            }} 
                            id="button-addon2"
                            href='/wms/movements/external/return-dispatch-route/new'
                        >
                            <div style={{'marginTop':'6px'}}>
                                <FaPlus style={{'marginBottom': '3px'}} /> Nueva Recepción
                            </div>
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
            <Row className='px-3 gx-0'>
                <Col>
                    <Card className='d-flex border text-center spread-font' style={{'borderRadius': '10px', 'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)'}}>
                        <Card.Body className='align-items-center d-flex justify-content-center' style={{'height': 'calc(100vh - 226px)', 'marginTop': '-18px' }}>
                            <DataGrid
                                disableColumnMenu
                                getRowId={(row) => row.id}
                                pageSize={10}  
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                rows={receivedData}
                                columns={cols}
                                pagination
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                sx={{
                                    border: 0
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>  
        </Container>
    )
}

const ReturnDispatchRouteFinished = () => {
    const { user: currentUser } = useSelector(state => state.auth)    
    const [receivedDataOk,setReceivedDataOk] = useState([])
    const [loading,setLoading] = useState(false)
    
    useEffect(() => {
        axios.get(`${url}/undelivered/receivedDataOk`).then(r => {
            const data = r.data.data[0]
            const dataFiltered = data.filter((data) => data.type == 'DISPATCH_ROUTE')
            setReceivedDataOk(dataFiltered)
        })
    }, [])

    const titleFormatCol = (title) => <Tooltip title={title} placement="bottom"><strong>{title}</strong></Tooltip>

    const generateExcelFile = async (id_)=> {
        setLoading(true)
        
        const body = {
            id:id_,
            receiver: currentUser.displayName
        }
        await axios({
            url:`${url}/undelivered/generateExcelFile`,
            method:'POST',
            responseType:'blob',
            headers :{
                'Access-Control-Allow-Origin' : '*'
               },
            data:body}).then((res)=>{
            // console.log(res)
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'COMPROBANTE DE DEVOLUCION' + ".xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
        })
        setLoading(false)
    }

    const cols = [
        { 
            field: 'driver', 
            renderHeader: () => (titleFormatCol('Usuario Móvil')),
            flex: 1,
        },
        {
            field: 'ppu',
            renderHeader: () => (titleFormatCol('Patente')),
            flex: 0.3
        },
        { 
            field: 'date',
            renderHeader: () => (titleFormatCol('Fecha de Ruta')),
            renderCell: (params) => Moment(params.row.date).format('DD/MM/YYYY'),
            flex: 0.3,
        },
        {
            field: 'received',
            renderHeader: () => (titleFormatCol('Bultos Recepcionados')),
            flex: 0.3,
        },
        {
            field: 'not_received',
            renderHeader: () => (titleFormatCol('Bultos Pendientes')),
            flex: 0.3,
        },
        {
            field: 'actions',
            renderHeader: () => (titleFormatCol('Acciones')),
            flex: 0.12,
            headerClassName: 'lastcolumnSeparator',
            renderCell: (param) => {
                return (
                    <>
                        {/* <Tooltip title="Imprimir" onClick={()=> proofReception('print')}>
                            <IconButton>
                                <FaPrint />
                            </IconButton>
                        </Tooltip> */}
                        
                        {
                            param.row.received === 0 
                            ?
                            <Tooltip disableFocusListener title="No existen bultos para emitir">
                                <IconButton disabled>
                                    <FaReceipt />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip
                                title="Descargar Comprobante" 
                                onClick={()=> generateExcelFile(param.row.id)}
                            >
                                <IconButton>
                                    <FaReceipt />
                                </IconButton>
                            </Tooltip>
                        }
                        
                        {
                        // USAR ROUTER REDIRECT.
                        /* <Tooltip title="Recepcionar" onClick={()=> location = ('/wms/movements/external/undelivered-return/new?',param.row.driver)}>
                            <IconButton>
                                <FaPeopleCarry />
                            </IconButton>
                        </Tooltip> */}
                    </>
                )
            }
        }
    ]
    return (
        <Container fluid className='gx-0'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ReturnUndelivered />
            <Row className='pt-2 gx-0'>
                <h3 className='spread-font text-dark text-center'>Resumen de la Recepción</h3>
                <h4 className='spread-font text-center' style={{'fontWeight': '400', 'color': '#787777', 'marginTop': '-10px'}}>Devolución No Entregados</h4>
            </Row>
            <Row className='px-3 gx-0'>
                <Col>
                    <InputGroup className="mb-2 justify-content-end" style={{'height':'60px'}}>
                        <Button 
                            className='spread-font' 
                            style={{
                                'width': '213px', 
                                'background': '#fff', 
                                'border' : '1px solid #009B78', 
                                'borderRadius': '10px', 
                                'marginLeft': '1rem', 
                                'color': '#009B78',
                                'height': '50px',
                                'marginTop':'10px' 
                            }} 
                            id="button-addon2"
                            href='/wms/movements/external/return-dispatch-route/new'
                        >
                            <div style={{'marginTop':'6px'}}>
                                <FaPlus style={{'marginBottom': '3px'}} /> Nueva Recepción
                            </div>
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
            <Row className='px-3 gx-0'>
                <Col>
                    <Card className='d-flex border text-center spread-font' style={{'borderRadius': '10px', 'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)'}}>
                        <Card.Body className='align-items-center d-flex justify-content-center' style={{'height': 'calc(100vh - 226px)', 'marginTop': '-18px' }}>
                            <DataGrid
                                disableColumnMenu
                                getRowId={(row) => row.id}
                                pageSize={10}  
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                rows={receivedDataOk}
                                columns={cols}
                                pagination
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                sx={{
                                    border: 0
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>  
        </Container>
    )
}


export { ReturnDispatchRouteNew, ReturnDispatchRouteResume, ReturnDispatchRouteFinished }
