import { types } from '../types/types'

export const setError = err => ({
  type: types.UI_SET_ERROR,
  payload: err
})

export const removeError = () => ({
  type: types.UI_REMOVE_ERROR
})

export const setTitle = title => ({
  type: types.UI_SET_TITLE,
  payload: title
})

export const resetTitle = () => ({
  type: types.UI_RESET_TITLE
})

export const startLoading = () => ({
  type: types.UI_START_LOADING
})

export const finishLoading = () => ({
  type: types.UI_FINISH_LOADING
})

export const openBackdrop = () => ({
  type: types.UI_OPEN_BACKDROP
})

export const closeBackdrop = () => ({
  type: types.UI_CLOSE_BACKDROP
})

export const openModal = () => ({
  type: types.UI_OPEN_MODAL
})

export const closeModal = () => ({
  type: types.UI_CLOSE_MODAL
})
