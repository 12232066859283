import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { BsRecordFill } from "react-icons/bs";

import '../../styles/InformationBox.css'


export default function InformationBox() {
     return (
          <Container fluid className=''>
               <Card className="infoBox-style card-header-tools">
                    <Card.Header className="">
                         <Row>
                              <Col className="">
                                   <Card.Text className="titleInfo">Instrucciones de uso</Card.Text>
                              </Col>
                         </Row>
                    </Card.Header>
                    <Card.Body className="card-body-tools">
                         <Row>
                              <Col className="RowBox-Header">
                                   <Card.Text className="subTitleInfo"><BsRecordFill className="IconPonit" />Paso 1</Card.Text>
                                   <Card.Text className="bodyInfo">
                                        Leer input de las Herramientas si recibe un listado de Guías Descargar
                                        plantilla y llenar la columna GUIA con los folios o Usar EXCEL
                                        proprio pero verificar de que el nombre de la columna con los folios tenga el nombre GUIA.
                                   </Card.Text>
                              </Col>
                              <Col md={12} className="RowBox-Header-2">
                                   <Card.Text className="subTitleInfo"><BsRecordFill className="IconPonit" />Paso 2</Card.Text>
                                   <Card.Text className="bodyInfo">
                                        Revisar la descripción de cada herramienta y clicar en el boton de la que se quiere usar.
                                   </Card.Text>
                              </Col>

                              <Col md={12} className="RowBox-Header-3">
                                   <Card.Text className="subTitleInfo"><BsRecordFill className="IconPonit" />Paso 3</Card.Text>
                                   <Card.Text className="bodyInfo">
                                        Clicar en examinar, seleccionar el archivo a utilizar y hacer clic en el boton continuar.
                                   </Card.Text>
                              </Col>
                              <Col md={12}>
                                   <Card.Text className="subTitleInfo"><BsRecordFill className="IconPonit" />Paso 4</Card.Text>
                                   <Card.Text className="bodyInfo">
                                        Esperar a que el resultado llegue al correo proporcionado.
                                   </Card.Text>
                              </Col>
                         </Row>
                    </Card.Body>
               </Card>
          </Container>
     )
}
