import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid, esES } from '@mui/x-data-grid'
import { utils, write } from 'xlsx'
import Moment from 'moment';
import { Tooltip, Backdrop, CircularProgress, IconButton } from '@mui/material'
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver'
import withReactContent from 'sweetalert2-react-content'
import dayjs from 'dayjs'
import { mockSelects } from '../../mock/mockSelects'
import { FaRegClipboard } from 'react-icons/fa'
import { InputGroup, Button, Modal, Container, Col, Row, Card, Form, FloatingLabel, Collapse } from 'react-bootstrap'
import { useForm } from '../../../../hooks/useForm'
import { saveOrderNumbers, startLoadingReprocessing, startLoadingReprocessingList } from '../../../../actions/reprocessing'
import { Typeahead } from 'react-bootstrap-typeahead'
import axios from 'axios'
//import styles from '../../styles/filters.module.css'


const url = process.env.REACT_APP_URL

const iconSize = {
    fontSize: '30px',
    marginRight: '0.5em',
    marginBottom: '3px',
    color: '#787777'
}

const Vas = () => {
    const [fileNames, setFileNames] = useState([]);
    const [packageState, setPackageState] = useState('')
    const [clientSelects] = useState(mockSelects.clients)
    const [originSelects] = useState(mockSelects.origins)
    const [destinationSelects] = useState(mockSelects.destinations)
    const [reasonSelects] = useState(mockSelects.reasons)
    const [data, setData] = useState([])
    const handleDrop = acceptedFiles => setFileNames(acceptedFiles.map(file => file.name))
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [formValues, handleInputChange] = useForm()
    const { reprocessingList } = useSelector(state => state.reprocessing)
    const MySwal = withReactContent(Swal)
    const [show, setShow] = useState(false)
    const [searchParam, setSearchParam] = useState('')
    const handleKeyUpVas = () => setDataVasFilter(dataVas.filter((data)=> data.orderNo.includes(searchParam) || data.region.includes(searchParam) || data.client.includes(searchParam) || data.origin.includes(searchParam) || data.flow.includes(searchParam) || data.reason.includes(searchParam) || data.reprocessingDate.includes(searchParam) || data.responsibleName.includes(searchParam) ))
    const handleClose = () => {
        setShow(false)
        setDataBeetrack([])
        // setItemSearch('')
        // setGuideSearch('')
        // setClientSearch('')
        // setRegionSearch('')
        // setFlowSearch('')
        // setOriginSearch('')
    }
    const handleShow = () => setShow(true)
    const [dataVas, setDataVas] = useState([])
    const [dataVasFilter, setDataVasFilter] = useState([])
    const [guideSearch, setGuideSearch] = useState('')
    const [itemSearch, setItemSearch] = useState('')
    const [clientSearch, setClientSearch] = useState('')
    const [regionSearch, setRegionSearch] = useState('')
    const [originSearch, setOriginSearch] = useState('')
    const [flowSearch, setFlowSearch] = useState('')
    const [dataBeetrack, setDataBeetrack] = useState([])

    const titleFormatCol = (title) => <Tooltip title={title} placement="bottom"><strong>{title}</strong></Tooltip>
    const manageContact = (itemData) => {
        setItemSearch(itemData.orderNo)
        setClientSearch(itemData.client)
        setRegionSearch(itemData.region)
        setFlowSearch(itemData.flow)
        setOriginSearch(itemData.origin)
        handleShow()
    }
    const cols = [
        {
            field: 'orderNo',
            renderHeader: () => (titleFormatCol('Item')),
            flex: 0.3,
        },
        {
            field: 'region',
            renderHeader: () => (titleFormatCol('Región')),
            flex: 0.25,
        },
        {
            field: 'client',
            renderHeader: () => (titleFormatCol('Cliente')),
            flex: 0.3,
        },
        {
            field: 'flow',
            renderHeader: () => (titleFormatCol('Flujo')),
            flex: 0.25,
        },
        {
            field: 'origin',
            renderHeader: () => (titleFormatCol('Origen')),
            flex: 0.6
        },
        {
            field: 'reason',
            renderHeader: () => (titleFormatCol('Motivo')),
            flex: 0.6
        },
        {
            field: 'reprocessingDate',
            renderHeader: () => (titleFormatCol('Fecha')),
            flex: 0.4,
            renderCell: (params) => Moment(params.row.reprocessingDate).format('DD/MM/YYYY')

        },
        {
            field: 'responsibleName',
            renderHeader: () => (titleFormatCol('Responsable')),
            flex: 0.5,
        },
        {
            field: 'manage',
            renderHeader: () => (titleFormatCol('Gestionar')),
            flex: 0.25,
            headerClassName: 'lastcolumnSeparator',
            renderCell: (params) => { 
                return (
                    <Tooltip
                    title="Gestionar" 
                    onClick={() => manageContact(params.row)}
                    >
                        <IconButton>
                            <FaRegClipboard />
                        </IconButton>
                    </Tooltip>
                )
            }
        }
    ]


    useEffect(() => {
        if (dataVas.length == 0) {
            loadWarehouseByParams()
        }
    },[])

    const loadWarehouseByParams = async () => {
        setLoading(true)
        const body = {
            destination: 'VAS'
        }
        await axios.post(`${url}/reprocessings/getWarehouseByParam`,body).then(r => {
            const data = r.data.data[0]
            setLoading(false) 
            if (r.data.data[0].length == 0) {
                MySwal.fire({
                    icon: 'info',
                    title: 'No se encontró información asociada',
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                console.log(data)
                setDataVas(data)
                setDataVasFilter(data)
            }
        })
    }
    
    const searchBeetrackData = async () => {
        if (guideSearch) {
            try {
                setLoading(true)
                const url_ = 'https://consulta-datos-beetrack.azurewebsites.net/api'
                const dataJSON = await axios.post(`${url_}/getinfo?guide=${guideSearch}`)
                const dataArray = Object.values(dataJSON.data)
                console.log(dataJSON)
                setLoading(false) 
                if (dataJSON.data.message==='Not found') {
                    MySwal.fire({
                        icon: 'info',
                        title: 'No se encontró información asociada :(',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    setDataBeetrack(dataArray)
                }   
            } catch (err) {
                console.error(err)
            }
            
        } else {
            MySwal.fire({
                title: 'Sin info',
                icon: 'warning',
                text: 'Debe escribir al menos una guía',
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    return (
        <Container fluid className='gx-0'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton className='spread-font' style={{ 'background': '#E5F5F1', 'color': '#009b78' }}>
                    <Modal.Title>Formulario VAS</Modal.Title>
                </Modal.Header>
                <Modal.Body className='spread-font'>
                    {dataBeetrack.length == 0 &&
                        <InputGroup className="mb-2 justify-content-end " style={{ 'height': '60px' }}>
                            <Form.Control
                                placeholder="Ingrese o escane guía para cargar la información"
                                aria-label="guide"
                                style={{
                                    'borderRadius': '10px'
                                }}
                                onChange={(e)=> setGuideSearch(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        searchBeetrackData()
                                    }
                                }}
                            />
                            <Button
                                className='spread-font'
                                style={{
                                    'width': '150px',
                                    'background': 'rgba(0, 155, 120, 0.21)',
                                    'border': 'rgba(0, 155, 120, 0.21)',
                                    'borderRadius': '10px',
                                    'marginLeft': '0.5rem',
                                    'color': '#009B78',
                                    'height': '50px',
                                    'marginTop': '10px'
                                }}
                                id="button-addon2"
                                onClick={() => searchBeetrackData()}
                            >
                                Buscar
                            </Button>
                        </InputGroup>
                    }

                    {
                        
                        dataBeetrack.length > 0 &&
                        <div >
                            <Modal.Title>Datos Cliente</Modal.Title>
                            <Row className="">
                                <Form.Group as={Col} controlId="itemForm">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Item"
                                        className=""
                                    >
                                        <Form.Control type="text" placeholder="Item" readOnly value={itemSearch} />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} controlId="nombreForm">
                                <FloatingLabel
                                        controlId="floatingInput"
                                        label="Destinatario"
                                        className=""
                                    >
                                        <Form.Control type="text" placeholder="Nombre Cliente" readOnly value={dataBeetrack[1].contact_name} />
                                    </FloatingLabel>
                                </Form.Group>
                            </Row>
                            <Row className="">
                                <Form.Group as={Col} controlId="clienteForm">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Cliente"
                                        className=""
                                    >
                                        <Form.Control type="text" placeholder="Cliente" readOnly value={clientSearch}/>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} controlId="regionForm">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Region"
                                        className=""
                                    >
                                        <Form.Control type="text" placeholder="Region" value={regionSearch}/>
                                    </FloatingLabel>
                                </Form.Group>
                            </Row>
                            <Row className="">
                                <Form.Group as={Col} controlId="motivoForm">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Motivo"
                                        className=""
                                    >
                                        <Form.Control type="text" placeholder="Motivo" readOnly value={dataBeetrack[1].substatus} />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} controlId="comunaForm">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Comuna"
                                        className=""
                                    >
                                        <Form.Control type="text" placeholder="Comuna" value={dataBeetrack[1].tags[0]['value']} />
                                    </FloatingLabel>
                                    
                                </Form.Group>
                            </Row>
                            <Row className=''>
                                <Form.Group as={Col} controlId="origenForm">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Origen"
                                        className=""
                                    >
                                        <Form.Control type="text" placeholder="Origen" readOnly value={originSearch}/>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} controlId="direccionForm">
                                    <FloatingLabel
                                            controlId="floatingInput"
                                            label="Dirección"
                                            className=""
                                        >
                                        <Form.Control type="text" placeholder="Dirección" value={dataBeetrack[1].contact_address} />
                                    </FloatingLabel>
                                </Form.Group>
                            </Row>
                            <Row className=''>
                                <Form.Group as={Col} controlId="flujoForm">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Flujo"
                                        className=""
                                    >
                                        <Form.Control type="text" placeholder="Flujo" readOnly value={flowSearch} />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} controlId="telefonoForm">
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Teléfono"
                                        className=""
                                    >
                                        <Form.Control type="text" placeholder="Teléfono Cliente" value={dataBeetrack[1].contact_phone} />
                                    </FloatingLabel>
                                </Form.Group>                  
                            </Row>
                            <hr
                                style={{
                                    
                                    height: 1
                                }}
                            />
                            <Modal.Title>Destino</Modal.Title>
                                <FloatingLabel as={Col} controlId="floatingSelect" label="Destino de bulto" className='mt-1'>
                                    <Form.Select aria-label="Floating label select example">
                                        <option value='' disabled>Seleccione un destino</option>
                                        <option value="REPITE">REPITE</option>
                                        <option value="PRE-LOGISTICA INVERSA">PRE-LOGISTICA INVERSA</option>
                                        <option value="MERMA">MERMA</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <Form.Group as={Col} className='pt-2' controlId="observacionesForm"  >
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Observaciones"
                                        className=""
                                    >
                                        <Form.Control as="textarea" placeholder="Observaciones" style={{ 'height': '136px' }} />
                                    </FloatingLabel>
                                </Form.Group>
                                

                        </div>

                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='spread-font' onClick={handleClose} style={{
                        'background': '#fff',
                        'color': '#EA4335',
                        'border': '1px solid #EA4335',
                        'height': '50px',
                        'padding': '0px',
                        'width': '250px',
                        'borderRadius': '10px'
                    }}>Cancelar</Button>
                    <Button variant="primary" className='spread-font' style={{
                        'background': '#009B78',
                        'color': '#fff',
                        'height': '50px',
                        'padding': '0px',
                        'width': '250px',
                        'borderRadius': '10px'
                    }} >
                        Continuar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row className='pt-2 gx-0'>
                <h3 className='spread-font text-dark text-center'>Vas</h3>
            </Row>
            <Row className='px-3 gx-0'>
                <Col>
                    <InputGroup className="mb-2" style={{ 'height': '60px' }}>
                        <Form.Control
                            placeholder="🔍 Buscador"
                            name='code'
                            type='text'
                            onChange={(v) => setSearchParam(v.target.value.toUpperCase())}
                            onKeyUp={() => handleKeyUpVas()}
                            style={{
                                'border': '1px solid #AFAFAF',
                                'borderRadius': '10px',
                                'fontFamily': 'Nunito',
                                'fontStyle': 'normal',
                                'color': '#787777',
                                'height': '50px'
                            }}
                            autoFocus
                        />
                        <Button
                            className='spread-font'
                            style={{
                                'width': '213px',
                                'background': 'rgba(0, 155, 120, 0.21)',
                                'border': 'rgba(0, 155, 120, 0.21)',
                                'borderRadius': '10px',
                                'marginLeft': '0.5rem',
                                'color': '#009B78',
                                'height': '50px',
                                'marginTop': '10px'
                            }}
                            id="button-addon2"
                        //.={handleCodeSearchBtn} 
                        >
                            Buscar
                        </Button>
                        {/* <Button 
                            className='spread-font' 
                            style={{
                                'width': '213px', 
                                'background': '#fff', 
                                'border' : '1px solid #009B78', 
                                'borderRadius': '10px', 
                                'marginLeft': '0.5rem', 
                                'color': '#009B78',
                                'height': '50px',
                                'marginTop':'10px' 
                            }} 
                        >
                            Gestionar
                        </Button> */}
                    </InputGroup>
                </Col>
            </Row>
            <Row className='px-3 gx-0'>
                {/* <Col xs={12} md={3} lg={3} xl={2} className=''>
                    <Card style={{'borderRadius': '10px', 'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)'}}>
                        <Card.Header as="h3" className={`border-bottom py-3 ${styles.title}`}>Filtros</Card.Header>
                        <Card.Body style={{'height': 'calc(100vh - 468px)', 'overflowY': 'auto', 'minHeight': '150px'}}>
                            <Form.Group>
                                <div className={`${styles.FormContainer}`}>
                                    <InputGroup className={`my-2 ${styles.DivInputFecha}`}>
                                        <InputGroup.Text><BsCalendar/></InputGroup.Text>
                                        <Form.Control id='to' type='date' name='date' onChange={handleInputChange} className={styles.InputPrincipalFecha} placeholder='Fecha'/>
                                    </InputGroup>
                                    <Form.Select aria-label="Región" name='region' id='region' onChange={changeState} className={styles.InputPrincipal} defaultValue={''}>
                                        <option hidden value={''}>Seleccione Region</option>
                                        <option value={'RM'}>Metropolitana</option>
                                        <option value={'V'}>Valparaiso</option>
                                        <option value={'VI'}>LGB O’Higgins</option>
                                        <option value={'VII'}>Maule</option>
                                    </Form.Select>
                                    <Form.Select aria-label="Cliente" name='client' id='client' onChange={changeState} className={styles.InputPrincipal} defaultValue={''}>
                                        <option hidden value={''}>Seleccione Cliente</option>
                                        <option value={'EASY'}>Easy</option>
                                        <option value={'PARIS'}>Falabella</option>
                                        <option value={'PARIS'}>Paris</option>
                                        <option value={'RIPLEY'}>Ripley</option>
                                        <option value={'SFILLMENT'}>Sfillment</option>
                                    </Form.Select>
                                    <Form.Select aria-label="Flujo" name='flow' id='flow' onChange={changeState} className={styles.InputPrincipal} defaultValue={''}>
                                        <option hidden value={''}>Seleccione Flujo</option>
                                        <option value={'MT'}>Medium Ticket</option>
                                        <option value={'MM'}>Mini Ticket</option>
                                    </Form.Select>
                                    <Form.Select aria-label="Origen" name='origin' id='origin' onChange={changeState} className={styles.InputPrincipal} defaultValue={''}>
                                        <option hidden value={''}>Seleccione Origen</option>
                                        <option value={'SORTING REGULAR'}>Sorting Regular</option>
                                        <option value={'SORTING SDD'}>Sorting SSD</option>
                                        <option value={'DESPACHO REGULAR'}>Despacho Regular</option>
                                        <option value={'DESPACHO SDD'}>Despacho SSD</option>
                                        <option value={'DEVOLUCIÓN'}>Devolución</option>
                                        <option value={'LOGISTICA INVERSA'}>Logística Inversa</option>
                                        <option value={'RUTA'}>Ruta</option>
                                        <option value={'EN PROCESO'}>En Proceso</option>
                                        <option value={'OTRO'}>Otro</option>
                                    </Form.Select>
                                    <Form.Select aria-label="Motivo" name='movementType' id='movementType' onChange={changeState} className={styles.InputPrincipal} defaultValue={''}>
                                        <option hidden value={''}>Seleccione Motivo</option>
                                        <option value={'NO ENTREGA MOTIVO CL'}>No Entrega Motivo CL</option>
                                        <option value={'NO ENTREGA MOTIVO TTE'}>No Entrega Motivo TTE</option>
                                        <option value={'ERROR DE BINS'}>Error De Bins</option>
                                        <option value={'SOBRE DIMENSIONADO'}>Sobre Dimensionado</option>
                                        <option value={'ENTREGA PARCIAL'}>Entrega Parcial</option>
                                        <option value={'FALSA ENTREGA'}>Falsa Entrega</option>
                                        <option value={'SIN INFORMACION'}>Sin Información</option>
                                        <option value={'SIN PLANIFICAR'}>Sin Planificar</option>
                                        <option value={'DIRECCION ERRONEA'}>Dirección Errónea</option>
                                        <option value={'ROBO O SINIESTRO'}>Robo o Siniestro</option>
                                        <option value={'MERMA'}>Merma</option>
                                        <option value={'EMPAQUE DAÑADO'}>Empaque Dañado</option>
                                        <option value={'RECOGIDO'}>Recogido</option>
                                        <option value={'DUPLICADO'}>Duplicado</option>
                                    </Form.Select>
                                    <Form.Select aria-label="Destino" name='destiny' id='destiny' onChange={changeState} className={styles.InputPrincipal} defaultValue={''}>
                                        <option hidden value={''}>Seleccione Destino</option>
                                        <option value={'ASIGNAR MANUAL'}>Asignar manual</option>
                                        <option value={'EN PROCESO'}>En Proceso</option>
                                        <option value={'REASIGNAR'}>Reasignar</option>
                                        <option value={'LOGISTICA INVERSA'}>Logistica Inversa</option>
                                        <option value={'MERMA'}>Merma</option>
                                        <option value={'SIN INFORMACION'}>Sin Información</option>
                                        <option value={'DISCREPANCIA DE CARGA'}>Discrepancia de Carga</option>
                                        <option value={'POR DESPACHAR EN BODEGA'}>Por despachar en bodega</option>
                                        <option value={'DESPACHADO EN BODEGA'}>Despachado en bodega</option>
                                        <option value={'DEVUELTO EN RUTA'}>Devuelto en ruta</option>
                                        <option value={'DEVUELTO A CLIENTE'}>Devuelto a cliente</option>
                                    </Form.Select>
                                </div>
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer >
                            <div className='d-grid gap-2'>
                                <Button type='submit' size="lg" 
                                    className={`${styles.BtnBuscar} btn btn-lg`}>
                                    <BsSearch className={styles.iconBuscar}/>
                                    <span className={styles.TxtBtnBuscar}>BUSCAR</span>
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col> */}
                <Col xs={12} md={12} lg={12} xl={12} className=''>
                    <Card className='d-flex border text-center spread-font' style={{ 'borderRadius': '10px', 'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)' }}>
                        <Card.Body className='align-items-center d-flex justify-content-center' style={{ 'height': 'calc(100vh - 200px)', 'marginTop': '-18px' }}>
                            <DataGrid
                                disableSelectionOnClick
                                disableColumnMenu
                                getRowId={(row) => row.id}
                                rows={dataVasFilter}
                                columns={cols}
                                pageSize={25}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                sx={{
                                    border: 0
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Vas
