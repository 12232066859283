import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ToolsSpread from '../components/OptionTarget/TargetSpread'

import InformationBox from '../components/InformationBox/InformationBox'


export default function SpreadTools() {
    return (
        <>
            <Container fluid className='gx-0'>
                <Row className='gx-0'>
                   <Col md={9} style={{'overflowY': 'auto', 'height': 'calc(100vh - 200px)'}}>
                        <ToolsSpread />
                   </Col>
                   <Col md={3}>
                        <InformationBox />
                   </Col>
                </Row>
            </Container>
        </>
    )
}

