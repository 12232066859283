import { Container, Button, Form, InputGroup } from 'react-bootstrap'
import styles from '../../styles/filterExpedition.module.css'
import { BsSearch, BsCalendar } from "react-icons/bs"

export default function FormFiltrosExpedicion() {
     return (
          <Container fluid className={styles.DivContainer}>
               <Form.Group>
                    <div className='border-bottom'>
                         <h3 className={styles.title}>Filtros</h3>
                    </div>
                    <div className={styles.Container}>
                         <Form.Control className={styles.InputPrincipal} id='' type='' placeholder='N° TIM' autoFocus />
                    </div>
                    <div>
                         <Form.Label className={styles.SegTitle}>Seleccionar Rango de Fecha</Form.Label>
                    </div>
                    <div>
                         {/* <BsCalendar className={styles.iconCalendario}/>
                         <div className={styles.DivInputFecha}> */}
                         <InputGroup className={styles.DivInputFecha}>
                              <InputGroup.Text><BsCalendar/></InputGroup.Text>
                              <Form.Control id='' type='date' className={styles.InputPrincipalFecha} placeholder='dd/mm/aaaa'/>
                         </InputGroup>
                              
                         {/* </div> */}
                         <Form.Control id='' type='text' className={styles.InputPrincipal} placeholder='Estado'/>
                         <Form.Control id='' type='text' className={styles.InputPrincipal} placeholder='Origen'/>
                         <Form.Control id='' type='text' className={styles.InputPrincipal} placeholder='Destino'/>
                         <Form.Control id='' type='text' className={styles.InputPrincipal} placeholder='Patente'/>
                         <Form.Control id='' type='text' className={styles.InputPrincipal} placeholder='N° Sello'/>
                    </div>
                    <div className='d-grid gap-2 py-3'>
                         <Button type='submit' size="lg" className={styles.BtnBuscar}>
                              <BsSearch className={styles.iconBuscar}/>
                              <span className={styles.TxtBtnBuscar}>BUSCAR</span>
                         </Button>
                    </div>
               </Form.Group>
          </Container>
     )
}