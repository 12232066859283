import React from 'react'
import { Container, Nav, Tab } from 'react-bootstrap'
import Expedition from './views/Expedition'
import Reception from './views/Reception'
import './styles/tabs.css'

export default function Movements() { 
    return (
        <Container fluid>
            <Tab.Container id='submenu-header' defaultActiveKey='expedition'>
                <Nav variant='pills' className='mt-3 mb-2 justify-content-center border-bottom border-1 spread-font'>
                    <Nav.Item  bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' eventKey='expedition'><p>Expedición</p></Nav.Link>
                    </Nav.Item>
                    <Nav.Item bsPrefix='subheader-item'>
                        <Nav.Link as='button' bsPrefix='subheader-link' eventKey='reception'><p>Recepción</p></Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey='expedition'>
                        <Expedition />  
                    </Tab.Pane>
                    <Tab.Pane eventKey='reception'>
                        <Reception />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>
    )    
}
