import { types } from '../types/types'

const initialState = {
  loading: false,
  msgError: null,
  backdropIsOpen: false,
  title: 'App Spread'
}

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UI_SET_ERROR:
      return {
        ...state,
        msgError: action.payload
      }
    case types.UI_SET_TITLE:
      return {
        ...state,
        title: action.payload
      }
    case types.UI_RESET_TITLE:
      return {
        ...state,
        title: initialState.title
      }
    case types.UI_REMOVE_ERROR:
      return {
        ...state,
        msgError: null
      }
    case types.UI_START_LOADING:
      return {
        ...state,
        loading: true
      }
    case types.UI_FINISH_LOADING:
      return {
        ...state,
        loading: false
      }
    case types.UI_OPEN_BACKDROP:
      return {
        ...state,
        backdropIsOpen: true
      }
    case types.UI_CLOSE_BACKDROP:
      return {
        ...state,
        backdropIsOpen: false
      }
    case types.UI_OPEN_MODAL:
      return {
        ...state,
        modalIsOpen: true
      }
    case types.UI_CLOSE_MODAL:
      return {
        ...state,
        modalIsOpen: false
      }
    default:
      return state
  }
}