import axios from 'axios';
import React from 'react'
import { Button, Modal, Row, Col, Form, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx';

import { BsUpload, BsExclamationCircle, BsX, BsFillEnvelopeFill, BsXLg } from 'react-icons/bs'
import styles from '../../styles/ModalCard.module.css'
import './StyleMail.css'


// import { Input } from '@material-ui/core';
// import { TextField } from 'material-ui';
const readExcelAsync = async (file) => {
     return new Promise((resolve,reject)=>{
       let reader = new FileReader()
       reader.onload = (evt) => { 
           const bstr = evt.target.result;
           const wb = XLSX.read(bstr, {type:'binary'});
           const wsname = wb.SheetNames[0];
           const ws = wb.Sheets[wsname];
           const data_json = XLSX.utils.sheet_to_json(ws);
           resolve(data_json)
       };
       reader.onerror = reject
       reader.readAsBinaryString(file);
   
     })      
   }
function MyVerticallyCenteredModal(props) {
     
     const login = useSelector(state => state.auth)
     console.log(login)
     function MostrarInput() {
          document.getElementById("inputMail").style.display = 'block';
     }

     function OcultarInput() {
          document.getElementById("inputMail").style.display = 'none';
     }

     return (
          <Modal {...props} size="lg" centered className={styles.Modal}>
               {/* aria-labelledby="contained-modal-title-vcenter"  */}
               <Modal.Header closeButton className={styles.ModalHeader}>
                    <Modal.Title id="contained-modal-title-vcenter" className={styles.ModalTitle}>
                    
                         <Row className={styles.RowTitle}>
                              <p>{props.titulo}</p>
                         </Row>
                    </Modal.Title>
               </Modal.Header>
               <Modal.Body className={styles.ModalBody}>
                    <Row >
                         <Form.Label>{props.cliente}</Form.Label>

                         <Col md={12} className={styles.ColBtn}>
                              <div className={styles.DivUploadFile}>
                                   <Button className={styles.UploadFile}  onClick={()=>{console.log('hola')}}>
                                        <input type='file' id='excel_input'></input>
                                   </Button>
                                   <p className={styles.textInput}><BsUpload className={`${styles.IconUp} float-start`} />Seleccionar archivo</p>
                              </div>
                         </Col>

                    </Row>
                    <Row className={styles.RowTab}>
                         <Col className={styles.ColExclamation}>
                              <BsExclamationCircle className={`${styles.IconExclamation} float-start`}  /> 
                              Sin archivo seleccionado
                         </Col>
                    </Row>
               </Modal.Body>
               <Modal.Footer className={styles.ModalFooter}>
                    <Row className={styles.RowBtnMail}>
                         <Col className={styles.BtnMail}>
                              <Button id='btnMostrar' onClick={() => MostrarInput()} className={styles.Mail}><BsFillEnvelopeFill className={styles.IconMail} />
                                   <p className={styles.TextBtnMail}>Enviar al correo</p>
                                   
                              </Button>

                              <div id="inputMail" className={styles.DivInputMail}>
                                   <Button id='btnOcultar' onClick={() => OcultarInput()} className={`${styles.CloseMail} float-end`}><BsXLg className={styles.IconX} /></Button>
                                   <Form.Control className={`${styles.InputMail} float-end`} type="mail" id="InputMail" placeholder='Ingrese su correo Spread' ></Form.Control>
                                   <Form.Label className={styles.LabelHelp}>Debe ingresar el correo correspondiente a la ORG Spread</Form.Label>
                              </div>
                         </Col>
                    </Row>
                    <Row className={styles.RowBtn}>
                         <Col md={6} className={styles.ColBtn}>
                              <Button onClick={props.onHide} className={styles.BtnClose} style={{ 'background': '#009B78', 'color': '#fff', 'marginTop': '10px', 'height': '50px', 'borderRadius': '10px' }}>CERRAR</Button>
                         </Col>
                         <Col md={6} className={styles.ColBtn}>
                              <Button onClick={async()=> {
                                   Loading = true
                                   const archivo = document.getElementById('excel_input')
                                   console.log(archivo.value,archivo.files[0])
                                   const excel_data = await readExcelAsync(archivo.files[0])
                                   console.log(excel_data)
                                   const data_axios = []
                                   for(let i=0;i<excel_data.length;i++){
                                        data_axios.push(excel_data[i].GUIA)

                                   }
                                   console.log(data_axios) 
                                   await axios.post(process.env.REACT_APP_URL_AZURE,{
                                        'correo':login['user']['email'],
                                        'guias':data_axios,
                                        'herramienta':props.id
                                   }).then(props.onHide()).finally(Loading = false)
                                   props.onHide()
                              }} className={styles.BtnContinue} style={{ 'background': '#009B78', 'color': '#fff', 'marginTop': '10px', 'height': '50px', 'borderRadius': '10px' }}  variant="primary" type="submit">CONTINUAR</Button>
                         </Col>
                    </Row>
               </Modal.Footer>
          </Modal>
     );
}

export default function ModalCard({Cliente,Titulo,Id}) {

     const [modalShow, setModalShow] = React.useState(false);

     return (
          <>
               <Container fluid>
                    <Button variant="primary" onClick={() => setModalShow(true)}>
                         Continuar
                    </Button>
                    <MyVerticallyCenteredModal
                         cliente = {Cliente}
                         titulo = {Titulo}
                         id = {Id}
                         show={modalShow}
                         onHide={() => setModalShow(false)} />
               </Container>
          </>
     );
}
