import './index.css'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { esES } from '@mui/x-data-grid'
import { esES as pickersEsES } from '@mui/x-date-pickers'
import { esES as coreEsES } from '@mui/material/locale'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import { orange } from '@mui/material/colors'
import reportWebVitals from './reportWebVitals'

const theme = createTheme({
	status: {
		danger: orange[500]
	},
	overrides: {
		MUIDataTable: {
			responsiveScroll: {
				maxHeight: 'none',
			},
		},
	},
	palette: {
		primary: {
		  main: '#009B78',
		  darker: '#053e85',
		}
	},
	shadows: ["none"],
	typography: {
		fontFamily: 'Nunito',
		fontSize: 15,
		textAlign: 'center'
	},
	esES,
	pickersEsES,
	coreEsES
})

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<App />
	</ThemeProvider>,
	document.getElementById('root')
)

reportWebVitals()
