import React from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import { CardsData } from '../../CardsDataRipley'
import '../../styles/cardsToolsRipley.css'

// import InformationBox from '../InformationBox/InformationBox'

import ModalCard from '../Modal/ModalCard'

export default function ToolsRipley() {
     return (
          <Container fluid className='w-100 h-100 g-0 align-items-center float-start'>
               <Row className="align-items-center">
                    {
                         CardsData.map((item, index) => {
                              return (
                                   <>
                                        <Col md={6} className='colul float-start'>
                                             <Card className="card-style-tools-r" key={index}>
                                                  <Card.Header className="card-header-tools-r">
                                                       <Row className="HeaderContainer">
                                                            <Card.Text className="card-text-tools-r">{item.title}</Card.Text>
                                                            <Card.Img className="card-img-r" />
                                                            <Row>
                                                                 <Card.Text className="text-instruction">
                                                                      <p className="text-p">
                                                                           <a className="text-a">Input: </a>
                                                                           {item.Text}
                                                                      </p>
                                                                      <p className="text-p">
                                                                           <a className="text-a">Data: </a>
                                                                           {item.Text1}
                                                                      </p>
                                                                      <p className="text-p">
                                                                           <a className="text-a">Output: </a>
                                                                           {item.Text2}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                       </Row>
                                                  </Card.Header>
                                                  <Card.Body className="card-body-tools-r">
          
                                                       <ModalCard Cliente={item.cliente} Titulo={item.title} Id={item.id} />
                                                       {/* <Button size="lg" variant={`align-middle ${item.class}`} href={item.path}>
                                                            {item.button}
                                                       </Button> */}
                                                  </Card.Body>
                                             </Card>
                                        </Col>

                                        {/* <Col className="col-information">
                                             <InformationBox />
                                        </Col> */}
                                   </>
                              )
                         })
                    }
               </Row>
          </Container>
     )
}
