import React from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import { CardsData } from '../../CardsDataSpread'
import '../../styles/cardsToolsSpread.css'

import ModalCard from '../Modal/ModalCard'

export default function ToolsSpread() {
     return (
          <Container fluid className='w-100 h-100 g-0 align-items-center float-start scrollspy'>
               <Row className="w-100 align-items-center">
                    {
                         CardsData.map((item, index) => {
                              return (
                                   <>
                                        <Col className='justify-content-evenly'>
                                             <Card className="card-style-spread" key={index}>
                                                  <Card.Header className="card-header-tools">
                                                       <Row className="HeaderContainer-s">
                                                            <Card.Text className="card-text-s">{item.title}</Card.Text>
                                                            <Row>
                                                                 <Card.Text className="text-instruction">
                                                                      <p className="text-p">
                                                                           <a className="text-a">Input: </a>
                                                                           {item.Text}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                            <Row>
                                                                 <Card.Text className="text-instruction-s">
                                                                      <p className="text-p">
                                                                           <a className="text-a-s">Data: </a>
                                                                           {item.Text1}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                            <Row>
                                                                 <Card.Text className="text-instruction">
                                                                      <p className="text-p">
                                                                           <a className="text-a">Output: </a>
                                                                           {item.Text2}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                       </Row>
                                                  </Card.Header>
                                                  <Card.Body className="card-body-btn" >
                                                       <ModalCard md={12} Cliente={item.cliente} Titulo={item.title} Id={item.id}/>
                                                       {/* <Button size="lg" className="card-btn" variant={`align-middle ${item.class}`} href={item.path}>
                                                            {item.button}
                                                       </Button> */}
                                                  </Card.Body>
                                             </Card>
                                        </Col>
                                   </>
                              )

                         })
                    }
               </Row>
          </Container>
     )
}
