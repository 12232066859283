import Logo from '../../assets/logo-sidebar.svg'
export default function LogoSpread () {
    return(
        <div className='logo-wrapper'>
            <img src={Logo} className='logo' alt="..." height="..."/>
            {/* <div className='logo' style={{backgroundImage: `url(${Logo})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}/> */}
        </div>
    )
}


