import { Redirect, Route } from 'react-router-dom'
import { setTitle } from './../actions/ui'

import React from 'react'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  localStorage.setItem('lastPath', rest.location.pathname)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (rest.location.pathname.includes('/dashboard')) dispatch(setTitle('Dashboard'))
    else if (rest.location.pathname.includes('/sorting')) dispatch(setTitle('Sorting'))
    else if (rest.location.pathname.includes('/dispatch')) dispatch(setTitle('Despacho'))
    else if (rest.location.pathname.includes('/wms')) dispatch(setTitle('Gestión Almacen'))
    else if (rest.location.pathname.includes('/reverse-logistic')) dispatch(setTitle('Logística Inversa'))
    else if (rest.location.pathname.includes('/search')) dispatch(setTitle('Busqueda'))
  }, [rest.location.pathname])
  return (
    <Route
      {...rest}
      component={props => (isAuthenticated ? <Component {...props} /> : <Redirect to="/auth" />)}
    />
  )
}

PrivateRoute.propTypes = {
  isAuthenticated: propTypes.bool.isRequired,
  component: propTypes.func.isRequired
}

export default PrivateRoute