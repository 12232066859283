import PropTypes from 'prop-types'
import React from 'react'
import Select from '@mui/material/Select'
import { Form } from 'react-bootstrap'

const CommonSelect = props => {
  const { client, onChange, children, inputRef } = props
  return (
    <Select
      // labelId="demo-simple-select-autowidth-label"
      // id="demo-simple-select-autowidth"
      value={client}
      onChange={onChange}
      inputRef={inputRef}
      style={{
        'borderRadius': '10px',
        'fontSize': '15px',
        'fontWeight': '600',
        'height': '50px',
        'width' : '100%'
      }}
      // autoWidth
      // displayEmpty
      // autoFocus={false}
      // style={{
      //   background: 'linear-gradient(180deg, #009B78 50%, #357B6B 100%)',
      //   color: 'white',
      //   fontFamily: 'Nunito',
      //   fontWeight: 900,
      //   width: '100%'
      //   // zIndex: 100
      // }}
      {...props}>
      {children}
    </Select>
  )
}

// const CommonSelect = props => {
//   const { client, onChange, children, inputRef } = props
//   return (
//     <Form.Select
//       value={client}
//       onChange={onChange}
//       inputRef={inputRef}
//       style={{
//         'borderRadius': '10px',
//         'fontSize': '15px',
//         'fontWeight': '600',
//         'height': '50px'
//       }}
//       {...props}>
//       {children}
//     </Form.Select>
//   )
// }

CommonSelect.propTypes = {
  client: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

export default CommonSelect