import React, { useState } from 'react'
import UploadFile from './uploadFile'
import { Button, ButtonGroup, Backdrop, CircularProgress } from '@material-ui/core'
import { Container, Row, Col } from 'react-bootstrap'

const Planning = () => {
    const [state, setState] = useState({ Path: "SeparadorSector", key: 1, titulo: "Separador por Sector" })
    const [loading, setLoading] = useState(false)
    return (
        <Container fluid className='g-0 w-100'>
            <Backdrop
                sx={{ color: '#fff' }}
                style={{zIndex: 999}}
                open={loading}
            >
                <CircularProgress />
            </Backdrop>
            <Row className='pt-5 gx-0 text-center'>
                <h3>{state.titulo}</h3>
            </Row>
            <Row className='text-center gx-0' style={{'height': 'calc(100vh - 200px'}}>
                <Col xs={2} className='text-center align-self-center spread-font'>
                    <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical contained primary button group"
                        className='mx-2'
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ backgroundColor: 'rgb(0, 155, 120)', marginBottom: 5 }}
                            onClick={() => {
                                setState({ Path: "ManifiestoFalabella", key: state.key + 1, titulo: "Manifiesto Falabella" })
                            }}
                            >Manifiesto Falabella</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ backgroundColor: 'rgb(0, 155, 120)', marginBottom: 5 }}
                            onClick={() => {
                                setState({ Path: "ManifiestoParis", key: state.key + 1, titulo: "Manifiesto Paris" })
                            }}
                            >Manifiesto Paris</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ backgroundColor: 'rgb(0, 155, 120)', marginBottom: 5 }}
                            onClick={() => {
                                setState({ Path: "ManifiestoAutomatizado", key: state.key + 1, titulo: "Manifiesto Paris DaaS" })
                            }}
                            disabled={true}
                            >Manifiesto Paris DaaS</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ backgroundColor: 'rgb(0, 155, 120)', marginBottom: 5 }}
                            onClick={() => {
                                setState({ Path: "ManifiestoAutomatizado", key: state.key + 1, titulo: "Manifiesto Ripley" })
                            }}
                            >Manifiesto Ripley</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ backgroundColor: 'rgb(0, 155, 120)', marginBottom: 5 }}
                            onClick={() => {
                                setState({ Path: "ManifiestoAutomatizado", key: state.key + 1, titulo: "Manifiesto Nclientes" })
                            }}
                            >Manifiesto Nclientes</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            
                            style={{ marginTop: 10, backgroundColor: '#FFD700', color: '#000', marginBottom: 5 }}
                            onClick={() => {
                                setState({ Path: "SeparadorSector", key: state.key + 1, titulo: "Separador por Sector" })
                            }}
                            >Separador Sector</Button>

                    </ButtonGroup>
                </Col>
                <UploadFile path={state.Path} titulo={state.titulo} loading={loading} setLoading={setLoading} />
                <Col xs={2}></Col>
            </Row>
        </Container>
    )

}

export default Planning
