import React from 'react'
import './styles/login.css'
import { CardLogin } from './CardLogin'
import { Container } from 'react-bootstrap'
import '../../App.css'

export const Login = () => {
  return (
    <Container fluid className='bg-spread'>
        <CardLogin/>
        <div className="telefono-icon"></div>
        <div className="circulo4 border-radius"></div>        
        <div className="circulo1 border-radius"></div>
        <div className="circulo2 border-radius"></div>
        <div className="circulo3 border-radius"></div>
        <div className="circulo4 border-radius"></div>
        <div className="circulo5 border-radius"></div>
        <div className="circulo6"></div>
        <p className="texto-spread">Bienvenido a MySpread</p>
    </Container>
  )
}
