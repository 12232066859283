import { types } from '../types/types'

const defaultState = {
  current: null,
  orderNumbers: [],
  searchDateRange: [],
  reprocessingList: [],
  reprocessingHistoryList: [],
  searchBy: types.REPROCESSING_SEARCH_BY_ORDER_NO
}

// const loadOrderNumbers = (orderNumbers, current) => {
//   Array.isArray(action.payload) ? action.payload.map(item => String(item.orderNo)?.trim()) : [action.payload.orderNo]
// }

export const reprocessingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.REPROCESSING_CLEAN_HISTORY_AND_LIST:
      return {
        ...state,
        reprocessingList: [],
        reprocessingHistoryList: []
      }
    case types.REPROCESSING_CLEAN_HISTORY:
      return {
        ...state,
        reprocessingHistoryList: []
      }
    case types.REPROCESSING_CLEAN_LIST:
      return {
        ...state,
        reprocessingList: []
      }
    case types.REPROCESSING_SEARCH_CLEAN_DATE_RANGE:
      return {
        ...state,
        searchDateRange: []
      }
    case types.REPROCESSING_SEARCH_SET_DATE_RANGE:
      return {
        ...state,
        searchDateRange: action.payload
      }
    case types.REPROCESSING_SEARCH_SWITCH_MODE:
      return {
        ...state,
        searchBy: action.payload
      }
    case types.REPROCESSING_LIST_SAVE_SUCCESS:
      return { ...state, reprocessingList: action.payload }
    case types.REPROCESSING_HISTORY_LIST_SAVE_SUCCESS:
      return { ...state, reprocessingHistoryList: action.payload }
    case types.REPROCESSING_HISTORY_LIST_CLEAN_LIST:
      return { ...state, reprocessingHistoryList: [] }
    case types.REPROCESSING_LOAD_CURRENT:
      return { ...state, current: null, orderNumbers: null }
    case types.REPROCESSING_LOAD_CURRENT_SUCCESS:
      // new order numbers if current order numbers length is more than 2
      return {
        ...state,
        current: action.payload
        // orderNumbers: loadOrderNumbers(state.orderNumbers, action.payload)
      }
    case types.REPROCESSING_LOAD_CURRENT_NOT_FOUND:
      return { ...state, current: null }
    case types.REPROCESSING_CLEAN_CURRENT:
      return { ...state, current: null, orderNumbers: null }
    case types.REPROCESSING_LOGOUT_CLEANING:
      return { ...state, current: null, orderNumbers: null }
    case types.REPROCESSING_SAVE_ORDER_NUMBERS:
      return { ...state, orderNumbers: action.payload }
    case types.REPROCESSING_CLEAN_ORDER_NUMBERS:
      return { ...state, orderNumbers: [] }
    default:
      return state
  }
}