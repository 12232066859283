import { MenuItem, ToggleButton } from '@mui/material'

export const renderSelectMenuItems = items => {
  return (
      items &&
      items.map(({ name, value }, index) => (
        <MenuItem className='spread-font' key={`${value}_${index + 1}`} value={value}>
          {name}
        </MenuItem>
      ))
    )
}

// export const renderSelectMenuItems = items => {
//   return (
//     items &&
//     items.map(({name,value}, index) =>(
//       <option key={`${value}_${index + 1}`} value={value}>
//         {name}
//       </option>
//     ))
//   )
// }

export const renderToggleButtons = () => {
  return [
    { name: 'Pistolear', value: 'SCANNER' },
    { name: 'Subir Archivo', value: 'UPLOAD_FILE' }
  ].map(({ name, value }) => (
    <ToggleButton key={`${name}_${value}`} value={value}>
      {name}
    </ToggleButton>
  ))
}