import React from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import '../../../styles/cards.css'

export default function ExternalCards() {
    const CardsData = [
        {
            title: 'Devolución No Entregados',
            path: '/wms/movements/external/undelivered-return',
            img: '/img/icon-movimientos.svg',
            button: 'card-text',
            class: '',
            colclass: 'justify-content-lg-center justify-content-xl-end justify-content-md-center justify-content-sm-center'
        },
        {
            title: 'Devolución Ruta Despachada',
            path: '/wms/movements/external/return-dispatch-route',
            img: '/img/icon-procesamiento.svg',
            button: 'card-text',
            class: '',
            colclass: 'justify-content-lg-center justify-content-xl-start justify-content-md-center justify-content-sm-center'
        }
    ]
    return (
        <Container fluid className='g-0 w-75'
         style={{'height': 'calc(100vh - 100px'}}
         >
            <Row className="h-100 align-items-center g-0">
                {CardsData.map((item, index) =>{
                    return (
                        <Col key={index} className={item.colclass}>
                            <Card className="card-style mx-auto" >
                                <Card.Header className='card-header-custom'>
                                    <Card.Text className="text-center">{item.title}</Card.Text>
                                    <Card.Img variant="top" src={item.img}/>
                                </Card.Header>
                                <Card.Body>
                                    <Button size="lg" variant={`align-middle ${item.class}`} href={item.path}>
                                        Continuar
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}
