import * as _ from 'lodash'

import { finishLoading, setError, startLoading } from './ui'
import { firebase, googleAuthProvider } from '../config/firebase-config'

import Swal from 'sweetalert2'
import { reprocessingLogout } from './reprocessing'
import { types } from '../types/types'

const fieldsToGet = ['displayName', 'emailVerified', 'email', 'phoneNumber', 'photoURL', 'uid', 'refreshToken']

export const startGoogleLogin = () => {
  return dispatch => {
    dispatch(startLoading())

    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(({ user }) => {
        const currentUser = _.pick(user, fieldsToGet)
        dispatch(login(currentUser))
        dispatch(finishLoading())
      })
      .catch(({ message }) => {
        dispatch(setError(message))
        Swal.fire('Error', message, 'error')
      })
  }
}

export const login = user => ({
  type: types.LOGIN,
  payload: {
    ...user
  }
})

export const startLogout = () => {
  return async dispatch => {
    dispatch(startLoading())
    await firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logout())
        dispatch(reprocessingLogout())
        dispatch(finishLoading())
      })
      .catch(({ message }) => {
        dispatch(setError(message))
        dispatch(logout())
        dispatch(finishLoading())
        Swal.fire('Error', message, 'error')
      })
  }
}

export const logout = () => ({
  type: types.LOGOUT
})
