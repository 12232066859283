import { authReducer } from './authReducer'
import { combineReducers } from 'redux'
import { reprocessingReducer } from './reprocessingReducer'
import { reverseLogisticsReducer } from './reverseLogisticsReducer'
import { uiReducer } from './uiReducer'

export const reducers = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  reprocessing: reprocessingReducer,
  logistics: reverseLogisticsReducer
})