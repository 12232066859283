import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Redirect, BrowserRouter as Router, Switch } from 'react-router-dom'
import { login, startLogout } from '../actions/auth'
import { AuthRouter } from './AuthRouter'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import { firebase } from '../config/firebase-config'
import { useDispatch } from 'react-redux'
import WarehouseManagement from '../modules/WarehouseManagement'
import ReverseLogistic from '../modules/ReverseLogistic'
import Sidebar from '../components/Sidebar'
import Movements from '../modules/ReverseLogistic/Movements'
import Processing from '../modules/ReverseLogistic/Processing'
import WmMovements from '../modules/WarehouseManagement/views/Movements'
import WmProcessing from '../modules/WarehouseManagement/views/Processing'
import ExternalCards from '../modules/WarehouseManagement/views/Movements/External/Cards'
import Planning from '../modules/Planning'
import { ExternalReturnUndeliveredNew, ExternalReturnUndeliveredResume, ExternalReturnUndeliveredFinished } from '../modules/WarehouseManagement/views/Movements/External/ReturnUndelivered'
import { ReturnDispatchRouteNew, ReturnDispatchRouteResume, ReturnDispatchRouteFinished } from '../modules/WarehouseManagement/views/Movements/External/ReturnDispatchRoute'
// import Manifest from '../modules/Planning/Manifest'
// import Sectorizer from '../modules/Planning/Sectorizer'
import Tools from '../modules/Tools'

const fieldsToGet = ['displayName', 'emailVerified', 'email', 'phoneNumber', 'photoURL', 'uid', 'refreshToken']

const RouterComponent = () => {
    const lastPath = localStorage.getItem('lastPath') || '/auth'

    const dispatch = useDispatch()
    const [checking, setChecking] = useState(true)
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
        if (user?.uid && user.email.includes('@spread.cl')) {
            const currentUser = _.pick(user, fieldsToGet)
            dispatch(login(currentUser))
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
            dispatch(startLogout())
        }

        setChecking(false)
        })
    }, [dispatch, setChecking, setIsLoggedIn])

    if (checking) {
        return (
        <div
            style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
            }}>
            {/* <h1>Cargando...</h1> */}
        </div>
        )
    }

    return (
        <Router>
            {isLoggedIn ? <Sidebar /> : ''}
            <main role='main' className='col-sm'>
                <Switch>
                    <PublicRoute isAuthenticated={isLoggedIn} path="/auth" component={AuthRouter} />
                    {/* <PrivateRoute exact 
                        path="/dashboard" 
                        isAuthenticated={isLoggedIn} 
                        component={Dashboard}
                    />
                    <PrivateRoute exact 
                        path="/sorting" 
                        isAuthenticated={isLoggedIn} 
                        component={Sorting}
                    />*/}
                    <PrivateRoute exact 
                        path="/planning" 
                        isAuthenticated={isLoggedIn} 
                        component={Planning}
                    />
                    {/* <PrivateRoute exact 
                        path="/planning/manifest" 
                        isAuthenticated={isLoggedIn} 
                        component={Manifest}
                    />
                    <PrivateRoute exact 
                        path="/planning/sectorizer" 
                        isAuthenticated={isLoggedIn} 
                        component={Sectorizer}
                    /> */}
                    {/*
                    <PrivateRoute exact 
                        path="/dispatch" 
                        isAuthenticated={isLoggedIn} 
                        component={Dispatch}
                    />*/}
                    <PrivateRoute exact 
                        path="/wms" 
                        isAuthenticated={isLoggedIn} 
                        component={WarehouseManagement}
                    />
                    <PrivateRoute exact 
                        path="/wms/movements" 
                        isAuthenticated={isLoggedIn} 
                        component={WmMovements}
                    />
                    <PrivateRoute exact 
                        path="/wms/movements/external" 
                        isAuthenticated={isLoggedIn} 
                        component={ExternalCards}
                    />
                    <PrivateRoute exact 
                        path="/wms/movements/external/undelivered-return" 
                        isAuthenticated={isLoggedIn} 
                        component={ExternalReturnUndeliveredResume}
                    />
                    <PrivateRoute exact 
                        path="/wms/movements/external/undelivered-return/new" 
                        isAuthenticated={isLoggedIn} 
                        component={ExternalReturnUndeliveredNew}
                    />
                    <PrivateRoute exact 
                        path="/wms/movements/external/undelivered-return/success" 
                        isAuthenticated={isLoggedIn} 
                        component={ExternalReturnUndeliveredFinished}
                    />
                    <PrivateRoute exact 
                        path="/wms/movements/external/return-dispatch-route" 
                        isAuthenticated={isLoggedIn} 
                        component={ReturnDispatchRouteResume}
                    />
                    <PrivateRoute exact 
                        path="/wms/movements/external/return-dispatch-route/new" 
                        isAuthenticated={isLoggedIn} 
                        component={ReturnDispatchRouteNew}
                    />
                    <PrivateRoute exact 
                        path="/wms/movements/external/return-dispatch-route/success" 
                        isAuthenticated={isLoggedIn} 
                        component={ReturnDispatchRouteFinished}
                    />
                    <PrivateRoute exact 
                        path="/wms/processing" 
                        isAuthenticated={isLoggedIn} 
                        component={WmProcessing}
                    />
                    <PrivateRoute exact 
                        path="/reverse-logistic" 
                        isAuthenticated={isLoggedIn} 
                        component={ReverseLogistic}
                    />
                    <PrivateRoute exact 
                        path="/reverse-logistic/movements" 
                        isAuthenticated={isLoggedIn} 
                        component={Movements}
                    />
                    <PrivateRoute exact 
                        path="/reverse-logistic/processing" 
                        isAuthenticated={isLoggedIn} 
                        component={Processing}
                    />
                    <PrivateRoute exact 
                        path="/tools" 
                        isAuthenticated={isLoggedIn} 
                        component={Tools}
                    />
                    <Redirect to={lastPath} />
                </Switch>
            </main>
        </Router>
    )
}

export default RouterComponent