import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropzone from 'react-dropzone'
import { DataGrid, esES } from '@mui/x-data-grid'
import { utils, write, read } from 'xlsx'
import Moment from 'moment';
import { Tooltip, Backdrop, CircularProgress } from '@mui/material'
import { closeBackdrop, openBackdrop, openModal } from '../../../../actions/ui'
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver'
import withReactContent from 'sweetalert2-react-content'
import { mockSelects } from '../../mock/mockSelects'
import { BsSearch, BsCalendar, BsExclamationCircle } from 'react-icons/bs'
import { FaFileUpload, FaFileExcel } from 'react-icons/fa'
import { InputGroup, Button, Container, Col, Row, Card, Form, Modal } from 'react-bootstrap'
import { useForm } from '../../../../hooks/useForm'
import { saveOrderNumbers, startLoadingReprocessing, startLoadingReprocessingList } from '../../../../actions/reprocessing'
import { Typeahead } from 'react-bootstrap-typeahead'
import { MuiChipsInput } from 'mui-chips-input'
import '../../styles/dropzone.css'
import '../../styles/modal.css'
import styles from '../../styles/filters.module.css'
import axios from 'axios'
import ModalForSave from '../../components/Modal'

const url = process.env.REACT_APP_URL

const iconSize = {
    fontSize: '30px',
    marginRight: '0.5em',
    marginBottom: '3px'
}

const Classification = () => {

    const [packageState, setPackageState] = useState('')
    const [loading,setLoading] = useState(false)
    const [clientSelect] = useState(mockSelects.clients)
    const [originSelect] = useState(mockSelects.origins)
    const [destinationSelect] = useState(mockSelects.destinations)
    const [reasonSelect] = useState(mockSelects.reasons)
    const [flowSelect] = useState(mockSelects.flow)
    const [regionSelect] = useState(mockSelects.region)
    const [startDateSelected, setStartDateSelected] = useState('')
    const [endDateSelected, setEndDateSelected] = useState('')
    const [regionSelected, setRegionSelected] = useState('')
    const [flowSelected, setFlowSelected] = useState('')
    const [clientSelected, setClientSelected] = useState('')
    const [originSelected, setOriginSelected] = useState('')
    const [reasonSelected, setReasonSelected] = useState('')
    const [destinationSelected, setDestinationSelected] = useState('')
    const [data, setData] = useState([])
    const [classificationList,setClassificationList] = useState([])
    const [dataFile, setDataFile] = useState([])
    const [openModalSearch, setOpenModalSearch] = useState(false)
    const handleCloseSearch = ()=>setOpenModalSearch(false)
    const [chips, setChips] = useState([])
    const [itemsFinales, setItemsFinales] = useState([])
    const [guidesTotal, setGuidesTotal] = useState([])
    const dispatch = useDispatch()
    const [formValues, handleInputChange] = useForm()
    const { reprocessingList } = useSelector(state => state.reprocessing)
    const MySwal = withReactContent(Swal)

    const titleFormatCol = (title) => <Tooltip title={title} placement="bottom"><strong>{title}</strong></Tooltip>

    const cols = [
        {
            field: 'orderNo',
            renderHeader: () => (titleFormatCol('Item')),
            flex: 0.3,
        },
        {
            field: 'region',
            renderHeader: () => (titleFormatCol('Región')),
            flex: 0.25,
        },
        {
            field: 'client',
            renderHeader: () => (titleFormatCol('Cliente')),
            flex: 0.3,
        },
        {
            field: 'flow',
            renderHeader: () => (titleFormatCol('Flujo')),
            flex: 0.25,
        },
        {
            field: 'origin',
            renderHeader: () => (titleFormatCol('Origen')),
            flex: 0.6
        },
        {
            field: 'reason',
            renderHeader: () => (titleFormatCol('Motivo')),
            flex: 0.6
        },
        {
            field: 'destination',
            renderHeader: () => (titleFormatCol('Destino')),
            flex: 0.6
        },
        {
            field: 'reprocessingDate',
            renderHeader: () => (titleFormatCol('Fecha')),
            flex: 0.4,
            renderCell: (params) => Moment(params.row.reprocessingDate).format('DD/MM/YYYY')

        },
        {
            field: 'responsibleName',
            renderHeader: () => (titleFormatCol('Responsable')),
            flex: 0.5,
            headerClassName: 'lastcolumnSeparator',
        }
    ]

    const downloadTemplate = async () => {
        const data_ = [{ Guia: '' }] // , Estado: '', Cliente: '', Motivo: '', Origen: ''
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const fileExtension = '.xlsx'
        const fileName = 'RP_CARGA_MASIVA'
        const ws = utils.json_to_sheet(data_)
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], { type: fileType })
        saveAs(data, fileName + fileExtension)
    }

    const changeState = async (event) => {
        await setPackageState(event.target.value)
        handleInputChange(event)
    }

    const loadWarehouseByIds = async (guides) => {
        const body = {
            ids: guides
        }
        await axios.post(`${url}/reprocessings/getWarehouseByIds`,body).then(r => {
            const data = r.data.data[0]
            setItemsFinales(data)
        })
    }

    const handleFileCode = async codes => {
        const guides = codes.map(code => String(code).trim())
        if (guides.length < 1) {
            return MySwal.fire({
                title: 'Usar la plantilla para subir el archivo',
                icon: 'warning',
                showConfirmButton: false,
                timer: 2500
            })
        }
        loadWarehouseByIds(guides)
        setGuidesTotal(codes)
        dispatch(saveOrderNumbers(guides))
        dispatch(openBackdrop())
        const url_ = `${url}/reprocessings/${guides}`
        dispatch(startLoadingReprocessing(url_))
            .then(() => {
                dispatch(closeBackdrop())
                dispatch(openModal())
            })
            .catch(err => {
                if (err?.response?.status !== 404) {
                    MySwal.fire({
                        title: 'Error al buscar el código',
                        icon: 'error',
                        text: 'El código no existe o no está registrado',
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    dispatch(openModal())   
                }
            })
    }

    const handleDrop = ([file]) => {
        const reader = new FileReader()
        reader.onload = async evt => {
            const bstr = evt.target.result
            const wb = read(bstr, { type: 'binary' })
            const ws = wb.Sheets[wb.SheetNames[0]]
            const dataJson = utils.sheet_to_json(ws)
            const dataClean = dataJson.map((item) => item.Guia)
            setDataFile(dataClean)
        }
        reader.readAsBinaryString(file)
    }

    useEffect(() => {
        if (dataFile.length > 1) { 
            handleFileCode(dataFile)
        }
    }, [dataFile])

    useEffect(()=> {
        if (classificationList.length == 0) {
            loadWarehouseToday()
        }
        
    },[])
    
    const loadWarehouseToday = async () => {
        setLoading(true)
        await axios.get(`${url}/reprocessings/getAllWarehouseToday`).then(r => {
            const data = r.data.data[0]
            setClassificationList(data)
            setLoading(false) 
        })
    }

    const loadWarehouseByParams = async () => {
        setLoading(true)
        const body = {
            startDate: startDateSelected,
            endDate: endDateSelected,
            region: regionSelected,
            flow: flowSelected,
            client: clientSelected,
            origin: originSelected,
            reason: reasonSelected,
            destination: destinationSelected
        }
        await axios.post(`${url}/reprocessings/getWarehouseByParam`,body).then(r => {
            const data = r.data.data[0]
            setClassificationList(data)
            setLoading(false) 
            if (r.data.data[0].length == 0) {
                MySwal.fire({
                    icon: 'info',
                    title: 'No se encontró información asociada',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }
    
    const searchInputChange = (newChips) => setChips(newChips)
    const getItemsById = async () => {
        handleFileCode(chips)
        handleCloseSearch()
    }

    return (
        <Container fluid className='gx-0'>
            {!loading && <ModalForSave handleCodeChange={handleInputChange} reprocessData={itemsFinales} guidesTotal={guidesTotal}/>}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" /> 
            </Backdrop>
            <Modal 
                backdrop="static"
                show={openModalSearch} 
                onHide={handleCloseSearch}
                centered
                keyboard={false}
                size="lg"
            >
                <Modal.Header className='spread-font' style={{'background':'#E5F5F1', 'color':'#009b78'}}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Búsqueda por pistoleo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card 
                        className='d-flex border text-center spread-font' 
                        style={{
                            'borderRadius': '10px', 
                            'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)'
                        }}
                    >
                        <Card.Body 
                            className='' 
                            style={{
                                'marginTop': '-18px'
                                }}
                        >
                            <span style={{'fontSize':'25px'}}>Items pistoleados: <strong>{chips.length}</strong></span>
                            
                    <MuiChipsInput
                        multiline={false}
                        variant='outlined'
                        color='primary'
                        fullWidth
                        value={chips} 
                        onChange={searchInputChange} 
                        autoFocus
                        className='chips-input-modal'
                        placeholder={chips.length < 1 ? 'Ingrese item y presione enter' : ''}
                    />
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer className='spread-font justify-content-center'>
                    <Button onClick={()=> setOpenModalSearch(false)} style={{
                        'background':'#fff', 
                        'color' : '#EA4335', 
                        'border' : '1px solid #EA4335', 
                        'height': '50px', 
                        'padding' : '0px',
                        'width' : '45%',
                        'borderRadius': '10px'
                    }}>Cancelar</Button>
                    <Button style={{
                        'background':'#009B78', 
                        'border' : '0px solid #EA4335',
                        'color' : '#fff', 
                        'height': '50px', 
                        'padding' : '0px',
                        'width' : '45%',
                        'borderRadius': '10px',
                        'marginLeft' : '0.5rem'
                    }} 
                    disabled={chips.length<1}
                    onClick={()=> getItemsById()}
                    >
                        Cargar
                        </Button>
                </Modal.Footer>
            </Modal>
            <Row className='pt-2 gx-0'>
                <h3 className='spread-font text-dark text-center'>Clasificación</h3>
            </Row>
            <Row className='px-3 gx-0 pb-2'>
                <Col >
                    <Card className='d-flex border text-center spread-font' style={{ 'borderRadius': '10px', 'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)' }}>
                        <Card.Body className='d-flex justify-content-center'>
                            <Dropzone
                                onDrop={handleDrop}
                                maxFiles={1}
                                multiple={false}
                                canRestart={true}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject
                                }) => {
                                    const additionalClass = isDragAccept
                                        ? "accept"
                                        : isDragReject
                                            ? "reject"
                                            : ""
                                    return (
                                        <div
                                            {...getRootProps({
                                                className: `dropzone ${additionalClass}`
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <p className='btn-template'>
                                                {isDragActive
                                                    ? <FaFileExcel style={iconSize} />
                                                    : <FaFileUpload style={iconSize} />
                                                }
                                                <span className='text-dropzone'>Suelta tu archivo aquí o {' '}</span>
                                                <Button type='file' style={{ 'background': '#009B78', 'color': '#fff', 'height': '45px', 'padding': '0px' }} >Seleccionar archivo</Button>
                                            </p>
                                        </div>

                                    )
                                }}
                            </Dropzone>
                            <div className='d-flex flex-column justify-content-center'>
                                <Button 
                                    style={{ 
                                        'width': '220px', 
                                        'marginLeft': '1rem', 
                                        'height': '40px', 
                                        'padding': '0px' 
                                        }} 
                                    onClick={() => { downloadTemplate() }}
                                    >
                                        <BsExclamationCircle style={{ 'marginBottom': '3px', 'marginRight' : '3px' }} />
                                        Descargar Plantilla
                                    </Button>
                                <Button
                                    className='spread-font'
                                    style={{
                                        'width': '220px',
                                        'background': 'rgba(0, 155, 120, 0.21)',
                                        'border': 'rgba(0, 155, 120, 0.21)',
                                        'borderRadius': '10px',
                                        'marginLeft': '1rem',
                                        'color': '#009B78',
                                        'height': '40px',
                                        'marginTop': '5px',
                                        'padding' : '0px',
                                    }}
                                    id="button-addon2"
                                    onClick={ ()=> setOpenModalSearch(true) } 
                                >
                                    Pistolear
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='px-3 gx-0'>
                <Col xs={12} md={3} lg={3} xl={2} className=''>
                    <Card style={{ 'borderRadius': '10px', 'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)' }}>
                        <Card.Header as="h3" className={`border-bottom py-3 ${styles.title}`}>Filtros</Card.Header>
                        <Card.Body style={{ 'height': 'calc(100vh - 396px)', 'overflowY': 'auto', 'minHeight': '150px' }}>
                            <Form.Group>
                                <div className={`${styles.FormContainer}`}>
                                    <InputGroup className={`mb-1 ${styles.DivInputFecha}`}>
                                        <InputGroup.Text><BsCalendar /></InputGroup.Text>
                                        <Form.Control 
                                            id='startDate' 
                                            type='date' 
                                            name='startDate' 
                                            //onInputChange={()=> setStartDateSelected('')}
                                            onChange={(e) => {
                                                console.log(e)
                                                setStartDateSelected(e.target.value)
                                            }} 
                                            className={styles.InputPrincipalFecha} placeholder='Fecha' 
                                        />
                                    </InputGroup>
                                    <InputGroup className={`${styles.DivInputFecha}`}>
                                        <InputGroup.Text><BsCalendar /></InputGroup.Text>
                                        <Form.Control 
                                            id='endDate' 
                                            type='date' 
                                            name='endDate' 
                                            //onInputChange={()=> setEndDateSelected('')}
                                            onChange={(e) => {
                                                console.log(e)
                                                setEndDateSelected(e.target.value)
                                            }} 
                                            className={styles.InputPrincipalFecha} placeholder='Fecha' 
                                        />
                                    </InputGroup>
                                    <Typeahead
                                        id='regionsearcher'
                                        labelKey='name'
                                        options={regionSelect}
                                        placeholder='Seleccione Región'
                                        highlightOnlyResult
                                        renderMenuItemChildren={(e) => e.name}
                                        //onInputChange={()=> setRegionSelected('')}
                                        className='input-custom-filter'
                                        onChange={(e) => {
                                            if (e.length) setRegionSelected(e[0].value)
                                        }}
                                    />
                                    <Typeahead
                                        id='flowsearcher'
                                        labelKey='name'
                                        options={flowSelect}
                                        placeholder='Seleccione Flujo'
                                        highlightOnlyResult
                                        renderMenuItemChildren={(e) => e.name}
                                        //onInputChange={()=> setFlowSelected('')}
                                        className='input-custom-filter'
                                        onChange={(e) => {
                                            if (e.length) setFlowSelected(e[0].value)
                                        }}
                                    />
                                    <Typeahead
                                        id='clientsearcher'
                                        labelKey='name'
                                        options={clientSelect}
                                        placeholder='Seleccione Cliente'
                                        highlightOnlyResult
                                        renderMenuItemChildren={(e) => e.name}
                                        //onInputChange={()=> setClientSelected('')}
                                        className='input-custom-filter'
                                        onChange={(e) => {
                                            if (e.length) setClientSelected(e[0].value)
                                        }}
                                    />
                                    <Typeahead
                                        id='originsearcher'
                                        labelKey='name'
                                        options={originSelect}
                                        placeholder='Seleccione Origen'
                                        highlightOnlyResult
                                        renderMenuItemChildren={(e) => e.name}
                                        //onInputChange={()=> setOriginSelected('')}
                                        className='input-custom-filter'
                                        onChange={(e) => {
                                            if (e.length) setOriginSelected(e[0].value)
                                        }}
                                    />
                                    <Typeahead
                                        id='reasonsearcher'
                                        labelKey='name'
                                        options={reasonSelect}
                                        placeholder='Seleccione Motivo'
                                        highlightOnlyResult
                                        renderMenuItemChildren={(e) => e.name}
                                        //onInputChange={()=> setReasonSelected('')}
                                        className='input-custom-filter'
                                        onChange={(e) => {
                                            if (e.length) setReasonSelected(e[0].value)
                                        }}
                                    />
                                    <Typeahead
                                        id='destinysearcher'
                                        labelKey='name'
                                        options={destinationSelect}
                                        placeholder='Seleccione Destino'
                                        highlightOnlyResult
                                        renderMenuItemChildren={(e) => e.name}
                                        //onInputChange={()=> setDestinationSelected('')}
                                        className='input-custom-filter'
                                        style={{ 'zIndex': '1' }}
                                        onChange={(e) => {
                                            if (e.length) setDestinationSelected(e[0].value)
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer >
                            <div className='d-grid gap-2'>
                                <Button size="lg"
                                    className={`${styles.BtnBuscar} btn btn-lg`}
                                    onClick={()=> loadWarehouseByParams()}>
                                    <BsSearch className={styles.iconBuscar} />
                                    <span className={styles.TxtBtnBuscar}>BUSCAR</span>
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col xs={12} md={9} lg={9} xl={10} className='ps-3'>
                    <Card className='d-flex border text-center spread-font' style={{ 'borderRadius': '10px', 'boxShadow': '0px 0px 3px rgba(0, 0, 0, 0.2)' }}>
                        <Card.Body className='align-items-center d-flex justify-content-center' style={{ 'height': 'calc(100vh - 260px)', 'marginTop': '-18px' }}>
                            <DataGrid
                                disableColumnMenu
                                getRowId={(row) => row.id}
                                rows={classificationList}
                                columns={cols}
                                //pageSize={25}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                sx={{
                                    border: 0
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Classification
