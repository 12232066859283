import React, {useState} from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import { CardsData } from '../../CardsDataParis'
import '../../styles/cardsTools.css'
import { Backdrop, IconButton, CircularProgress, Chip, Tooltip } from '@mui/material'

import ModalCard from '../Modal/ModalCard'

// import InformationBox from '../InformationBox/InformationBox'


export default function ToolsParis() {
     const [loading,setLoading] = useState(false)
     return (
          <Container fluid className='w-100 h-100 g-0 align-items-center float-start' >
               <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
               <Row className="w-100">
                    {
                         CardsData.map((item, index) => {
                              return (
                                   <>
                                        <Col className='justify-content-evenly' >
                                             <Card className="card-style-tools " key={index} >
                                                  <Card.Header className="card-header-tools">
                                                       <Row md={12} className="HeaderContainer" >
                                                            <Card.Text className="card-text-tools">{item.title}</Card.Text>
                                                            <Card.Img className="card-img" src={item.img} />
                                                            <Row>
                                                                 <Card.Text className="text-instruction">
                                                                      <p className="text-p">
                                                                           <a className="text-a">Input: </a>
                                                                           {item.Text}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                            <Row>
                                                                 <Card.Text className="text-instruction">
                                                                      <p className="text-p">
                                                                           <a className="text-a">Data: </a>
                                                                           {item.Text1}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                            <Row>
                                                                 <Card.Text className="text-instruction">
                                                                      <p className="text-p">
                                                                           <a className="text-a">Output: </a>
                                                                           {item.Text2}
                                                                      </p>
                                                                 </Card.Text>
                                                            </Row>
                                                       </Row>
                                                  </Card.Header>
                                                  <Card.Body className="card-body-tools">
                                                       <ModalCard Cliente={item.cliente} Titulo={item.title} Id={item.id} Loading={loading}/>

                                                       {/* <Button size="lg" variant={`align-middle ${item.class}`} href={item.path}>
                                                            {/* {item.button} </Button> */}
                                                  </Card.Body>
                                             </Card>
                                        </Col>
                                   </>
                              )

                         })
                    }
               </Row>
          </Container>
     )
}
