import { Redirect, Route } from 'react-router-dom'

import React from 'react'
import propTypes from 'prop-types'

const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  const lastPath = localStorage.getItem('lastPath') || '/reverse-logistic'

  return (
    <Route {...rest} component={props => (!isAuthenticated ? <Component {...props} /> : <Redirect to={lastPath} />)} />
  )
}

PublicRoute.propTypes = {
  isAuthenticated: propTypes.bool.isRequired,
  component: propTypes.func.isRequired
}

export default PublicRoute