export const mockSelects = {
  region: [
    // { name: 'V: Valparaíso', value: 'VA' },
    { name: 'RM: Metropolitana', value: 'METROPOLITANA' },
    { name: 'VI: L. Bernardo OHiggins', value: 'LGB O’HIGGINS' },
    { name: 'VII: Maule', value: 'MAULE' }
  ],
  flow: [
    { name: 'Medium Ticket', value: 'MT' },
    { name: 'Mini Ticket', value: 'MM' }
  ],
  clients: [
    { name: 'Easy', value: 'EASY'},
    { name: 'Falabella', value: 'FALABELLA' },
    { name: 'Paris', value: 'PARIS' },
    { name: 'Ripley', value: 'RIPLEY' },
    { name: 'Sfillment', value: 'SFILLMENT' }
  ],
  origins: [
    { name: 'Sorting Regular', value: 'SORTING REGULAR' },
    { name: 'Sorting SDD', value: 'SORTING SDD' },
    { name: 'Despacho Regular', value: 'DESPACHO REGULAR' },
    { name: 'Despacho SDD', value: 'DESPACHO SDD' },
    { name: 'Devolucion', value: 'DEVOLUCIÓN' },
    { name: 'Logistica Inversa', value: 'LOGISTICA INVERSA' },
    { name: 'Ruta', value: 'RUTA' },
    { name: 'VAS', value: 'VAS'},
    { name: 'Sin Información', value: 'SIN INFORMACION'}
  ],
  destinations: [
    { name: 'Asignación Manual', value: 'ASIGNACION MANUAL' },
    { name: 'En Proceso', value: 'EN PROCESO' },
    { name: 'Repite', value: 'REPITE' },
    { name: 'Pre-Logistica Inversa', value: 'PRE-LOGISTICA INVERSA' },
    { name: 'Merma', value: 'MERMA' },
    { name: 'Sin Información', value: 'SIN INFORMACION' },
    { name: 'Por despachar en bodega', value: 'POR DESPACHAR EN BODEGA' },
    { name: 'Despachado en bodega', value: 'DESPACHADO EN BODEGA' },
    { name: 'Devuelto en ruta', value: 'DEVUELTO EN RUTA' },
    { name: 'Devuelto a cliente', value: 'DEVUELTO A CLIENTE' },
    { name: 'VAS', value: 'VAS' }
  ],
  reasons: [
    { name: 'Sin Moradores', value: 'SIN MORADORES' },
    { name: 'Anulación o Devolución', value: 'ANULACION O DEVOLUCION' },
    { name: 'Dirección Errónea', value: 'DIRECCION ERRONEA' },
    { name: 'Comuna Errónea', value: 'COMUNA ERRONEA' },
    { name: 'Fuera de Horario', value: 'FUERA DE HORARIO' },
    { name: 'Zona Intransitable', value: 'ZONA INTRANSITABLE' },
    { name: 'Domicilio No Corresponde', value: 'DOMICILIO NO CORRESPONDE' },
    { name: 'Fuera de Cobertura', value: 'FUERA DE COBERTURA' },
    { name: 'Error De Bin', value: 'ERROR DE BIN' },
    { name: 'Sobredimensionado', value: 'SOBREDIMENSIONADO' },
    { name: 'Entrega Parcial', value: 'ENTREGA PARCIAL' },
    { name: 'Falsa Entrega', value: 'FALSA ENTREGA' },
    { name: 'Sin Información', value: 'SIN INFORMACION' },
    { name: 'Planificado Sin Ruta', value: 'PLANIFICADO SIN RUTA' },
    { name: 'Siniestro', value: 'SINIESTRO' },
    { name: 'Merma', value: 'MERMA' },
    { name: 'Empaque Dañado', value: 'EMPAQUE DAÑADO' },
    { name: 'Recogido', value: 'RECOGIDO' },
    { name: 'Duplicado', value: 'DUPLICADO' }
  ]
}
