export const types = {
  LOGIN: '[Auth] Login',
  LOGOUT: '[Auth] Logout',

  UI_SET_ERROR: '[UI] Set Error',
  UI_REMOVE_ERROR: '[UI] Remove Error',
  UI_START_LOADING: '[UI] Start Loading',
  UI_FINISH_LOADING: '[UI] Finish Loading',
  UI_OPEN_BACKDROP: '[UI] Open Backdrop',
  UI_CLOSE_BACKDROP: '[UI] Close Backdrop',
  UI_OPEN_MODAL: '[UI] Open Modal',
  UI_CLOSE_MODAL: '[UI] Close Modal',
  UI_SET_TITLE: '[UI] Set Title',
  UI_RESET_TITLE: '[UI] Reset Title',

  REPROCESSING_LOAD_CURRENT: '[Reprocessing] Loading Current',
  REPROCESSING_LOAD_CURRENT_SUCCESS: '[Reprocessing] Load Current Success',
  REPROCESSING_LOAD_CURRENT_NOT_FOUND: '[Reprocessing] Load Current Not Found',
  REPROCESSING_CLEAN_CURRENT: '[Reprocessing] Clean Current',
  REPROCESSING_LOGOUT_CLEANING: '[Reprocessing] Logout Cleaning',
  REPROCESSING_SAVE_SUCCESS: '[Reprocessing] Save Success',
  REPROCESSING_SAVE_FAIL: '[Reprocessing] Save Fail',
  REPROCESSING_SAVE_ORDER_NUMBERS: '[Reprocessing] Save Order Numbers',
  REPROCESSING_CLEAN_ORDER_NUMBERS: '[Reprocessing] Clean Order Numbers',
  REPROCESSING_LIST_SAVE_SUCCESS: '[Reprocessing] List Save Success',
  REPROCESSING_HISTORY_LIST_SAVE_SUCCESS: '[Reprocessing] History List Save Success',
  REPROCESSING_HISTORY_LIST_CLEAN_LIST: '[Reprocessing] History List Clean List',

  REVERSE_LOGISTICS_SET_MODE: '[Reverse Logistics] Set Mode',
  REVERSE_LOGISTICS_LOAD_CURRENT: '[Reverse Logistics] Loading Current',
  REVERSE_LOGISTICS_LOAD_CURRENT_SUCCESS: '[Reverse Logistics] Load Current Success',
  REVERSE_LOGISTICS_LOAD_CURRENT_NOT_FOUND: '[Reverse Logistics] Load Current Not Found',
  REVERSE_LOGISTICS_CLEAN_CURRENT: '[Reverse Logistics] Clean Current',
  REVERSE_LOGISTICS_LOGOUT_CLEANING: '[Reverse Logistics] Logout Cleaning',
  REVERSE_LOGISTICS_SAVE_SUCCESS: '[Reverse Logistics] Save Success',
  REVERSE_LOGISTICS_SAVE_FAIL: '[Reverse Logistics] Save Fail',
  REVERSE_LOGISTICS_SAVE_ORDER_NUMBER: '[Reverse Logistics] Save Order Number',
  REVERSE_LOGISTICS_CLEAN_ORDER_NUMBER: '[Reverse Logistics] Clean Order Number',

  REPROCESSING_SEARCH_BY_ORDER_NO: 'ORDER_NO',
  REPROCESSING_SEARCH_BY_ORDER_DATE: 'ORDER_DATE',
  REPROCESSING_SEARCH_BY_DATE: 'DATE_RANGE',
  REPROCESSING_SEARCH_BY_DESTINATION: 'DESTINATION',
  REPROCESSING_SEARCH_SWITCH_MODE: '[Reprocessing Search] Switch Mode',
  REPROCESSING_CLEAN_HISTORY_AND_LIST: '[Reprocessing Search] Clean History And List',
  REPROCESSING_CLEAN_HISTORY: '[Reprocessing Search] Clean History',
  REPROCESSING_CLEAN_LIST: '[Reprocessing Search] Clean List',
  REPROCESSING_SEARCH_SET_DATE_RANGE: '[Reprocessing Search] Set Date Range',
  REPROCESSING_SEARCH_CLEAN_DATE_RANGE: '[Reprocessing Search] Clean Date Range',

  REVERSE_LOGISTICS_MODE_SHOOT: 'SHOOT',
  REVERSE_LOGISTICS_MODE_UPLOAD_FILE: 'UPLOAD_FILE'
}